import {Button, Descriptions, Form, Input, InputNumber, message, Modal, Table} from "antd";
// import ItemList from "../components/ItemList";
import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import CONSTANT from "../config/constant.json";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
// import ItemList from "../components/ItemList";


const ConfigAdminScreen = () => {


    const locale = "it-IT";
    const [form] = Form.useForm();

    const [dataBanks, setDataBanks] = useState();
    const [editingKey, setEditingKey] = useState('');
    const [visibleModal, setVisibleModal] = useState(false);
    const [formItem] = Form.useForm();
    const isEditing = (record) => record.key === editingKey;
    //let count = 1;

    useEffect(() => {

        loadData();
        // eslint-disable-next-line
    }, []);


    function loadData() {
        API.getConfig().then(rs => {

            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                const data = rs.result


                let list = data.bankInfos.map(o => {

                    return {
                        key: o.id,
                        id: o.id,
                        beneficiaryName: o.beneficiaryName,
                        bankName: o.bankName,
                        bankShortName: o.bankShortName,
                        accountNumber: o.accountNumber,
                        note: o.note,
                    }
                });
                setDataBanks(list)


                form.setFields([
                    {
                        "name": "insurancePercentage",
                        "value": data.insurancePercentage
                    },
                    {
                        "name": "rate",
                        "value": data.rate
                    },
                    {
                        "name": "warehouseAddress",
                        "value": data.warehouseAddress
                    }
                ])
            }
        }).catch((err) => console.log(err))

    }

    const currencyParser = val => {
        try {
            // for when the input gets clears
            if (typeof val === "string" && !val.length) {
                val = "0.0";
            }

            // detecting and parsing between comma and dot
            var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
            var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
            var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
            reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
            //  => 1232.21 €

            // removing everything except the digits and dot
            reversedVal = reversedVal.replace(/[^0-9.]/g, "");
            //  => 1232.21

            // appending digits properly
            const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
            const needsDigitsAppended = digitsAfterDecimalCount > 2;

            if (needsDigitsAppended) {
                reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
            }

            return Number.isNaN(reversedVal) ? 0 : reversedVal;
        } catch (error) {
            console.error(error);
        }
    };


    const currencyFormatter = value => {
        return new Intl.NumberFormat(locale, {style: 'currency', currency: 'VND'}).format(value);
    };


    const addItem = value => {

        if (false) {
            message.error('Vui lòng điền dầy đủ thông tin');
        } else {
            try {
                const newData = [...dataBanks];

                if (newData.length === 0) {
                    value.key = 0
                } else {
                    value.key = newData[newData.length - 1].key + 1
                }
                newData.push(value)
                setDataBanks(newData);
                setVisibleModal(false)
                message.success('Thêm thành công');


            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
            //setVisibleModal(false)
        }


    }

    const EditableCell = ({
                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {
        const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };


    const cancel = () => {
        setEditingKey('');
    };

    const edit = (record) => {
        formItem.setFieldsValue({

            ...record,
        });
        setEditingKey(record.key);
    };


    const save = async (key) => {
        try {
            const row = await formItem.validateFields();
            const newData = [...dataBanks];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setDataBanks(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setDataBanks(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    const deleteItem = async (record) => {
        try {
            const newData = [...dataBanks];
            //  const index = newData.findIndex((item) => record.key === item.key);

            for (let i = 0; i < newData.length; i++) {
                if (newData[i].key === record.key) {


                    newData.splice(i, 1)

                    setDataBanks(newData);
                    return

                } else {

                }

            }


        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }


    const columns = [
        {
            title: <div style={{textAlign: ""}}>Id</div>,
            editable: false,
            dataIndex: 'key',
        },
        {
            title: <div style={{textAlign: ""}}>Người thụ hưởng</div>,
            editable: true,
            dataIndex: 'beneficiaryName',
        },
        {
            title: <div style={{textAlign: ""}}>Tên ngân hàng</div>,
            editable: true,
            dataIndex: 'bankName',
        },
        {
            title: 'Tên viết tắt',
            dataIndex: 'bankShortName',
            editable: true,
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accountNumber',
            editable: true,
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Button

                type="primary"
                onClick={() => save(record.key)}
                style={{
                    marginRight: 8,
                }}
            >
              Lưu lại
            </Button>

            <Button
                onClick={cancel}
                style={{marginTop: "10px"}}>
                            Huỷ
            </Button>

          </span>
                ) : (
                    <div>
                        <div>
                            <Button

                                type="primary" icon={<EditOutlined/>}

                                disabled={editingKey !== ''} onClick={() => edit(record)}>

                            </Button>
                            <Button

                                icon={<DeleteOutlined/>}
                                style={{marginTop: "10px", marginLeft: "10px"}}
                                disabled={editingKey !== ''} onClick={() => deleteItem(record)}>

                            </Button>
                        </div>
                    </div>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    function onFinish(values) {


        values.bankInfos = dataBanks.map(o => {

            let idGet = o.id
            if (idGet === undefined) {
                idGet = null
            }

            return {
                id: idGet,
                beneficiaryName: o.beneficiaryName,
                bankName: o.bankName,
                bankShortName: o.bankShortName,
                accountNumber: o.accountNumber,
                note: o.note,
            }
        })
        values.id = 1
        values.config = null
        values.note = null

        console.log(values)
        API.updateConfig(values).then(rs => {
            console.log(rs)
            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                message.success("Cập nhật thành công")
            } else {
                message.error("Có lỗi xảy ra")
            }

        }).catch(err => console.log(err))
    }

    return (
        <div style={{padding: "10px"}}>
            <div style={{padding: "20px"}}>
                <h2 style={{fontWeight: 600, fontSize: "30px"}}>Cấu hình Admin</h2>
            </div>
            <div style={{padding: "20px"}}>


                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <div style={{background: "white", padding: "15px"}}>

                        <Descriptions title="Thông tin" bordered>
                            <Descriptions.Item label="Bảo hiểm" span={3}>
                                <Form.Item name="insurancePercentage" rules={[{required: true}]}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        style={{minWidth: "150px"}}/>
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="Rate" span={3}>
                                <Form.Item name="rate" rules={[{required: true}]}>
                                    <InputNumber
                                        //   formatter={value => `${value}`.toLocaleString('en-US', {style : 'currency', currency : 'VND'})}
                                        // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        formatter={value => currencyFormatter(value)}
                                        parser={currencyParser}
                                        style={{minWidth: "250px"}}
                                    />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="Địa chỉ kho" span={3}>
                                <Form.Item name="warehouseAddress" rules={[{required: true}]}>
                                    <Input/>
                                </Form.Item>
                            </Descriptions.Item>

                        </Descriptions>

                        <h3 style={{marginTop: "20px", fontSize: "25px"}}>Danh sách chuyển khoản</h3>
                        <div>
                            <Modal
                                title="Thêm thông tin ngân hàng"
                                centered
                                visible={visibleModal}

                                footer={[]}
                            >

                                <Form

                                    name="basic"
                                    onFinish={addItem}
                                    initialValues={{
                                        beneficiaryName: "",
                                        bankName: "",
                                        bankShortName: "",
                                        accountNumber: "",
                                        note: "",


                                    }}
                                >

                                    <Form.Item
                                        label="Người thụ hưởng"
                                        name="beneficiaryName"
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Tên ngân hàng"
                                        name="bankName"
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        label="	Tên viết tắt"
                                        name="bankShortName"
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        label="	Số tài khoản"
                                        name="accountNumber"
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Ghi chú"
                                        name="note"
                                    >
                                        <Input/>
                                    </Form.Item>


                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Lưu
                                        </Button>
                                        <Button
                                            style={{marginLeft: "10px"}}
                                            onClick={() => setVisibleModal(false)}
                                        >
                                            Huỷ
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </Modal>


                            <div>
                                <Button

                                    onClick={() => setVisibleModal(true)}
                                    style={{margin: "10px 0"}}>Thêm thông tin chuyển khoản</Button>
                            </div>


                        </div>

                        <Form form={formItem} component={false}>
                            <Table

                                size="small"
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                bordered
                                dataSource={dataBanks}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                                pagination={{
                                    onChange: cancel,
                                }}
                            />
                        </Form>

                        <Button
                            name={"update"}
                            type="primary" htmlType="submit" size={'large'}>
                            Cập nhật thông tin
                        </Button>
                    </div>
                </Form>

            </div>
        </div>
    )
}

export default ConfigAdminScreen;
