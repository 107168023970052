import React from "react";
import {Tabs} from 'antd';
import TrackingAdminList from "../components/TrackingAdminList";

const {TabPane} = Tabs;

const PackageAdminScreen = () => {


    const onChange = (event) => {

    }

    return (
        <div style={{padding: "10px"}}>
            <div style={{padding: "20px"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Quản lý tracking</h2>

            </div>
            <Tabs defaultActiveKey="1" onChange={onChange()}>
                <TabPane tab="Tất cả đơn hàng" key="1">
                    <TrackingAdminList type={"all"}/>
                </TabPane>
                <TabPane tab="Chưa phân loại" key="no-sort">
                    <TrackingAdminList type={"no-sort"}/>
                </TabPane>
                <TabPane tab="Đơn đã có khách nhận" key="2">
                    <TrackingAdminList type="customer"/>
                </TabPane>

                <TabPane tab="Đơn hàng đã xoá" key="4">
                    <TrackingAdminList type="delete"/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default PackageAdminScreen;
