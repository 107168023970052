import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Table} from "antd";
import CONSTANT from "../config/constant.json";
import moment from "moment";
import Customer from "../components/Customer";
import Text from "antd/es/typography/Text";


const {TextArea} = Input;
const {Option} = Select;

const AirplaneDetailAdmin = (props) => {

    const dateFormat = 'DD/MM/YYYY';
    const dateFormatUpdate = 'YYYY/MM/DD';
    const [fields, setFields] = useState([]);

    const [airplaneDetail, setAirplaneDetail] = useState([]);
    const [statusPackage, setStatusPackage] = useState(0);
    const [listPackage, setListPackage] = useState([]);
    const [listPackageAll, setListPackageAll] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [listItemTemp, setListItemTemp] = useState([]);


    useEffect(() => {
        getDataDetail();
          API.getAllPackageAirplane().then(rs => {
       // API.getAllPackage().then(rs => {
            setListPackageAll(rs.result)
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    /*
        const showModal = () => {
            setIsModalVisible(true);
        };
    */
    const handleOk = () => {
        setIsModalVisible(false);

        let newItem = [...listPackage];
        for (let i = 0; i < listItemTemp.length; i++) {
            newItem.push(listItemTemp[i])
        }

        setListPackage(newItem)
    };


    const handleCancel = () => {
        setIsModalVisible(false);
        setListItemTemp([])
    };


    /*

        const onSearch = value => {
            let data = {code: [value]}
            console.log(data)
            API.scanAll(data).then(rs => {
                console.log(rs)
                if (rs.result.itemVOHashSet.length > 0) {
                    setListItemTemp(rs.result.itemVOHashSet)
                    showModal();
                }
            })
        }
    */


    const dataSource = listPackage.map(o => {
        return {
            key: o.id,
            id: o.id,
            owner:  o.ownerVO|| o.owner  ,
            name: o.name,
        }
    })

    const removeItem = async id => {

        try {
            const newData = [...listPackage];
            //  const index = newData.findIndex((item) => record.key === item.key);
            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id === id) {
                    newData.splice(i, 1)
                    setListPackage(newData);
                    return
                } else {

                }

            }


        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }

    const addItem = async id => {

        try {
            const newData = [...listPackageAll];
            const newDataPackage = [...listPackage];
            //  const index = newData.findIndex((item) => record.key === item.key);
            for (let i = 0; i < listPackageAll.length; i++) {
                if (newData[i].id === id) {
                    let flag = true;
                    for (let i = 0; i < newDataPackage.length; i++) {
                        if (newData[i].id === id) {
                            flag = false
                        }
                    }
                    if (flag) {
                        newDataPackage.push(newData[i])
                        setListPackage(newDataPackage)
                    }
                    return
                }

            }


        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }


    const columns = [
        {
            title: <div style={{textAlign: "center"}}>Mã Package</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Tên</div>,
            key: "nameItem",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.name}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Khách hàng</div>,
            key: "ownerVO",
            render(o) {

                if (o.owner !== null) {
                    return (
                        <Customer data={o.owner}/>
                    )
                }
            }


        },
        {
            title: <div style={{textAlign: "center"}}></div>,
            key: "id-remove",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    <Button onClick={() => {
                        removeItem(o.id)
                    }}>
                        Xoá
                    </Button>
                    <Button size="large">
                        <a rel="noopener noreferrer" href={"/package/" + o.id} target="_blank">Chi tiết</a>
                    </Button>

                </div>;
            },
        },

    ];

    const handleChangeStatus = (value) => {
        setStatusPackage(value)
    }


    const getStatusFromConstant = (status) => {
        let mapStatus = CONSTANT.STATUS_PACKAGE;
        return (
            <Select defaultValue={status} style={{width: 300}} onChange={handleChangeStatus}>
                <Option value={0}>{mapStatus[0]}</Option>

                <Option value={2}>{mapStatus[2]}</Option>
                <Option value={3}>{mapStatus[3]}</Option>


                <Option value={8}>{mapStatus[8]}</Option>
                <Option value={9}>{mapStatus[9]}</Option>
                <Option value={10}>{mapStatus[10]}</Option>
                <Option value={11}>{mapStatus[11]}</Option>
                <Option value={12}>{mapStatus[12]}</Option>
                <Option value={13}>{mapStatus[13]}</Option>
                <Option value={14}>{mapStatus[14]}</Option>
                <Option value={15}>{mapStatus[15]}</Option>
            </Select>
        )


    }


    const onFinish = (values) => {

        values.id = airplaneDetail.id

        // if (values.dateOfFly !== undefined) {
        if (values.dateOfFly !== null) {
            if (values.dateOfFly.isValid()) {
                values.dateOfFly = values.dateOfFly.format(dateFormatUpdate)
            }
        }

        if (values.estimatedArrivalDate !== null) {
            if (values.estimatedArrivalDate.isValid()) {
                values.estimatedArrivalDate = values.estimatedArrivalDate.format(dateFormatUpdate)
            }
        }

        if (values.estimatedDeliveryDate !== null) {
            if (values.estimatedDeliveryDate.isValid()) {
                values.estimatedDeliveryDate = values.estimatedDeliveryDate.format(dateFormatUpdate)
            }
        }

        //  }
        if (values.note === undefined) {
            values.note = ""
        }
        values.listPackageId = listPackage.map(p => {
            return p.id
        })
        values.status = statusPackage

        API.updateAirplaneDetail(values)
            .then(rs => {

                    message.success(rs.result)
                    getDataDetail()
                }
            ).catch((er) => {
            console.log(er)
            message.error("Có lỗi xảy ra vui lòng thử lại")
        })


    }

    const getDataDetail = () => {

        const idGet = props.match.params.id
        API.getAirplaneById(idGet).then(result => {
            let airplaneDetail = result.result
            console.log(airplaneDetail)

            setAirplaneDetail(airplaneDetail)
            if (airplaneDetail.dateOfFly !== null) {
                airplaneDetail.dateOfFly = moment(airplaneDetail.dateOfFly, dateFormat)
            }

            if (airplaneDetail.estimatedArrivalDate !== null) {
                airplaneDetail.estimatedArrivalDate = moment(airplaneDetail.estimatedArrivalDate, dateFormat)
            }

            if (airplaneDetail.estimatedDeliveryDate !== null) {
                airplaneDetail.estimatedDeliveryDate = moment(airplaneDetail.estimatedDeliveryDate, dateFormat)
            }

            setListPackage(airplaneDetail.packageVOList)
            setStatusPackage(airplaneDetail.status)
            setFields([
                {
                    name: ["id"],
                    value: airplaneDetail.id,
                },
                {
                    name: ["codeAirplanes"],
                    value: airplaneDetail.codeAirplanes,
                },
                {
                    name: ["name"],
                    value: airplaneDetail.name,
                },
                {
                    name: ["dateCreate"],
                    value: airplaneDetail.dateCreate,
                },
                {
                    name: ["dateUpdate"],
                    value: airplaneDetail.dateUpdate,
                },
                {
                    name: ["dateOfFly"],
                    value: airplaneDetail.dateOfFly,
                },
                {
                    name: ["estimatedArrivalDate"],
                    value: airplaneDetail.estimatedArrivalDate,
                },
                {
                    name: ["estimatedDeliveryDate"],
                    value: airplaneDetail.estimatedDeliveryDate,
                },

            ])


        })
    }


    return (
        <div style={{padding: "10px"}}>


            <Modal title="Đã tìm thấy đơn hàng" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Bạn có muốn thêm tất cả vào package</p>

            </Modal>

            <div style={{padding: "20px", background: "white", margin: "10px 0"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Chi tiết Chuyến bay</h2>
                <Row style={{}}>
                    <Col span={13} offset={0} style={{background: "white", padding: "10px", marginLeft: "15px"}}>
                        <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off"
                              layout="vertical"
                              fields={fields}
                        >
                            <Row style={{padding: "10px 0"}}>
                                <Col span={10} offset={0}>
                                    <div style={{fontSize: 18,}}>Mã Package</div>
                                    <div
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 800,
                                            padding: "10px 0"
                                        }}>#{airplaneDetail.id}</div>
                                </Col>
                                <Col span={10} offset={2}>
                                    <div style={{fontSize: 18,}}>Trạng thái</div>
                                    <div style={{
                                        fontSize: 18,
                                        fontWeight: 600,
                                        padding: "10px 0"
                                    }}>

                                        {airplaneDetail.status ? getStatusFromConstant(airplaneDetail.status) : ""}


                                    </div>
                                </Col>
                            </Row>


                            <Form.Item
                                label={<p style={{fontSize: 18, margin: 0,}}>Tên chuyến bay</p>}
                                name="name"
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label={<p style={{fontSize: 18, margin: 0,}}>Ghi chú chuyến bay</p>}
                                name="note"
                            >
                                <TextArea/>
                            </Form.Item>

                            <Row>
                                <Col>

                                    <Form.Item
                                        label={<p style={{fontSize: 18, margin: 0,}}>Ngày bay dự kiến</p>}
                                        name="dateOfFly"
                                    >
                                        <DatePicker format={dateFormat}/>
                                    </Form.Item>
                                </Col>
                                <Col offset={1}>

                                    <Form.Item
                                        label={<p style={{fontSize: 18, margin: 0,}}>Ngày đáp dự kiến</p>}
                                        name="estimatedArrivalDate"
                                    >
                                        <DatePicker format={dateFormat}/>
                                    </Form.Item>
                                </Col>
                                <Col offset={1}>

                                    <Form.Item
                                        label={<p style={{fontSize: 18, margin: 0,}}>Ngày giao dự kiến</p>}
                                        name="estimatedDeliveryDate"
                                    >
                                        <DatePicker format={dateFormat}/>
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row>
                                <Col>
                                    <div>
                                        <h3 style={{fontSize: "19px"}}>Ngày tạo đơn</h3>
                                        <h3 style={{
                                            fontSize: "16px",
                                            fontWeight: 400
                                        }}> {airplaneDetail.dateCreate}</h3>
                                    </div>
                                </Col>
                                <Col offset={3}>

                                    {airplaneDetail.dateUpdate === null ? "" :
                                        <div>
                                            <h3 style={{fontSize: "19px"}}>Ngày cập nhật</h3>
                                            <h3 style={{
                                                fontSize: "16px",
                                                fontWeight: 400
                                            }}> {airplaneDetail.dateUpdate}</h3>
                                        </div>}
                                </Col>
                            </Row>


                            <Form.Item style={{marginTop: "20px"}}>


                                <Button
                                    name={"update"}
                                    type="primary" htmlType="submit" size={'large'}>
                                    Cập nhật chuyến bay
                                </Button>

                            </Form.Item>

                        </Form>

                    </Col>
                    <Col span={10} offset={0} style={{background: "white", padding: "10px", marginLeft: "20px"}}>
                        <h3 style={{fontSize: "24px"}}>Thêm package</h3>

                        <Select
                            showSearch
                            style={{width: "100%"}}
                            placeholder="Chọn package"
                            optionFilterProp="children"
                            onChange={value => {

                                addItem(value)

                            }}


                            // onFocus={onFocus}
                            // onBlur={onBlur}
                            // onSearch={onSearch}

                        >

                            <Option key={null} value={null}>Không chọn</Option>
                            {listPackageAll &&
                            listPackageAll.map((p) => (
                                <Option key={p.id} value={p.id}>
                                    {p.id} - {p.name} | Khách: {p.ownerVO.nickName}
                                </Option>
                            ))}
                        </Select>

                        <h3 style={{fontSize: "24px"}}>Danh sách package</h3>
                        <Table

                            size="small" bordered dataSource={dataSource} columns={columns}
                            summary={pageData => {
                                let totalTracking = 0;
                                pageData.forEach(() => {
                                    totalTracking ++;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>Tổng số</Table.Summary.Cell>
                                            <Table.Summary.Cell colSpan={3}>
                                                <Text style={{fontSize:"25px",fontWeight:"600"}}>{totalTracking}</Text>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>

                                    </>
                                );
                            }}


                        />


                    </Col>
                </Row>
            </div>


        </div>

    )


}

export default AirplaneDetailAdmin;