import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, message, Modal, notification, Row, Select, Table} from "antd";
import * as API from "../code/Api";
import CONSTANT from "../config/constant.json";
import moment from "moment";
import ItemList from "../components/ItemList";
import TextArea from "antd/es/input/TextArea";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

const {Option} = Select;

const ReceiveAdmin = () => {

    const [form] = Form.useForm();
    const [formTracking] = Form.useForm();
    const [listTracking, setListTracking] = useState([]);
    const dateFormat = 'YYYY/MM/DD';
    const [listUser, setListUser] = useState();
    const refForm = useRef();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [resetData, setResetData] = useState(false);
    const [customCustomer, setDefaultCustomer] = useState(null);
    const [spinningStatus, setSpinningStatus] = useState(false);
    const [count, setCount] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const inputGen = useRef();


    useEffect(() => {
        //setSpinningStatus(false)
    }, [listTracking]);


    useEffect(() => {
        console.log("ahihi", count)

    }, [count]);

    useEffect(() => {
        API.getListUserActive().then(rs => {
            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                setListUser(rs.result)
            }
        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /*
        useEffect(() => {


            //   setModalConfirmVisible(true)

            if (customTracking.id !== undefined) {
                console.log(customTracking)
                formTracking.setFieldsValue(customTracking)
                setIsModalVisible(true)
            }
            // eslint-disable-next-line
        }, [customTracking]);
    */


    useEffect(() => {
        if (resetData === true) {
            const newData = [...listTracking].length;
            setListTracking([])
            for (let i = 0; i < newData; i++) {
                refForm.current.submit()
            }

        }
        // eslint-disable-next-line
    }, [resetData]); // eslint-disable-next-line


    async function updateTrackingList() {


        for (let i = 0; i < listTracking.length; i++) {
            setCount(i + 1)
            formTracking.resetFields()
            let trackingT = listTracking[i]
            if (trackingT.status < 4) {
                trackingT.status = 4;
            }
            let owner = 1;


            if (trackingT.ownerTracking !== null) {
                owner = trackingT.ownerTracking.id;
            }


            if (trackingT.id.toString().includes("temp")) {

                let note = ""
                let split = 1
                if (trackingT.note !== undefined) {
                    note = trackingT.note
                }
                if (trackingT.split !== undefined) {
                    split = trackingT.split
                }
                let trackingRequest = {
                    codeTracking: trackingT.codeTracking,
                    note: note,
                    userId: owner,
                    status: trackingT.status,
                    listItemDTO: [],
                    split: split
                }

                console.log("chia", trackingRequest)

                await API.createTrackingUser([trackingRequest]).then(rs => {

                    if (rs.message === CONSTANT.STATUS.SUCCESS) {
                        message.success("Tạo đơn thành công")
                    } else {
                        message.error("Đơn trùng " + rs.result.toString())
                    }
                })

            } else {

                let trackingRequest = {
                    id: trackingT.id,
                    codeTracking: trackingT.codeTracking,
                    note: trackingT.note,
                    userId: owner,
                    status: trackingT.status,
                    listItemDTO: null,

                }
                if (trackingRequest.note === undefined) {
                    trackingRequest.note = ""
                }
                console.log(trackingRequest)

                await API.updateTrackingDetailAdminNew(trackingRequest).then(rs => {
                    if (rs.message === CONSTANT.STATUS.SUCCESS) {
                        message.success("Cập nhật thành công")

                    }

                })
            }


        }
        console.log("Đã xong update")
        form.resetFields()
        setResetData(true)
        setIsRunning(!isRunning)
        setSpinningStatus(!spinningStatus)
        setTimeout(function () {
            console.log("Đã xong update", spinningStatus)
            window.alert("Đã cập nhật xong")
            window.location.reload();
        }, 3000);
    }

    /*
        async function showDetail(id) {

            let tracking = getTrackingbyId(id)

            if (tracking.ownerTracking !== null) {
                tracking.ownerTracking = tracking.ownerTracking.id
            }
            setCustomTracking(tracking)


        }
    */


    function handleKeyUp(event) {
        // Enter
        if (event.keyCode === 13) {
            refForm.current.submit();
        }
    }

    function finishEditTrackingTemp(value) {
        console.log(value)

        if (value.ownerTracking === null) {
            message.error("Vui lòng chọn khách hàng")
            return
        }
        setIsModalVisible(false)
        handleUpdate(value)


    }


    /*    function getTrackingbyId(id) {
            const newData = [...listTracking];
            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id === id) {
                    return newData[i]
                }
            }
        }*/

    function handleUpdate(tracking) {

        const newData = [...listTracking];

        for (let i = 0; i < newData.length; i++) {
            if (newData[i].id === tracking.id) {
                tracking.key = tracking.id
                tracking.status = 6
                tracking.ownerTracking = getOwner(tracking.ownerTracking)
                newData[i] = tracking
                setListTracking(newData)
            }
        }

    }

    function getOwner(id) {
        if (id == null) {
            return null
        }
        const newData = [...listUser];

        for (let i = 0; i < newData.length; i++) {

            if (newData[i].id === id) {
                return newData[i];
            }
        }
    }

    /* function handeAdd(tracking) {
         console.log("tracking here " + tracking.id)
         if (customCustomer !== null && tracking.ownerTracking === null) {
             tracking.ownerTracking = getOwner(customCustomer)
         }

         const newData = [...listTracking];
         if (newData.length === 0) {
             newData.push(tracking)
             setListTracking(newData)

         }

         let flag = true;
         for (let i = 0; i < newData.length; i++) {
             if (newData[i].id === tracking.id) {
                 flag = false
             }
         }

         if (flag) {
             newData.push(tracking)
             setListTracking(newData)
         }


     }*/

    function handleDelete(id) {

        const newData = [...listTracking];
        for (let i = 0; i < newData.length; i++) {
            console.log("chạy lun")
            if (newData[i].id === id) {
                newData.splice(i, 1)
                setListTracking(newData);
            }
        }
    }


    // const onFinishOrder = (value) => {
    //     if (value.idUser !== undefined) {
    //         console.log("zoo nè")
    //         API.updateOwnerTracking(value).then(rs => {
    //
    //             if (rs.error === CONSTANT.STATUS.SUCCESS) {
    //                 message.success("Cập nhật khách hàng thành công")
    //             }
    //
    //         })
    //     } else {
    //         message.error("Vui lòng chọn khách hàng")
    //     }
    // }

    const onFinish = async (value) => {
        //setDefaultCustomer(null)

        //setSpinningStatus(true)

        if (value.listCode === undefined) {
            return
        }
        console.log("Nhấn vào")
        /// value = value.toString().replace("\n", "ahihi")


        // value.listCode = value.listCode.replaceAll("\n", ",")
        // console.log(value)
        //
        // eslint-disable-next-line
        value.listCode = await value.listCode.split("\n").map(x => {
            if (x !== "") {
                if (x.length >= 10) {
                    return x
                } else {

                    notification.open({
                        message: 'Tracking phải lớn hơn 10 ký tự',
                        duration: 1,
                        type: "error"
                    });
                }
            }

        });


        let listOrder_ = value.listCode
        listOrder_ = await listOrder_.filter(function (element) {
            return element !== undefined;
        });


        let count = 0;

        let listTrackingDisplay = []

        for (const item of listOrder_) {
            // eslint-disable-next-line
            await API.searchTracking({listCode: [item]}).then(rs => {
                if (rs.result !== undefined) {
                    if (rs.message !== "not found") {
                        let flag = true
                        for (const t of listTrackingDisplay) {

                            if (rs.result[0].codeTracking === t.codeTracking) {
                                flag = false
                            }
                        }
                        if (flag) {


                            listTrackingDisplay.push(rs.result[0])

                        } else {
                            notification.open({
                                message: 'Đơn trùng',
                                duration: 1,
                            });
                        }


                    } else {

                        let flag = true
                        for (const t of listTrackingDisplay) {

                            if (item === t.codeTracking) {
                                flag = false
                            }
                        }
                        if (flag) {
                            let tracking = {
                                codeTracking: item,
                                key: count + "temp",
                                id: count + "temp",
                                status: 4,
                                ownerTracking: getOwner(customCustomer),
                                listItemVO: [],
                            }
                            listTrackingDisplay.push(tracking)

                        } else {

                        }


                        // console.log(rs.result)
                        // message.error(rs.result)
                    }

                }
            })
            console.log("chạy lần n " + count, listTracking.length)
            if (count === listTracking.length) {
                notification.open({
                    message: 'Thêm thành công',
                    duration: 1,
                    type: "success"
                });
            }

            count++;
        }
        console.log("chạy xong nè ")


        setListTracking(listTrackingDisplay)
        // setSpinningStatus(false)
        /* for (let i = 0; i <=  listOrder_.length; i++) {
             if (i === listOrder_.length) {
                 console.log("chạy lần cúi ")
                 console.log(listTrackingDisplay)
                 break
             }
             console.log("chạy lần thứ " + i)
            API.searchTracking({listCode: [listOrder_[i]]}).then(rs => {
                if (rs.result !== undefined) {
                    if (rs.message !== "not found") {
                        listTrackingDisplay.push(rs.result[0])
                        console.log(listTrackingDisplay)

                    } else {
                        let tracking = {
                            codeTracking: listOrder_[i],
                            key: i + "temp",
                            id: i + "temp",
                            status: 6,
                            ownerTracking: null,
                            listItemVO: [],
                        }

                        console.log("add chưa có")


                         listTrackingDisplay.push(tracking)
                        // console.log(rs.result)
                        // message.error(rs.result)
                    }

                }
            })
        }*/


        // for (const item of listOrder_) {
        //     await  API.searchTracking({listCode: [item]}).then(rs => {
        //         console.log("chạy lần thứ " + count)
        //         if (rs.result !== undefined) {
        //             if (rs.message !== "not found") {
        //                 console.log("add đã có sẵn")
        //
        //                 listTrackingDisplay.push(rs.result[0])
        //             } else {
        //                 let tracking = {
        //                     codeTracking: item,
        //                     key: count + "temp",
        //                     id: count + "temp",
        //                     status: 6,
        //                     ownerTracking: null,
        //                     listItemVO: [],
        //                 }
        //
        //                 console.log("add chưa có")
        //                 listTrackingDisplay.push(tracking)
        //                 // console.log(rs.result)
        //                 // message.error(rs.result)
        //             }
        //
        //         }
        //     })
        // }

        /*  for (let i = 0; i < listOrder_.length; i++) {
              API.searchTracking({listCode: [listOrder_[i]]}).then(rs => {
                  if (rs.result !== undefined) {
                      if (rs.message !== "not found") {
                          console.log("add đã có sẵn")
                          console.log("chạy lần thứ " + i)
                           handeAdd(rs.result[0])
                      } else {
                          let tracking = {
                              codeTracking: listOrder_[i],
                              key: i + "temp",
                              id: i + "temp",
                              status: 6,
                              ownerTracking: null,
                              listItemVO: [],
                          }

                          console.log("add chưa có")
                          handeAdd(tracking)
                          // console.log(rs.result)
                          // message.error(rs.result)
                      }

                  }
              })


          }*/


        //  console.log(listArray)

        /*
            API.searchTracking(value).then(rs => {
                    if (rs.result !== undefined) {
                        if (rs.message !== "not found") {
                            setListOrder(rs.result)
                        } else {
                            // console.log(rs.result)
                            // message.error(rs.result)
                        }

                    }
                })
                    .catch(er => console.log(er))
                    */

        // createTracking(value.listCode).then(() => {
        //
        //     API.searchTracking(value).then(rs => {
        //         if (rs.result !== undefined) {
        //             if (rs.message !== "not found") {
        //                 setListOrder(rs.result)
        //
        //             } else {
        //                 // console.log(rs.result)
        //                 // message.error(rs.result)
        //             }
        //
        //         }
        //     })
        //         .catch(er => console.log(er))
        //
        //
        // })


    }


    /*   async function createTracking(tracking) {

           // for (const x in list) {
           //     let valueSearch = {listCode: [list[x]]}
           //     console.log(valueSearch)
           //     await API.searchTracking(valueSearch).then(rs => {
           //
           //         if (rs.result !== undefined) {
           //             if (rs.message !== "not found") {
           //             } else {
           //
           //                 let trackingUser = {
           //                     codeTracking: list[x],
           //                     note: "Đơn được tạo bởi hệ thống",
           //                     listItemDTO: []
           //                 }
                           API.createTrackingUser(trackingUser).then(rs => {
                                   console.log()
                           })
               //             // message.error(rs.result)
               //         }
               //
               //     }
               // })
          // }
           console.log("chạy sau")

       };*/

    const getStatusFromConstan = (tracking) => {
        let mapStatus = CONSTANT.STATUS_TRACKING;
        //  let mapColor = CONSTANT.COLOR_TAG;
        // return <Tag color={mapColor[status]}>
        //     {mapStatus[status]}
        // </Tag>;

        return (
            <Select defaultValue={tracking.status} style={{width: 250}}
                    onChange={(value) => {
                        const newData = [...listTracking];
                        for (let i = 0; i < newData.length; i++) {
                            if (newData[i].id === tracking.id) {
                                tracking.status = value
                                newData[i] = tracking
                                setListTracking(newData)

                            }
                        }

                    }
                    }>

                <Option value={4}>{mapStatus[4]}</Option>
                <Option value={5}>{mapStatus[5]}</Option>


            </Select>
        )


    }

    const dataSource = listTracking.map(o => {
        return {
            key: o.id,
            id: o.id,
            status: o.status,
            codeTracking: o.codeTracking,
            goodsName: o.goodsName,
            noteTracking: o.noteTracking,
            dateCreate: o.dateCreate,
            dateUpdate: o.dateUpdate,
            dateReceive: o.dateReceive,
            listItemVO: o.listItemVO,
            userName: o.userName,
            ownerTracking: o.ownerTracking,
            customNoteUser: o.customNoteUser,

        }
    })


    function onChangeCusCustom(tracking, value) {


        const newData = [...listTracking];
        console.log("change cus", newData)

        for (let i = 0; i < newData.length; i++) {
            console.log("ahihi", newData[i], tracking)
            if (newData[i].id === tracking.id) {
                tracking.ownerTracking = getOwner(value)
                newData[i] = tracking
                setListTracking(newData)

            }
        }

    }

    const columns = [
        {
            title: <div style={{textAlign: "center"}}>Mã ĐH</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: <div style={{textAlign: "center"}}>Trạng thái</div>,
            key: "status",
            render(o) {


                return <div style={{textAlign: "center",}}>{getStatusFromConstan(o)}</div>;


            },
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],


        },
        {
            title: <div style={{textAlign: "center"}}>Khách hàng</div>,
            key: "userName",
            render(o) {
                if (o.ownerTracking !== null) {
                    return (
                        <Select
                            style={{minWidth: "200px"}}
                            onChange={(cus) => {
                                onChangeCusCustom(o, cus)
                            }
                            }
                            defaultValue={o.ownerTracking.id}
                        >
                            {listUser &&
                            listUser.map((cus) => (

                                <Option key={cus.id} value={cus.id}>
                                    {cus.id} - {cus.name} - {cus.nickName}
                                </Option>
                            ))}
                        </Select>)
                }
                return (
                    <Select
                        style={{minWidth: "200px"}}
                        onChange={(cus) => {
                            onChangeCusCustom(o, cus)
                        }
                        }
                    >
                        {listUser &&
                        listUser.map((cus) => (

                            <Option key={cus.id} value={cus.id}>
                                {cus.id} - {cus.name} - {cus.nickName}
                            </Option>
                        ))}
                    </Select>)
                // return (
                //     <div>
                //         <Customer data={o.ownerTracking}/>
                //     </div>
                // )

            }


        },

        {
            title: <div style={{textAlign: "center",}}>Code Tracking</div>,
            key: "codeTracking",
            render(o) {
                return <div style={{textAlign: "center",}}>
                    <CopyToClipboard text={o.codeTracking}>
                        <Button onClick={() => {
                            message.success("Đã copy code tracking")
                        }}>
                            {o.codeTracking}
                        </Button>
                    </CopyToClipboard>
                </div>;
            },

        },
        {
            title: <div style={{textAlign: "center"}}>Chia tracking</div>,
            key: "split",
            render(o) {

                return (
                    <InputNumber
                        onChange={(e) => {

                            const newData = [...listTracking];
                            for (let i = 0; i < newData.length; i++) {
                                if (newData[i].id === o.id) {
                                    o.split = e
                                    newData[i] = o
                                    setListTracking(newData)

                                }
                            }
                        }}
                        defaultValue={1}
                    />)
            }
        },
        {
            title: <div style={{textAlign: "center"}}>Ghi chú (Admin)</div>,
            key: "note",
            render(o) {

                //  return <div style={{textAlign: "center"}}>{o.noteTracking}</div>;
                return <Input
                    onChange={(e) => {
                        const newData = [...listTracking];
                        for (let i = 0; i < newData.length; i++) {
                            if (newData[i].id === o.id) {
                                o.note = e.target.value
                                newData[i] = o
                                setListTracking(newData)

                            }
                        }
                    }
                    }
                    name={o.id + "note"} defaultValue={o.noteTracking}/>


            },

        },
        {
            title: <div style={{textAlign: "center"}}>Ngày tạo</div>,
            key: "dateCreate",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.dateCreate}</div>;
            },
            sorter: (a, b) => new Date(a.dateCreate) - new Date(b.dateCreate),
            sortDirections: ['descend', 'ascend'],


        },


        {
            title: "",
            key: "action",
            render(o) {

                return (
                    <span>

           {/*   <Button onClick={() => {

                  //  formTracking.resetFields()
                  // showDetail(o.id)

                  if (o.ownerTracking !== null) {
                      o.ownerTracking = o.ownerTracking.id
                  }

                  formTracking.setFields([
                      {
                          name: ["id"],
                          value: o.id,
                      },
                      {
                          name: ["codeTracking"],
                          value: o.codeTracking,
                      },
                      {
                          name: ["ownerTracking"],
                          value: o.ownerTracking,
                      },
                      {
                          name: ["noteTracking"],
                          value: o.noteTracking,
                      },
                      {
                          name: ["listItemVO"],
                          value: o.listItemVO,
                      },
                  ])
                  setIsModalVisible(true)


              }} type="primary" size="large">
                <span className="nav-text">Chỉnh sửa</span>
              </Button>
*/}

                        <Button onClick={() => handleDelete(o.id)} type="danger" size="large">
                <span className="nav-text">Xoá</span>
              </Button>

              <Button size="large">
                <a rel="noopener noreferrer" href={"/tracking-admin-detail/" + o.id} target="_blank">Chi tiết</a>
              </Button>
          </span>
                );
            },
        },

    ]


    // const receivedAll = (id) => {
    //     console.log("đã nhận all " + id)
    //     API.updateStatusTracking(id, 7).then(() => {
    //         message.success("Cập nhật trạng thái đã nhận đủ thành công")
    //         setListTracking([])
    //         refForm.current.submit()
    //     })
    //
    //
    // }

    // const receivedHalf = (id) => {
    //     console.log("đã nhận một ít " + id)
    //     API.updateStatusTracking(id, 5).then(rs => {
    //         message.success("Cập nhật trạng thái đã nhận nhưng còn thiếu")
    //         setListTracking([])
    //         refForm.current.submit()
    //     })
    //
    // }

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }


    async function genCode() {
        console.log(inputGen.current.value)
        let value = inputGen.current.value
        inputGen.current.value = null
        if (value) {
            console.log("chạy gen ra code")
            let listCode = [];
            for (let i = 0; i < value; i++) {
                listCode.push("DP_TK_" + new Date().getTime() + "\n")
                await timeout(10);
            }
            listCode = listCode.toString().replace(/,/g, '')
            console.log(listCode)
            form.setFields([
                    {
                        name: ["listCode"],
                        value: listCode,
                    },

                ]
            )


        }
    }

    return (
        <div style={{padding: "10px"}}>

            {/*<Spin spinning={spinningStatus} tip={"Đang load dữ liệu"}>*/}
            <div style={{padding: "10px", background: "white"}}>


                <div>
                    <Modal
                        title="Thay đổi thông tin tracking"
                        centered
                        visible={isModalVisible}
                        // visible={true}

                        footer={[]}
                    >

                        <Form
                            form={formTracking}
                            name="basic"
                            onFinish={finishEditTrackingTemp}

                        >

                            <Form.Item
                                label="Id Tracking"
                                name="id"

                            >
                                <Input disabled/>
                            </Form.Item>


                            <Form.Item name="ownerTracking" label="Khách hàng">
                                <Select
                                    showSearch
                                    style={{width: 300}}
                                    placeholder="Chọn khách hàng"
                                    optionFilterProp="children"
                                >


                                    {listUser &&
                                    listUser.map((cus) => (

                                        <Option key={cus.id} value={cus.id}>
                                            {cus.id} - {cus.name} - {cus.nickName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>


                            <Form.Item
                                style={{display: "none"}}
                                label="listItemVO"
                                name="listItemVO"
                            >
                                <TextArea/>
                            </Form.Item>


                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Cập nhật tracking
                                </Button>
                                <Button
                                    style={{marginLeft: "20px"}}
                                    onClick={() => {
                                        setIsModalVisible(false)


                                    }}
                                >
                                    Huỷ
                                </Button>
                            </Form.Item>

                        </Form>

                    </Modal>
                </div>


                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Nhập hàng vào kho</h2>
                <div>
                    <h3>Lựa chọn khách hàng mặc định</h3>
                    <Select style={{minWidth: "400px"}}
                            showSearch
                            allowClear={true}
                            value={customCustomer}
                            onChange={(value) => {
                                setDefaultCustomer(value)

                            }}>
                        {listUser &&
                        listUser.map((cus) => (

                            <Option key={cus.id} value={cus.id}>
                                {cus.id} - {cus.name} - {cus.nickName}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            <Row style={{marginTop: "10px",}}>

                <Col span={24} offset={0} style={{background: "white", padding: "20px"}}>
                    <InputNumber ref={inputGen} style={{maxWidth: "300px"}}/>
                    <Button
                        style={{marginLeft: "20px", marginBottom: "20px"}}
                        onClick={() => {

                            genCode()

                            //setSpinningStatus(true)
                        }}>
                        Tự thêm Tracking
                    </Button>
                    <Form
                        onKeyUp={handleKeyUp}
                        ref={refForm}
                        form={form}
                        onFinish={onFinish}
                        initialValues={{code: ""}}

                    >
                        <Form.Item

                            label="Mã tracking"
                            name="listCode"
                        >
                            <TextArea rows={4}/>
                        </Form.Item>


                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                style={{marginLeft: "20px"}}
                                onClick={() => {
                                    setIsRunning(!isRunning)
                                    updateTrackingList()

                                    //setSpinningStatus(true)
                                }}>
                                Cập nhật toàn bộ
                            </Button>

                        </Form.Item>
                    </Form>

                </Col>

            </Row>
            <Row>
                {isRunning ? <div><h1>Đã chạy {count} trên {listTracking.length}</h1></div> : ""}
            </Row>
            <Row style={{marginTop: "10px"}}>


                <Table
                    loading={spinningStatus}
                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "70"],
                    }}
                    expandable={{
                        expandedRowRender: o => {


                            return <div>
                                {o.noteOrder === '' ? "" : <p style={{fontSize: "17px", fontWeight: 500}}><span
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: 500
                                    }}>Ghi chú (User): </span>{o.customNoteUser}
                                </p>}
                                {o.dateReceive === null ? "" :
                                    <p style={{fontSize: "17px", fontWeight: 500}}><span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: 500
                                        }}>Ngày nhận hàng dự kiến: </span>{moment(o.dateReceive).format(dateFormat)}
                                    </p>}


                                {o.listItemVO.length > 0 ?

                                    <>
                                        <h3 style={{marginTop: "10px"}}>Danh sách sản phẩm</h3>

                                        <ItemList listItem={o.listItemVO}/></>
                                    : ""}


                            </div>;


                        },

                    }}


                />


            </Row>

            {/*</Spin>*/}
        </div>
    )
}

export default ReceiveAdmin;
