import React from "react";
import TrackingCustomerList from "../components/TrackingCustomerList"

const OrderTrackingCustomer = () => {


    return (<div>
        <TrackingCustomerList/>
    </div>)
}


export default OrderTrackingCustomer;