import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Table, Tag} from "antd";
import moment from "moment";
import CONSTANT from "../config/constant.json"
import {Link, useHistory} from "react-router-dom";
// import ItemList from "../components/ItemList"


const AirplaneAdminList = (props) => {
    const history = useHistory();

    const [listAirplane, setListAirplane] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formAirplane] = Form.useForm();
    const [isLoading, setIsLoading]  = useState(true);
    const dateFormat = 'YYYY/MM/DD';
    useEffect(() => {
        loadData(); // eslint-disable-next-line
    }, []);

    /*
        function formatDate(dateInput) {

            return moment(dateInput, "DD/MM/YYYY").format(dateFormat)
        }
    */


    const loadData = () => {
        API.getAllAirplane().then((res) => {

            const type = props.type
            const list = res.result;

            let result = list.filter(airplane => airplane.status !== 0);

            if (type === "delete") {
                result = res.result.filter(airplane => airplane.status === 0);
            }


            setListAirplane(result);
            setIsLoading(!isLoading)
            if (result.length === 0 ){
                message.error("Không có chuyến bay")
                return
            }
            message.success("Tải xong chuyến bay")

        });
    };

    function onFinish(value) {

        if (value.name === undefined) {
            message.error("Vui lòng nhập tên")
            return
        }
        if (value.dateOfFly !== undefined) {
            value.dateOfFly = moment(value.dateOfFly).format(dateFormat)
        } else {
            value.dateOfFly = ""
        }
        value.listPackageId = []
        console.log(value)
        API.createAirPlane(value).then(rs => {
            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                message.success("Tạo thành công")
                setIsModalVisible(false)
                history.push("/airplane/" + rs.result);

            }
        })


    }

    const dataSource = listAirplane.map(o => {
        return {
            key: o.id,
            id: o.id,
            codeAirplanes: o.codeAirplanes,
            name: o.name,
            status: o.status,
            dateCreate: o.dateCreate,
            dateOfFly: o.dateOfFly,
            packageVOList: o.packageVOList,
            dateUpdate: o.dateUpdate,


        }
    })

    const getStatusFromConstan = (status) => {
        let mapStatus = CONSTANT.STATUS_AIRPLANE;
        let mapColor = CONSTANT.COLOR_TAG;
        return <Tag color={mapColor[status]}>
            {mapStatus[status]}
        </Tag>;


    }

    const columns = [
        {
            title: <div style={{textAlign: "center", maxWidth: "80px"}}>Chuyến bay</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: <div style={{textAlign: "center"}}>Tên </div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.name}</div>;
            },
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],


        },
        {
            title: <div style={{textAlign: "center"}}>Trạng thái</div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>{getStatusFromConstan(o.status)}</div>;
            },
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],


        },
        {
            title: <div style={{textAlign: "center"}}>Số thùng</div>,
            key: "count",
            width: '10%',
            render(o) {

                if (o.packageVOList !== null) {
                    console.log(o.packageVOList)
                    return <div style={{textAlign: "center", fontSize:"25px"}} >{ o.packageVOList.length}</div>;

                }

            }

        },
        {
            title: <div style={{textAlign: "center"}}>Ngày tạo</div>,
            key: "dateCreate",
            render(o) {
                return <div style={{textAlign: "center"}}>{(o.dateCreate)}</div>;
            },
            sorter: (a, b) => new Date(a.dateCreate) - new Date(b.dateCreate),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: <div style={{textAlign: "center"}}>Ngày cập nhật</div>,
            key: "dateUpdate",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    {(o.dateUpdate)}
                </div>;
            },
            sorter: (a, b) => new Date(a.dateUpdate) - new Date(b.dateUpdate),
            sortDirections: ['descend', 'ascend'],


        },
        {
            title: "",
            key: "action",
            render(o) {
                const url = "/airplane/" + o.id;
                return (
                    <span>
            <Link
                to={{
                    pathname: url,
                }}
            >
              <Button type="primary" size="large">
                <span className="nav-text">Chi tiết</span>
              </Button>
            </Link>
          </span>
                );
            },
        },

    ]

    return (<div style={{}}>
        <Row style={{}}>
            <div>

                <Button
                    onClick={() => {

                        formAirplane.resetFields()
                        setIsModalVisible(true)
                    }}
                    type="primary" style={{marginLeft: "35px", marginBottom: '20px'}}>


                    Thêm chuyến bay
                </Button>
                <Modal
                    title="Thêm chuyến bay"
                    centered
                    visible={isModalVisible}
                    // visible={true}

                    footer={[]}
                >
                    <Form
                        form={formAirplane}
                        name="basic"
                        onFinish={onFinish}

                    >

                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Tên chuyến bay</p>}
                            name="name"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Ngày bay</p>}
                            name="dateOfFly"
                        >
                            <DatePicker placeholder="Chọn ngày" format="DD/MM/YYYY"/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Thêm mới
                            </Button>
                            <Button
                                style={{marginLeft: "20px"}}
                                onClick={() => {
                                    formAirplane.resetFields();
                                    setIsModalVisible(false)
                                }}>
                                Huỷ
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>

            <Col span={22} offset={1} style={{}}>
                <Table
                    loading={isLoading}
                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}

                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "70"],
                    }}
                />
            </Col>

        </Row>
    </div>)
}

export default AirplaneAdminList;