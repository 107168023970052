import {Button, Col, DatePicker, Form, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import CONSTANT from "../config/constant.json";

const {Option} = Select;
const {RangePicker} = DatePicker;


const SearchTracking = (props) => {


    const [listUser, setListUser] = useState();
    const [listAirplane, setListAirplane] = useState();
    const dateFormatSearch = 'YYYY/MM/DD';
    let mapStatus = CONSTANT.STATUS_TRACKING;


    useEffect(() => {
        API.getListUserActive().then(rs => {
            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                setListUser(rs.result)
            }
        })
        API.getAllAirplane().then(rs => {
            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                setListAirplane(rs.result)
            }
        })
    }, []);

    return (
        <div>
            <Row>
                <Col  offset={1} style={{marginBottom: "20px"}}>
                    {props.user === "no" ? "" :
                        <div>
                            <h3>Tìm theo khách hàng</h3>
                            <Form.Item
                                name="userId"
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn khách hàng"
                                    optionFilterProp="children"
                                    style={{minWidth: "200px"}}
                                >
                                    <Option key={null} value={null}>
                                        Chọn tất cả
                                    </Option>

                                    {listUser &&
                                    listUser.map((cus) => (

                                        <Option key={cus.id} value={cus.id}>
                                            {cus.id} - {cus.name} - {cus.nickName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    }
                </Col>
                <Col  offset={1}>
                    <h3>Tìm theo ngày</h3>
                    <Form.Item

                        name="date"
                    >
                        <RangePicker format={dateFormatSearch}
                                     placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}/>
                    </Form.Item>

                </Col>

                <Col  offset={1}>
                    <h3>Tìm theo chuyến bay</h3>
                    <Form.Item
                        name="airplanesId"
                    >
                        <Select
                            showSearch
                            placeholder="Chọn chuyến bay"
                            optionFilterProp="children"
                            style={{minWidth: "200px"}}
                        >
                            <Option key={null} value={null}>
                                Chọn tất cả
                            </Option>
                            <Option key={0} value={0}>
                                Không có chuyến bay
                            </Option>
                            {listAirplane &&
                            listAirplane.map((a) => (

                                <Option key={a.id} value={a.id}>
                                    {a.id} - {a.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>


                </Col>
                <Col  offset={1}>
                    {props.user === "no" ? "" :
                        <div>
                            <h3>Trạng thái</h3>
                            <Form.Item
                                name={"status"}
                            >
                                <Select  style={{width: 300}}
                                >
                                    <Option value={0}>{mapStatus[0]} </Option>
                                    <Option value={1}>{mapStatus[1]} </Option>
                                    <Option value={2}>{mapStatus[2]}</Option>
                                    <Option value={3}>{mapStatus[3]}</Option>
                                    <Option value={4}>{mapStatus[4]}</Option>
                                    <Option value={5}>{mapStatus[5]}</Option>
                                    <Option value={7}>{mapStatus[7]}</Option>
                                    <Option value={10}>{mapStatus[10]}</Option>
                                    <Option value={11}>{mapStatus[11]}</Option>
                                    <Option value={12}>{mapStatus[12]}</Option>
                                    <Option value={13}>{mapStatus[13]}</Option>
                                    <Option value={14}>{mapStatus[14]}</Option>
                                    <Option value={15}>{mapStatus[15]}</Option>
                                </Select>
                            </Form.Item>
                        </div>}

                </Col>
                <Col>
                    <Button htmlType="submit" style={{marginTop: "28px", marginLeft: "50px"}}>Tìm kiếm</Button>
                </Col>


            </Row>


        </div>)
}

export default SearchTracking;