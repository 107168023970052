import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, message, Row, Table, Tag} from "antd";
import CONSTANT from "../config/constant.json"
import {Link} from "react-router-dom";
// import ItemList from "../components/ItemList"


const AirplaneUserList = (props) => {

    const [listAirplane, setListAirplane] = useState([]);
    const [isLoading, setIsLoading]  = useState(true);

    useEffect(() => {
        loadData(); // eslint-disable-next-line
    }, []);

    /*
        function formatDate(dateInput) {

            return moment(dateInput, "DD/MM/YYYY").format(dateFormat)
        }
    */


    const loadData = () => {
        API.getAllAirplaneUser().then((res) => {

            const type = props.type
            const list = res.result;

            let result = list.filter(airplane => airplane.status !== 0);

            if (type === "delete") {
                result = res.result.filter(airplane => airplane.status === 0);
            }


            setListAirplane(result);
            setIsLoading(!isLoading)
            if (result.length === 0 ){
                message.error("Không có chuyến bay")
                return
            }
            message.success("Tải xong chuyến bay")
        });
    };


    const dataSource = listAirplane.map(o => {
        return {
            key: o.id,
            id: o.id,
            codeAirplanes: o.codeAirplanes,
            name: o.name,
            status: o.status,
            dateCreate: o.dateCreate,
            dateOfFly: o.dateOfFly,
            packageVOList: o.packageVOList,
            dateUpdate: o.dateUpdate,


        }
    })

    const getStatusFromConstan = (status) => {
        let mapStatus = CONSTANT.STATUS_AIRPLANE;
        let mapColor = CONSTANT.COLOR_TAG;
        return <Tag color={mapColor[status]}>
            {mapStatus[status]}
        </Tag>;


    }

    const columns = [
        {
            title: <div style={{textAlign: "center", maxWidth: "80px"}}>Chuyến bay</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: <div style={{textAlign: "center"}}>Tên </div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.name}</div>;
            },
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],


        },
        {
            title: <div style={{textAlign: "center"}}>Trạng thái</div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>{getStatusFromConstan(o.status)}</div>;
            },
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],


        },
        {
            title: <div style={{textAlign: "center"}}>Ngày tạo</div>,
            key: "dateCreate",
            render(o) {
                return <div style={{textAlign: "center"}}>{(o.dateCreate)}</div>;
            },
            sorter: (a, b) => new Date(a.dateCreate) - new Date(b.dateCreate),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: <div style={{textAlign: "center"}}>Ngày cập nhật</div>,
            key: "dateUpdate",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    {(o.dateUpdate)}
                </div>;
            },
            sorter: (a, b) => new Date(a.dateUpdate) - new Date(b.dateUpdate),
            sortDirections: ['descend', 'ascend'],


        },
        {
            title: "",
            key: "action",
            render(o) {
                const url = "/airplane-user/" + o.id;
                return (
                    <span>
            <Link
                to={{
                    pathname: url,
                }}
            >
              <Button type="primary" size="large">
                <span className="nav-text">Chi tiết</span>
              </Button>
            </Link>
          </span>
                );
            },
        },

    ]

    return (<div style={{}}>
        <Row style={{}}>

            <Col span={22} offset={1} style={{}}>
                <Table
                    loading={isLoading}
                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}

                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "70"],
                    }}
                />
            </Col>

        </Row>
    </div>)
}

export default AirplaneUserList;