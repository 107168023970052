import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, Form, Row, Table} from "antd";
import CONSTANT from "../config/constant.json";
import Text from "antd/es/typography/Text";


const AirplaneDetailUser = (props) => {


    const [fields, setFields] = useState([]);

    const [airplaneDetail, setAirplaneDetail] = useState([]);
    const [listPackageVO, setListPackageVO] = useState([]);


    useEffect(() => {
        getDataDetail();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const getStatusFromConstant = (status) => {
        let mapStatus = CONSTANT.STATUS_TRACKING;
        return mapStatus[status];


    }


    const getDataDetail = () => {

        const idGet = props.match.params.id
        API.getAirplaneById(idGet).then(result => {
            let airplaneDetail = result.result


            setAirplaneDetail(airplaneDetail)

            setListPackageVO(airplaneDetail.packageVOList)

            setFields([
                {
                    name: ["id"],
                    value: airplaneDetail.id,
                },
                {
                    name: ["codeAirplanes"],
                    value: airplaneDetail.codeAirplanes,
                },
                {
                    name: ["name"],
                    value: airplaneDetail.name,
                },
                {
                    name: ["dateCreate"],
                    value: airplaneDetail.dateCreate,
                },
                {
                    name: ["dateUpdate"],
                    value: airplaneDetail.dateUpdate,
                },
                {
                    name: ["dateOfFly"],
                    value: airplaneDetail.dateOfFly,
                },
                {
                    name: ["estimatedArrivalDate"],
                    value: airplaneDetail.estimatedArrivalDate,
                },
                {
                    name: ["estimatedDeliveryDate"],
                    value: airplaneDetail.estimatedDeliveryDate,
                },

            ])


        })
    }

    const columns = [
        {
            title: <div style={{textAlign: "center"}}>Mã Package</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Tên</div>,
            key: "nameItem",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.name}</div>;
            },
        },

        {
            title: <div style={{textAlign: "center"}}></div>,
            key: "id-remove",
            render(o) {
                return <div style={{textAlign: "center"}}>

                    <Button size="large">
                        <a rel="noopener noreferrer" href={"/package-user/" + o.id} target="_blank">Chi tiết</a>
                    </Button>

                </div>;
            },
        },

    ];

    const dataSource = listPackageVO.map(o => {

        return {
            key: o.id,
            id: o.id,
            name: o.name,
        }
    })


    return (
        <div style={{padding: "10px"}}>


            <div style={{padding: "20px", background: "white", margin: "10px 0"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Chi tiết Chuyến bay </h2>
                <Row style={{}}>
                    <Col span={13} offset={0} style={{background: "white", padding: "10px", marginLeft: "15px"}}>
                        <Form name="dynamic_form_nest_item" autoComplete="off"
                              layout="vertical"
                              fields={fields}
                        >
                            <Row style={{padding: "10px 0"}}>
                                <Col span={10} offset={0}>
                                    <div style={{fontSize: 18,}}>Mã chuyến bay</div>
                                    <div
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 800,
                                            padding: "10px 0"
                                        }}>#{airplaneDetail.id}</div>
                                </Col>
                                <Col span={10} offset={2}>
                                    <div style={{fontSize: 18,}}>Trạng thái</div>
                                    <div style={{
                                        fontSize: 18,
                                        fontWeight: 600,
                                        padding: "10px 0"
                                    }}>

                                        {airplaneDetail.status ? getStatusFromConstant(airplaneDetail.status) : ""}


                                    </div>
                                </Col>
                            </Row>
                            <h3 style={{fontSize: "19px"}}>Tên chuyến bay</h3>
                            <h3 style={{
                                fontSize: "18px",
                                fontWeight: 400,
                                paddingBottom: "30px"
                            }}> {airplaneDetail.name}</h3>


                            <Row>
                                <Col>
                                    {airplaneDetail.dateOfFly === null ? "" :
                                        <div>
                                            <h3 style={{fontSize: "19px"}}>Ngày bay dự kiến</h3>
                                            <h3 style={{
                                                fontSize: "16px",
                                                fontWeight: 400
                                            }}> {airplaneDetail.dateOfFly}</h3>
                                        </div>}

                                </Col>
                                <Col offset={1}>
                                    {airplaneDetail.estimatedArrivalDate === null ? "" :
                                        <div>
                                            <h3 style={{fontSize: "19px"}}>Ngày đáp dự kiến</h3>
                                            <h3 style={{
                                                fontSize: "16px",
                                                fontWeight: 400
                                            }}> {airplaneDetail.estimatedArrivalDate}</h3>
                                        </div>}

                                </Col>
                                <Col offset={1}>
                                    {airplaneDetail.estimatedDeliveryDate === null ? "" :
                                        <div>
                                            <h3 style={{fontSize: "19px"}}>Ngày giao dự kiến</h3>
                                            <h3 style={{
                                                fontSize: "16px",
                                                fontWeight: 400
                                            }}> {airplaneDetail.estimatedDeliveryDate}</h3>
                                        </div>}

                                </Col>
                            </Row>


                            <Row>
                                <Col>
                                    <div>
                                        <h3 style={{fontSize: "19px"}}>Ngày tạo đơn</h3>
                                        <h3 style={{
                                            fontSize: "16px",
                                            fontWeight: 400
                                        }}> {airplaneDetail.dateCreate}</h3>
                                    </div>
                                </Col>
                                <Col offset={3}>

                                    {airplaneDetail.dateUpdate === null ? "" :
                                        <div>
                                            <h3 style={{fontSize: "19px"}}>Ngày cập nhật</h3>
                                            <h3 style={{
                                                fontSize: "16px",
                                                fontWeight: 400
                                            }}> {airplaneDetail.dateUpdate}</h3>
                                        </div>}
                                </Col>
                            </Row>


                        </Form>
                        <h3 style={{fontSize: "24px"}}>Danh sách package</h3>
                        <Table

                            size="small" bordered dataSource={dataSource} columns={columns}
                            summary={pageData => {
                                let totalTracking = 0;
                                pageData.forEach(() => {
                                    totalTracking ++;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell>Tổng số</Table.Summary.Cell>
                                            <Table.Summary.Cell colSpan={3}>
                                                <Text style={{fontSize:"25px",fontWeight:"600"}}>{totalTracking}</Text>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>

                                    </>
                                );
                            }}
                        />

                    </Col>

                </Row>
            </div>


        </div>

    )


}

export default AirplaneDetailUser;