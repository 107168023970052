import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, Form, Input, message, Row, Table, Tag} from "antd";
import moment from "moment";
import CONSTANT from "../config/constant.json"
import {Link} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SearchTracking from "./SearchTracking";
// import ItemList from "../components/ItemList"
const {Search} = Input;
const TrackingCustomerList = () => {

    const [listOrder, setListOrder] = useState([]);
    const [listOrderStock, setListOrderStock] = useState([]);
    const dateFormat = 'YYYY/MM/DD';
    const dateFormatSearch = 'YYYY/MM/DD';
    const [isLoading, setIsLoading]  = useState(true);
    const [form] = Form.useForm();
    // const [dateSearch, setDateSearch] = useState(null);
    useEffect(() => {

        loadData();
        // eslint-disable-next-line
    }, []);

    const loadData = () => {
        API.getTracking().then((res) => {
            let result = res.result.filter(tracking => tracking.status > 2);

            setListOrder(result);

            setListOrderStock(result);
            setIsLoading(!isLoading)
            if (result.length === 0 ){
                message.error("Không có đơn hàng nào")
                return
            }
            message.success("Tải xong đơn hàng")
        });
    };

    // async function onClickSearch() {
    //     if (dateSearch !== null) {
    //         let startDate = dateSearch[0].add(-1, 'd').format(dateFormat)
    //         let endDate = dateSearch[1].add(1, 'd').format(dateFormat)
    //         let newData = [...listOrderStock]
    //         newData = await newData.filter(tracking => {
    //             let date = moment(tracking.dateWarehouseUsReceived, "DD/MM/YYYY HH:mm:ss").format(dateFormat)
    //             return moment(date).isBetween(startDate, endDate)
    //
    //         })
    //         if (newData.length === 0) {
    //             message.error("Không có đơn nào cả")
    //         }
    //         setListOrder(newData)
    //     }
    // }


    const dataSource = listOrder.map(o => {
        return {
            key: o.id,
            id: o.id,
            status: o.status,
            codeTracking: o.codeTracking,
            goodsName: o.goodsName,
            noteOrder: o.noteOrder,
            noteTracking: o.noteTracking,
            dateCreate: o.dateCreate,
            dateUpdate: o.dateUpdate,
            dateReceive: o.dateReceive,
            listItemVO: o.listItemVO,
            listPackage: o.listPackage,
            listAirplanes: o.listAirplanes,
            dateWarehouseUsReceived: o.dateWarehouseUsReceived,

        }
    })

    const getStatusFromConstan = (status) => {
        let mapStatus = CONSTANT.STATUS_TRACKING;
        let mapColor = CONSTANT.COLOR_TAG;
        console.log(mapColor)
        return <Tag color={mapColor[status]}>
            {mapStatus[status]}
        </Tag>;


    }


    // function onChangeDate(date) {
    //
    //     if (date !== null) {
    //         setDateSearch(date)
    //     }
    //
    // }

    const columns = [
        {
            title: <div style={{textAlign: "center"}}>Mã ĐH</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: <div style={{textAlign: "center"}}>Trạng thái</div>,
            key: "status",
            render(o) {


                return <div style={{textAlign: "center"}}>{getStatusFromConstan(o.status)}</div>;
            },
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],


        },
        {
            title: <div style={{textAlign: "center"}}>Package/Airplane</div>,
            key: "pack",
            width: '15%',
            render(o) {
                let list = []

                if (o.listPackage !== null) {
                    if (o.listPackage.length > 0) {
                        list.push(
                            <Button size="large"
                                    key={"package"}
                            >
                                <a rel="noopener noreferrer"
                                   href={"/package/" + o.listPackage[0].id}
                                   target="_blank">{o.listPackage[0].name}
                                </a>
                            </Button>)
                    }
                }

                if (o.listAirplanes.length > 0) {
                    list.push(

                        <Button
                            key={"airplane"}
                            style={{background: "#39a9cb", marginLeft:"5px"}}
                            size="large">
                            <a rel="noopener noreferrer"
                               style={{color: "white",}}
                               href={"/airplane/" + o.listAirplanes[0].id}
                               target="_blank">{o.listAirplanes[0].name}
                            </a>
                        </Button>
                    )
                }

                return <>

                    <div style={{textAlign: "center"}}>
                        {list}
                    </div>
                </>

            }

        },

        {
            title: <div style={{textAlign: "center"}}>Code Tracking</div>,
            key: "codeTracking",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    <CopyToClipboard text={o.codeTracking}>
                        <Button onClick={() => {
                            message.success("Đã copy code tracking")
                        }}>
                            {o.codeTracking}
                        </Button>
                    </CopyToClipboard>
                </div>;
            },

        },
        {
            title: <div style={{textAlign: "center"}}>Ghi chú</div>,
            key: "noteTracking",
            render(o) {
                return <div style={{textAlign: "center"}}>{(o.noteTracking)}</div>;
            },


        },

        {
            title: <div style={{textAlign: "center"}}>Ngày kho nhận hàng</div>,
            key: "dateUpdate",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.dateWarehouseUsReceived}</div>;
            },


        },
        {
            title: "",
            key: "action",
            render(o) {
                const url = "/tracking/" + o.id;
                return (
                    <span>
            <Link
                to={{
                    pathname: url,
                }}
            >
              <Button type="primary" size="large">
                <span className="nav-text">Chi tiết</span>
              </Button>
            </Link>
          </span>
                );
            },
        },

    ]

    async function onChangeCodeSearch(value) {
        console.log(value)
        if (value === "") {
            setListOrder(listOrderStock)
            return
        }
        let newData = [...listOrderStock]
        newData = await newData.filter(tracking => {
            return tracking.codeTracking.includes(value)

        })
        console.log(newData)
        if (newData.length === 0) {
            message.error("Không có đơn nào cả")
        }
        setListOrder(newData)
    }

    const onFinish = (values) => {

        let body = {dateFrom: null, dateTo: null, airplanesId: null}

        if (values.date !== undefined) {
            if (values.date !== null) {
                body.dateFrom = values.date[0].add(-1, 'd').format(dateFormatSearch)
                body.dateTo = values.date[1].add(1, 'd').format(dateFormatSearch)

            }
        }

        if (values.airplanesId !== undefined) {
            body.airplanesId = values.airplanesId
        }

        API.searchTrackingUser(body).then(rs => {
            console.log(rs)
            if (rs.result.length === 0) {
                message.error("Không có tracking nào")
                setListOrder([])
                return
            }

            setListOrder(rs.result)
        })

    };


    return (<div style={{}}>

        <div style={{padding: "20px"}}>
            <h2 style={{fontWeight: 600, fontSize: "25px"}}>Quản lý Tracking</h2>

        </div>
        <Form
            onFinish={onFinish}
            form={form}
        >
            <SearchTracking user={"no"}/>

        </Form>
        <Row>
            <Col span={7} offset={1} style={{marginBottom: "20px", marginTop: "20px"}}>
                <h3>Tìm theo code tracking</h3>
                <Search placeholder="" onSearch={onChangeCodeSearch}/>
            </Col>
            <Col span={7} offset={1} style={{marginTop: "50px"}}>
                <Button onClick={() => {
                    setListOrder(listOrderStock)
                    form.resetFields()
                }}>
                    Xoá tìm kiếm
                </Button>
            </Col>

        </Row>

        <Row style={{padding: "20px",}}>
            <Col span={22} offset={1} style={{}}>
                <Table
                    loading={isLoading}
                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}
                    expandable={{
                        expandedRowRender: o => {
                            if (o.listItemVO !== null) {
                                return <div>
                                    {o.noteOrder === '' ? "" : <p style={{fontSize: "17px", fontWeight: 500}}><span
                                        style={{fontSize: "18px", fontWeight: 500}}>Ghi chú: </span>{o.noteOrder}</p>}
                                    {o.dateReceive === null ? "" : <p style={{fontSize: "17px", fontWeight: 500}}><span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: 500
                                        }}>Ngày nhận hàng dự kiến: </span>{moment(o.dateReceive).format(dateFormat)}
                                    </p>}

                                    {/*<ItemList listItem={o.listItemVO}/>*/}
                                </div>;
                            }
                            return;
                        },

                    }}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "70"],
                    }}
                />
            </Col>

        </Row>
    </div>)
}

export default TrackingCustomerList;