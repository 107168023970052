import React from "react";
import {Button, Col, Popover, Row} from "antd";


const Customer = props => {


    let ownerTracking = props.data;
    if (ownerTracking !== null) {
        return (<div>
            <Popover content={
                <div>
                    <Row>
                        <Col>
                            <h3> Tên khách hàng:</h3>
                        </Col>
                        <Col offset={1}>
                            {ownerTracking.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3> Nick Name:</h3>

                        </Col>
                        <Col offset={1}>
                            {ownerTracking.nickName}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3> Ghi chú:</h3>
                        </Col>
                        <Col offset={1}>
                            {ownerTracking.note}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h3> Email:</h3>
                        </Col>
                        <Col offset={1}>
                            {ownerTracking.email}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h3> Địa chỉ:</h3>
                        </Col>
                        <Col offset={1}>
                            {ownerTracking.address}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3> Số điện thoại:</h3>
                        </Col>
                        <Col offset={1}>
                            {ownerTracking.numberPhone}
                        </Col>
                    </Row>


                </div>

            } title={"Tên: " + ownerTracking.name} trigger="click">
                <Button>{ownerTracking.nickName}</Button>

            </Popover>
        </div>)
    }
    return ""


}


export default Customer;