import React from "react";
import {Button, Col, Form, Image, Input, notification, Row} from "antd";
import logo_company from "../images/DP_Logo.png";
import auth from "../auth";
import * as API from "../code/Api";
import CONSTANT from "../config/constant.json"


export const Login = (props) => {

    const inputStyle = {
        backgroundColor: "#eef3f7",
        borderColor: "#eef3f7",
        color: "#5e6278",
        transition: "color .2s ease,background-color .2s ease",
        padding: "10px",
        fontSize: 15,
    };


    let token = localStorage.getItem(CONSTANT.LOCAL_KEY.TOKEN);
    if (token != null) {
        auth.login(() => {

            let role = localStorage.getItem(CONSTANT.LOCAL_KEY.ROLE);
            if (role === CONSTANT.ROLE.ADMIN || role === CONSTANT.ROLE.ADMIN_VN || role === CONSTANT.ROLE.ADMIN_US) {
                props.history.push("/home");
            } else if (role === CONSTANT.ROLE.USER) {
                props.history.push("/tracking-user-all");
            }
            // if (role === "customer") {
            //   props.history.push("/home-user");
            // }
            //  props.history.push("/update-info");
        });
    }

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg,
        });
    };

    const onFinish = (values) => {
        API.login(values)
            .then((res) => {
                console.log(res)
                if (res.message === "success") {
                    auth.login(() => {
                        // openNotificationWithIcon("success", "Đăng nhập hệ thống thành công");
                        try {
                            localStorage.setItem(CONSTANT.LOCAL_KEY.TOKEN, res.result.token);
                            localStorage.setItem(CONSTANT.LOCAL_KEY.ROLE, res.result.role);
                            localStorage.setItem(CONSTANT.LOCAL_KEY.NAME, res.result.name);
                        } catch (e) {
                        }
                        if (res.result.role === CONSTANT.ROLE.ADMIN || res.result.role === CONSTANT.ROLE.ADMIN_VN || res.result.role === CONSTANT.ROLE.ADMIN_US) {
                            openNotificationWithIcon("success", "Đăng nhập hệ thống thành công");
                            window.location.href = "/home";
                        }
                        if (res.result.role === CONSTANT.ROLE.USER) {
                            openNotificationWithIcon("success", "Đăng nhập hệ thống thành công");
                            window.location.href = "/tracking-user-all";
                        }
                        // if (role === "customer") {
                        //   window.location.href = "/home-user";
                        // }
                        // window.location.href = "/update-info";
                    });
                } else {
                    openNotificationWithIcon("error", res.result);
                }
            });
    };

    return (
        <Row style={{height: "100vh"}} justify="middle">
            <Col
                style={{background: "", textAlign: "center"}}
                xs={24}
                lg={12}
            >
                <div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Image width={400} preview={false} src={logo_company}/>
                </div>

            </Col>
            <Col style={{height: "100vh", background: "#eb7c2d", textAlign: "center"}} xs={24} lg={12}>
                <div
                    style={{textAlign: "left", marginLeft: "20%", paddingTop: "20%"}}
                >
                    {" "}

                    <h3 style={{color: "#fff", fontSize: 40, margin: 0}}>
                        Xin chào !
                    </h3>
                    {/*  <h3 style={{color: "#fff", fontSize: 20}}>
                        Chưa có tài khoản ?
                        <Link to="/register" style={{color: "#000"}}> Tạo tài khoản</Link>
                    </h3>*/}
                </div>
                <Form
                    style={{padding: "5% 20%"}}
                    layout="vertical"
                    name="basic"
                    // initialValues={{email: "admin", password: "system"}}
                    onFinish={onFinish}

                >
                    <Form.Item
                        label={<p style={{fontSize: 20, margin: 0, color: "white"}}>Email</p>}
                        name="email"

                    >
                        <Input style={inputStyle}/>
                    </Form.Item>

                    <Form.Item
                        label={<p style={{fontSize: 20, margin: 0, color: "white"}}>Mật khẩu</p>}
                        name="password"

                    >
                        <Input.Password style={inputStyle}/>
                    </Form.Item>

                    <Form.Item style={{position: "absolute"}}>
                        <Button
                            style={{
                                borderColor: "transparent",
                                backgroundColor: "#289672"
                                , borderRadius: 5,
                                padding: "0 30%"
                            }}
                            size="large"
                            type="primary"
                            htmlType="submit"
                        >
                            Đăng nhập
                        </Button>
                    </Form.Item>

                </Form>

            </Col>
        </Row>
    );
};

export default Login;
