import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, Form, Input, message, Row, Table, Tag} from "antd";
import moment from "moment";
import CONSTANT from "../config/constant.json"
import {Link} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Customer from "./Customer";
import SearchTracking from "./SearchTracking";
import ItemList from "./ItemList";
import exportFromJSON from 'export-from-json'

const {Search} = Input;
// import ItemList from "../components/ItemList"


const TrackingAdminList = (props) => {

    const [form] = Form.useForm();
    const [listOrder, setListOrder] = useState([]);
    const [listOrderStock, setListOrderStock] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [customer, setCustomer] = useState(2);

    const dateFormat = 'dd-MM-yyyy HH:mm:ss';
    const dateFormatSearch = 'YYYY/MM/DD';
    // const [dateSearch, setDateSearch] = useState(null);


    useEffect(() => {

        loadData(); // eslint-disable-next-line
    }, []);


    const loadData = () => {
        API.getTracking().then((res) => {

            const type = props.type
            const list = res.result;

            let result = list.filter(tracking => tracking.status !== 0);
            if (type === "customer") {
                result = result.filter(tracking => tracking.ownerTracking !== null);
            }

            if (type === "no-customer") {
                result = result.filter(tracking => tracking.ownerTracking === null);
            }

            if (type === "delete") {
                result = res.result.filter(tracking => tracking.status === 0);
            }
            if (type === "no-receive") {
                result = res.result.filter(tracking => 3 <= tracking.status && tracking.status < 5);
            }
            if (type === "no-sort") {
                result = res.result.filter(tracking => {
                    if (tracking.ownerTracking !== null) {
                        if (tracking.ownerTracking.id === 1)
                            return true;
                    }
                    return false
                });
            }


            setListOrder(result);
            setListOrderStock(result);
            setIsLoading(!isLoading)
            if (result.length === 0) {
                message.error("Không có đơn hàng nào")
                return
            }
            message.success("Tải xong đơn hàng")

        });
    };

    const dataSource = listOrder.map(o => {
        return {
            key: o.id,
            id: o.id,
            status: o.status,
            codeTracking: o.codeTracking,
            goodsName: o.goodsName,
            noteOrder: o.noteOrder,
            dateCreate: o.dateCreate,
            dateUpdate: o.dateUpdate,
            dateReceive: o.dateReceive,
            listItemVO: o.listItemVO,
            userName: o.userName,
            ownerTracking: o.ownerTracking,
            dateWarehouseUsReceived: o.dateWarehouseUsReceived,
            dateOfFly: o.dateOfFly,
            noteTracking: o.noteTracking,
            listPackage: o.listPackage,
            listAirplanes: o.listAirplanes,
            note: o.note,
            customNoteUser: o.customNoteUser,

        }
    })

    const getStatusFromConstan = (status) => {
        let mapStatus = CONSTANT.STATUS_TRACKING;
        let mapColor = CONSTANT.COLOR_TAG;
        return <Tag color={mapColor[status]}>
            {mapStatus[status]}
        </Tag>;


    }

    /*

        function formatDate(dateInput) {
            console.log(dateInput)
            if  (dateInput === null){
                return ""
            }

            return moment(dateInput,"dd-MM-yyyy HH:mm:ss").format(dateFormat)
        }
    */

    //
    // function onChangeDate(date) {
    //
    //     if (date !== null) {
    //         setDateSearch(date)
    //     }
    //
    // }
    //
    //
    // async function onClickSearch() {
    //     if (dateSearch !== null) {
    //         let startDate = dateSearch[0].add(-1, 'd').format(dateFormatSearch)
    //         let endDate = dateSearch[1].add(1, 'd').format(dateFormatSearch)
    //         let newData = [...listOrderStock]
    //         newData = await newData.filter(tracking => {
    //             let date = moment(tracking.dateWarehouseUsReceived, "DD/MM/YYYY HH:mm:ss").format(dateFormatSearch)
    //             return moment(date).isBetween(startDate, endDate)
    //
    //         })
    //         if (newData.length === 0) {
    //             message.error("Không có đơn nào cả")
    //         }
    //         setListOrder(newData)
    //     }
    // }

    const columns = [
        {
            title: 'STT',
            key: 'index',
            render: (text, record, index) => {
                return <div style={{textAlign: "center"}}>{index + 1}</div>;
            },
            width: '5%'
        },
        {
            title: <div style={{textAlign: "center"}}>Mã ĐH</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            width: '10%'
        },
        {
            title: <div style={{textAlign: "center"}}>Ngày nhận kho</div>,
            key: "dateWarehouseUsReceived",
            width: '10%',
            render(o) {
                return <div style={{textAlign: "center"}}>
                    {(o.dateWarehouseUsReceived)}
                </div>;
            },
        },

        {
            title: <div style={{textAlign: "center",}}>Code Tracking</div>,
            key: "codeTracking",
            width: '10%',
            render(o) {
                return <div style={{textAlign: "center",}}>
                    <CopyToClipboard text={o.codeTracking}>
                        <Button onClick={() => {
                            message.success("Đã copy code tracking")
                        }}>
                            {o.codeTracking}
                        </Button>
                    </CopyToClipboard>
                </div>;
            },

        },
        {
            title: <div style={{textAlign: "center"}}>Ghi chú (Admin)</div>,
            key: "noteAmin",
            width: "10%",
            render(o) {
                return <div style={{textAlign: "center"}}>{(o.note)}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Ghi chú (User)</div>,
            key: "noteusrr",
            width: "10%",
            render(o) {
                return <div style={{textAlign: "center"}}>{(o.customNoteUser)}</div>;
            },
        },

        {
            title: <div style={{textAlign: "center"}}>Khách hàng</div>,
            key: "userName",
            width: '10%',
            render(o) {
                if (o.ownerTracking !== null) {
                    return (
                        <div style={{textAlign: "center"}}>
                            <Customer data={o.ownerTracking}/>
                        </div>

                    )
                }
            }
        },
        {
            title: <div style={{textAlign: "center"}}>Package/Airplane</div>,
            key: "pack",
            width: '10%',
            render(o) {
                let list = []

                if (o.listPackage !== null) {
                    if (o.listPackage.length > 0) {
                        list.push(
                            <Button size="large"
                                    key={"package"}
                            >
                                <a rel="noopener noreferrer"
                                   href={"/package/" + o.listPackage[0].id}
                                   target="_blank">{o.listPackage[0].name}
                                </a>
                            </Button>)
                    }
                }

                if (o.listAirplanes.length > 0) {
                    list.push(
                        <Button
                            key={"airplane"}
                            style={{background: "#39a9cb", marginLeft: "5px"}}
                            size="large">
                            <a rel="noopener noreferrer"
                               style={{color: "white",}}
                               href={"/airplane/" + o.listAirplanes[0].id}
                               target="_blank">{o.listAirplanes[0].name}
                            </a>
                        </Button>
                    )
                }

                return <>

                    <div style={{textAlign: "center"}}>
                        {list}
                    </div>
                </>

            }

        },
        {
            title: <div style={{textAlign: "center"}}>Trạng thái</div>,
            key: "status",
            width: '10%',
            render(o) {

                let list = []
                list.push(
                    <div key={"tag"} style={{textAlign: "center"}}>
                        {getStatusFromConstan(o.status)}
                    </div>
                )


                return <>{list}</>

            },
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],


        },


        {
            title: "",
            key: "action",
            render(o) {
                const url = "/tracking-admin-detail/" + o.id;
                return (
                    <span>
            <Link
                target="_blank" rel="noopener noreferrer"
                to={{
                    pathname: url,

                }}

            >
              <Button type="primary" size="large">
                <span className="nav-text">Chi tiết</span>
              </Button>
            </Link>
          </span>
                );
            },
        },

    ]

    // async function handleChange(value) {
    //     setCustomer(value)
    //
    //     if (value === null) {
    //         setListOrder(listOrderStock)
    //         return
    //     }
    //     let newData = [...listOrderStock]
    //     newData = await newData.filter(tracking => {
    //         if (tracking.ownerTracking !== null) {
    //             if (tracking.ownerTracking.id === value)
    //                 return true
    //         }
    //         return false
    //     })
    //     if (newData.length === 0) {
    //         message.error("Khách này không có đơn nào cả")
    //     }
    //     setListOrder(newData)
    // }

    async function onChangeCodeSearch(value) {
        console.log(value)
        if (value === "") {
            setListOrder(listOrderStock)
            return
        }
        let newData = [...listOrderStock]
        newData = await newData.filter(tracking => {
            return tracking.codeTracking.includes(value)

        })
        console.log(newData)
        if (newData.length === 0) {
            message.error("Không có đơn nào cả")
        }
        setListOrder(newData)
    }

    const onFinish = (values) => {

        let body = {userId: null, dateFrom: null, dateTo: null, airplanesId: null}
        if (values.userId !== undefined) {
            body.userId = values.userId
        }


        if (values.date !== undefined) {
            if (values.date !== null) {
                body.dateFrom = values.date[0].add(-1, 'd').format(dateFormatSearch)
                body.dateTo = values.date[1].add(1, 'd').format(dateFormatSearch)

            }
        }

        if (values.airplanesId !== undefined) {
            body.airplanesId = values.airplanesId
        }

        if (values.status !== undefined) {
            body.status = values.status
        }

        API.searchTrackingAdmin(body).then(rs => {
            console.log(rs)
            if (rs.result.length === 0) {
                message.error("Không có tracking nào")
                setListOrder([])
                return
            }

            setListOrder(rs.result)
        })

    };


    return (<div style={{}}>

        <Form
            onFinish={onFinish}
            form={form}
        >
            <SearchTracking/>

        </Form>
        <Row>
            <Col span={7} offset={1} style={{marginBottom: "20px",}}>
                <h3>Tìm theo code tracking</h3>
                <Search placeholder="" onSearch={onChangeCodeSearch}/>
            </Col>
            <Col span={7} offset={1} style={{marginTop: "50px"}}>
                <Button onClick={() => {
                    setListOrder(listOrderStock)
                    form.resetFields()
                }}>
                    Xoá tìm kiếm
                </Button>
            </Col>
            <Col>

                <Button
                    type="primary"
                    style={{
                        marginBottom: 20,
                    }}
                    onClick={() => {
                        console.log(listOrder)
                        let listExport = []
                        for (let i = 0; i < listOrder.length; i++) {
                            let od = listOrder[i]
                            let rowAdd = {}
                            rowAdd.stt = i + 1
                            rowAdd.phan_loai = "Tracking"
                            if (od.listAirplanes != null) {
                                if (od.listAirplanes.length > 0) {
                                    rowAdd.chuyen_bay = od.listAirplanes[0].id + "  " + od.listAirplanes[0].name
                                } else {
                                    rowAdd.chuyen_bay = ""
                                }
                            }
                            if (od.listPackage != null) {
                                if (od.listPackage.length > 0) {
                                    rowAdd.thung = od.listPackage[0].id + "  " + od.listPackage[0].name
                                } else {
                                    rowAdd.thung = ""
                                }
                            }

                            rowAdd.codeTracking = "'" + od.codeTracking + "'"

                            rowAdd.ngay_kho_nhan = od.dateWarehouseUsReceived
                            rowAdd.ten_khach = od.ownerTracking.nickName + " - " + od.ownerTracking.name
                            rowAdd.email = od.ownerTracking.email
                            rowAdd.ten_item = ""
                            rowAdd.so_luong = ""
                            rowAdd.gia = ""

                            listExport.push(rowAdd)
                            if (od.listItemVO != null) {
                                if (od.listItemVO.length > 0) {
                                    for (let j = 0; j < od.listItemVO.length; j++) {
                                        let a = od.listItemVO[j]
                                        let rowAddItem = {}
                                        rowAddItem.stt = j + i + 2
                                        rowAddItem.phan_loai = "Item"
                                        rowAddItem.chuyen_bay = ""
                                        rowAddItem.thung = ""
                                        rowAdd.codeTracking = ""
                                        rowAddItem.ngay_kho_nhan = ""
                                        rowAddItem.ten_khach = ""
                                        rowAddItem.email = ""
                                        rowAddItem.ten_item = a.nameItem
                                        rowAddItem.so_luong = a.quantity
                                        rowAddItem.gia = a.price
                                        listExport.push(rowAddItem)
                                    }
                                }
                            }

                        }
                        console.log(listExport)
                        const exportFileName = 'dp-cargo'
                        const exportType = exportFromJSON.types.xls
                        const exportData = [...listExport]


                        exportFromJSON({
                            data: exportData, fileName : exportFileName, exportType: exportType
                        })
                    }}
                >
                    Xuất exel
                </Button>
            </Col>
        </Row>

        <Row style={{marginTop: "10px"}}>
            <Col span={22} offset={1} style={{}}>
                <Table
                    sty
                    loading={isLoading}
                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}
                    expandable={{
                        expandedRowRender: o => {
                            return <div>
                                {o.noteOrder === '' ? "" : <p style={{fontSize: "17px", fontWeight: 500}}><span
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: 500
                                    }}>Ghi chú (User): </span>{o.customNoteUser}
                                </p>}
                                {o.dateReceive === null ? "" :
                                    <p style={{fontSize: "17px", fontWeight: 500}}><span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: 500
                                        }}>Ngày nhận hàng dự kiến: </span>{moment(o.dateReceive).format(dateFormat)}
                                    </p>}


                                {o.listItemVO.length > 0 ?

                                    <>
                                        <h3 style={{marginTop: "10px"}}>Danh sách sản phẩm</h3>

                                        <ItemList listItem={o.listItemVO}/></>
                                    : ""}


                            </div>
                        },

                    }}
                    pagination={false}
                />
            </Col>

        </Row>
    </div>)
}

export default TrackingAdminList;