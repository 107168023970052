import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, Form, Input, message, Modal, Row, Select, Table} from "antd";
import CONSTANT from "../config/constant.json";
import moment from "moment";
import Customer from "../components/Customer";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Text from "antd/es/typography/Text";


const {TextArea} = Input;
const {Option} = Select;


const PackageDetailUser = (props) => {


    const [fields, setFields] = useState([]);

    const [listItem, setListItem] = useState([]);
    const [listTracking, setListTracking] = useState([])
    const [packageDetail, setPackageDetail] = useState([]);
    const [statusPackage, setStatusPackage] = useState(0);
    const [listUser, setListUser] = useState();
    const [customer, setCustomer] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [listItemTemp, setListItemTemp] = useState([]);


    useEffect(() => {
        getDataDetail();
        API.getListUserActive().then(rs => {
            console.log(rs.result)
            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                setListUser(rs.result)
            }
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const handleOk = () => {
        setIsModalVisible(false);
        console.log(listItemTemp)

        let newItem = [...listItem];
        for (let i = 0; i < listItemTemp.length; i++) {
            newItem.push(listItemTemp[i])
        }
        console.log(newItem)
        setListItem(newItem)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setListItemTemp([])
    };


    const onChangeCus = (value) => {
        if (packageDetail.owner !== value) {
            setCustomer(value)
        }
    }


    const dataSource = listItem.map(o => {
        return {
            key: o.id,
            id: o.id,
            codeItem: o.codeItem,
            nameItem: o.nameItem,
            quantity: o.quantity,
        }
    })

    const dataSourceTracking = listTracking.map(o => {
        console.log(o)
        return {
            key: o.id,
            id: o.id,
            codeTracking: o.codeTracking,

        }
    })


    const columnsTracking = [
        {
            title: <div style={{textAlign: "center"}}>Mã Tracking</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Code Tracking</div>,
            key: "id",
            render(o) {
                return <CopyToClipboard text={o.codeTracking}>
                    <Button onClick={() => {
                        message.success("Đã copy code tracking")
                    }}>
                        {o.codeTracking}
                    </Button>
                </CopyToClipboard>;
            },
        },


        {
            title: <div style={{textAlign: "center"}}></div>,
            key: "tracking-remove",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    {/*   <Button onClick={() => {
                       removeTracking(o)
                    }}>

                        Xoá
                    </Button>*/}
                    <Button size="large">
                        <a rel="noopener noreferrer" href={"/tracking/" + o.id} target="_blank">Chi tiết</a>
                    </Button>
                </div>;
            },
        },

    ];


    const columns = [
        {
            title: <div style={{textAlign: "center"}}>Mã Item</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Code</div>,
            key: "codeItem",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.codeItem}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Tên</div>,
            key: "nameItem",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.nameItem}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Số lượng</div>,
            key: "quantity",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.quantity}</div>;
            },
        },

        {
            title: <div style={{textAlign: "center"}}></div>,
            key: "id-remove",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    {/* <Button onClick={() => {
                        removeItem(o.id)
                    }}>
                        Xoá
                    </Button>*/}
                </div>;
            },
        },

    ];

    const handleChangeStatus = (value) => {
        setStatusPackage(value)
    }


    const getStatusFromConstant = (status) => {
        let mapStatus = CONSTANT.STATUS_PACKAGE;
        return (
            <Select defaultValue={status} style={{width: 300}} onChange={handleChangeStatus}
                    disabled={packageDetail.airplanesId ? true : false}>
                <Option value={0}>{mapStatus[0]}</Option>

                <Option value={2}>{mapStatus[2]}</Option>
                <Option value={3}>{mapStatus[3]}</Option>


                <Option value={8}>{mapStatus[8]}</Option>
                <Option value={9}>{mapStatus[9]}</Option>
                <Option value={10}>{mapStatus[10]}</Option>
                <Option value={11}>{mapStatus[11]}</Option>
                <Option value={12}>{mapStatus[12]}</Option>
                <Option value={13}>{mapStatus[13]}</Option>
                <Option value={14}>{mapStatus[14]}</Option>
                <Option value={15}>{mapStatus[15]}</Option>
            </Select>
        )


    }

    const onFinish = (values) => {

        values.id = packageDetail.id


        values.status = statusPackage


        values.listItemId = listItem.map(x => {
            return x.id
        })
        values.listTrackingId = listTracking.map(t => {
            return t.id
        })


        if (customer === null) {
            values.packageOwner = packageDetail.owner.id
        } else {
            values.packageOwner = customer
        }
        console.log(values)

        API.updatePackageDetail(values)
            .then(rs => {
                    console.log(rs)
                    message.success(rs.result)

                }
            ).catch((er) => {
            console.log(er)
            message.error("Có lỗi xảy ra vui lòng thử lại")
        })


    }

    const getDataDetail = () => {

        const idGet = props.match.params.id
        API.getPackgeById(idGet).then(result => {
            let packageDetail = result.result
            console.log(packageDetail)
            setListItem(packageDetail.listItem)
            setPackageDetail(packageDetail)
            setStatusPackage(packageDetail.status)

            setListTracking(packageDetail.listTracking)
            if (packageDetail.dateOfFly !== null) {
                packageDetail.dateOfFly = moment(packageDetail.dateOfFly)
            }


            setFields([
                {
                    name: ["id"],
                    value: packageDetail.id,
                },
                {
                    name: ["codePackage"],
                    value: packageDetail.codePackage,
                },
                {
                    name: ["name"],
                    value: packageDetail.name,
                },
                {
                    name: ["note"],
                    value: packageDetail.note,
                },
                {
                    name: ["amount"],
                    value: packageDetail.amount,
                },
                {
                    name: ["note"],
                    value: packageDetail.note,
                },
                {
                    name: ["dateCreate"],
                    value: packageDetail.dateCreate,
                },
                {
                    name: ["dateUpdate"],
                    value: packageDetail.dateUpdate,
                },
                {
                    name: ["dateOfFly"],
                    value: packageDetail.dateOfFly,
                },

            ])


        })
    }
    const getItem = () => {
        return listItemTemp.map(x => {
            return <p key={x.id}> Code: {x.id} | Tên: {x.nameItem} | Giá: {x.price} | Số lượng: {x.quantity}</p>
        })

    }
    /*
        const expandedRender = record => {
            console.log(record)
            API.getTrackingById(record.id).then(rs => {
                console.log(rs.result.ownerTracking)
                setCustomTrackingDetail(rs.result.ownerTracking)
               /!*
                console.log(rs.result)
                let tracking = rs.result
                if ((tracking.ownerTracking !== undefined) && (tracking.ownerTracking !== null)) {
                    return <div>
                        <Popover
                            content={<Link to={"user/detail/" + tracking.ownerTracking.id}>Xem chi
                                tiết | Email: {tracking.ownerTracking.email}</Link>}
                            title={"Tên: " + tracking.ownerTracking.name} trigger="click">
                            <Button>{tracking.ownerTracking.nickName}</Button>
                        </Popover>
                    </div>
                } else {
                    return <div>
                        <h3> Đơn này chưa thuộc về khác hàng nào cả</h3>
                    </div>
                }*!/
            })
            return <div>
                <p style={{fontSize: 18, margin: 0,}}>Khách hàng</p>
                {(customTrackingDetail.ownerTracking !== undefined) && (customTrackingDetail.ownerTracking !== null) ?
                    <div>
                        <Popover content={<Link to={"user/detail/" + customTrackingDetail.ownerTracking.id}>Xem chi tiết | Email: {customTrackingDetail.ownerTracking.email}</Link>} title={"Tên: " + customTrackingDetail.ownerTracking.name} trigger="click">
                            <Button>{customTrackingDetail.ownerTracking.nickName}</Button>
                        </Popover>
                    </div>

                    : <div>
                        <h3> Đơn này chưa thuộc về khác hàng nào cả</h3>
                    </div>
                }
            </div>
        }*/


    return (
        <div style={{padding: "10px"}}>


            <Modal title="Đã tìm thấy đơn hàng" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Bạn có muốn thêm tất cả vào package</p>
                {getItem()}
            </Modal>

            <div style={{padding: "20px", background: "white", margin: "10px 0"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Chi tiết Package</h2>


            </div>
            <Row style={{}}>
                <Col span={12} offset={0} style={{background: "white", padding: "10px", marginLeft: "15px"}}>
                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off"
                          layout="vertical"
                          fields={fields}
                          disbled="true"
                    >
                        <Row style={{padding: "10px 0"}}>
                            <Col span={10} offset={0}>
                                <div style={{fontSize: 18,}}>Mã Package</div>
                                <div
                                    style={{fontSize: 18, fontWeight: 800, padding: "10px 0"}}>#{packageDetail.id}</div>
                            </Col>
                            <Col span={10} offset={2}>

                                <div style={{fontSize: 18,}}>Trạng thái</div>
                                <div style={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    padding: "10px 0"
                                }}>
                                    {packageDetail.airplanesId ?
                                        <div>

                                            <Button size="large">
                                                <a rel="noopener noreferrer"
                                                   href={"/airplane/" + packageDetail.airplanesId}
                                                   target="_blank">{"Đã có chuyến bay " + packageDetail.airplanesId}</a>
                                            </Button>

                                        </div>
                                        : ""}

                                    {packageDetail.status ? getStatusFromConstant(packageDetail.status) : ""}
                                </div>
                            </Col>
                        </Row>


                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Code Package</p>}
                            name="name"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Ghi chú Package</p>}
                            name="note"
                        >
                            <TextArea/>
                        </Form.Item>

                        <p style={{fontSize: 18, margin: 0,}}>Khách hàng</p>
                        {(packageDetail.owner !== undefined) && (packageDetail.owner !== null) ?
                            <div>
                                <Customer data={packageDetail.owner}/>
                            </div>

                            : <div>
                                <h3> Đơn này chưa thuộc về khác hàng nào cả</h3>
                            </div>
                        }
                        <div>
                            <h3 style={{margin: "10px 0"}}>Chỉ chọn khi thay đổi khách hàng</h3>
                            <Select
                                showSearch
                                style={{width: 500}}
                                placeholder="Chọn khách hàng"
                                optionFilterProp="children"
                                onChange={onChangeCus}

                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                // onSearch={onSearch}

                            >

                                <Option key={null} value={null}>Không chọn</Option>
                                {listUser &&
                                listUser.map((cus) => (
                                    <Option key={cus.id} value={cus.id}>
                                        {cus.id} - {cus.name} - {cus.nickName}
                                    </Option>
                                ))}
                            </Select>

                        </div>

                        {/*<Form.Item*/}
                        {/*    label={<p style={{fontSize: 18, margin: 0,}}>Ngày bay dự kiến</p>}*/}
                        {/*    name="dateOfFly"*/}
                        {/*>*/}
                        {/*    <DatePicker format={dateFormat}/>*/}
                        {/*</Form.Item>*/}
                        <Row style={{paddingTop: "20px"}}>


                            <Col offset={3}>
                                <h3 style={{fontSize: "19px"}}>Ngày bay dự kiến</h3>
                                {packageDetail.dateOfFly === null ? "" :
                                    <div>

                                        <h3 style={{fontSize: "16px", fontWeight: 400}}> {packageDetail.dateUpdate}</h3>
                                    </div>}
                            </Col>
                            <Col offset={3}>
                                <div>
                                    <h3 style={{fontSize: "19px"}}>Ngày tạo đơn</h3>
                                    <h3 style={{fontSize: "16px", fontWeight: 400}}> {packageDetail.dateCreate}</h3>
                                </div>
                            </Col>

                            <Col offset={3}>

                                {packageDetail.dateUpdate === null ? "" :
                                    <div>
                                        <h3 style={{fontSize: "19px"}}>Ngày cập nhật</h3>
                                        <h3 style={{fontSize: "16px", fontWeight: 400}}> {packageDetail.dateUpdate}</h3>
                                    </div>}
                            </Col>
                        </Row>


                        <Form.Item style={{marginTop: "20px"}}>


                            <Button
                                name={"update"}
                                type="primary" htmlType="submit" size={'large'}>
                                Cập nhật package
                            </Button>

                        </Form.Item>

                    </Form>

                </Col>
                <Col span={11} offset={0} style={{background: "white", padding: "10px", marginLeft: "20px"}}>

                    {/* <h3 style={{fontSize: "24px"}}>Thêm Tracking</h3>
                    <Form
                        onFinish={onSearchTracking}
                        form={formTracking}
                    >
                        <Form.Item
                            label="Thêm Tracking"
                            name="listCode"
                        >
                            <TextArea/>
                        </Form.Item>
                        <Button type="primary" htmlType="submit">
                            Tìm kiếm
                        </Button>
                    </Form>*/}
                    {/*<Search*/}
                    {/*    placeholder="Nhập mã Tracking"*/}
                    {/*    allowClear*/}
                    {/*    enterButton="Search"*/}
                    {/*    size="large"*/}
                    {/*    onSearch={onSearchTracking}*/}
                    {/*    style={{marginBottom: "20px"}}*/}
                    {/*    value={searchValueTracking}*/}
                    {/*    onChange={onChangeTracking}*/}

                    {/*/>*/}


                    <h3 style={{fontSize: "24px"}}>Danh sách tracking</h3>
                    <Table
                        /*    expandedRowRender= {expandedRender}*/

                        pagination={false}
                        size="small"
                        bordered
                        dataSource={dataSourceTracking}
                        columns={columnsTracking}
                        summary={pageData => {
                            let totalTracking = 0;
                            pageData.forEach(() => {
                                totalTracking ++;
                            });

                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell>Tổng số</Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={3}>
                                            <Text style={{fontSize:"25px",fontWeight:"600"}}>{totalTracking}</Text>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>

                                </>
                            );
                        }}

                    />


                    {/*    <h3 style={{fontSize: "24px"}}>Thêm từng sản phẩm</h3>
                    <Search
                        placeholder="Nhập mã item"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={onSearch}
                        style={{marginBottom: "20px"}}
                        value={searchValue}
                        onChange={onChange}
                    />
                    */}
                    <h3 style={{fontSize: "24px"}}>Danh sách sản phẩm</h3>

                    <Table

                        size="small" bordered dataSource={dataSource} columns={columns}

                    />

                </Col>
            </Row>


        </div>

    )


}

export default PackageDetailUser;