import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, message, notification, Row, Select} from "antd";
import * as API from "../code/Api";
import CONSTANT from "../config/constant.json";


const {Option} = Select;
const {TextArea} = Input;

export const CreatePackage = (props) => {

    const [listUser, setListUser] = useState();
    const [listAirplane, setListAirplane] = useState([]);

    useEffect(() => {
        API.getListUserActive().then(rs => {
            console.log(rs.result)
            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                setListUser(rs.result)
            }
        })
        API.getAllAirplane().then((res) => {
            setListAirplane(res.result);
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg,
        });
    };

    const onFinish = (values) => {
        if (values.name === undefined) {
            message.error("Vui lòng điền tên")
            return
        }
        if (values.packageOwner === undefined) {
            values.packageOwner = null
        }
        values.kg = 0


        API.createPackagge(values).then(result => {

            openNotificationWithIcon(result.message, "Tạo package thành công")
            props.history.push("/package/" + result.result);
            // if (role === CONSTANT.ROLE.ADMIN || role === CONSTANT.ROLE.ADMIN_VN || role === CONSTANT.ROLE.ADMIN_US) {
            //     props.history.push("/tracking-admin");
            // } else if (role === CONSTANT.ROLE.USER) {
            //     props.history.push("/tracking-user-all")
            // }

        })
    };


    return (
        <div>
            <div style={{padding: "20px"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Tạo Package mới</h2>
                <h2>Vui lòng điền các thông tin bên dưới</h2>
            </div>
            <Row style={{padding: "20px", height: "100%",}}>
                <Col span={20} offset={2} style={{}}>
                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off"
                          layout="vertical">
                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Tên package</p>}
                            name="name"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Ghi chú package</p>}
                            name="note"
                        >
                            <TextArea/>
                        </Form.Item>

                        <Form.Item name="packageOwner" label="Khách hàng">
                            <Select
                                showSearch
                                style={{width: 500}}
                                placeholder="Chọn khách hàng"
                                optionFilterProp="children"

                            >


                                {listUser &&
                                listUser.map((cus) => (

                                    <Option key={cus.id} value={cus.id}>
                                        {cus.id} - {cus.name} - {cus.nickName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item name="airplanes" label="Chuyến bay">
                            <Select
                                showSearch
                                style={{width: 500}}
                                placeholder="Chọn chuyến bay"
                                optionFilterProp="children"

                            >
                                {listAirplane &&
                                listAirplane.map((cus) => (

                                    <Option key={cus.id} value={cus.id}>
                                        {cus.id} - {cus.codeAirplanes} - {cus.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>


                        <Form.Item style={{marginTop: "20px"}}>
                            <Button type="primary" htmlType="submit">
                                Tạo package mới
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>

            </Row>
        </div>
    );
};

export default CreatePackage;