import React, {useEffect, useState} from "react";
import {Button, Table} from "antd";
import * as API from "../code/Api";


const ListInvoiceAdminScreen = () => {
    const [listInvoice, setListInvoice] = useState([]);


    useEffect(() => {
        loadData(); // eslint-disable-next-line
    }, []);

    const loadData = () => {
        API.searchInvoiceAdmin({
            "codeInvoice": null,
            "userId": null
        }).then((rs) => {

            const list = rs.result;
            console.log(list)
            setListInvoice(list);

        });
    };

    const dataSource = listInvoice.map(o => {
        return {
            key: o.id,
            id: o.id,
            codeInvoice: o.codeInvoice,
            userName: o.userName,
            total: o.total,
            amountToBePaid: o.amountToBePaid,
            createDate: o.createDate,
            status: o.status,
        }
    })


    const columns = [
        {
            title: <div style={{textAlign: "center"}}>Id </div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Code </div>,
            key: "codeInvoice",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.codeInvoice}</div>;
            },


        },
        {
            title: <div style={{textAlign: "center"}}>NickName </div>,
            key: "userName",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.userName}</div>;
            },


        },
        {
            title: <div style={{textAlign: "center"}}>Tổng tiền</div>,
            key: "total",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    {o.total.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})}
                </div>;
            },


        },
        {
            title: <div style={{textAlign: "center"}}>Tiền đã trả</div>,
            key: "amountToBePaid",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    {o.amountToBePaid.toLocaleString('it-IT', {style: 'currency', currency: 'VND'})}
                </div>;
            }
        },

        {
            title: <div style={{textAlign: "center"}}>Ngày tạo</div>,
            key: "createDate",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    {o.createDate}
                </div>;
            },


        },
        {
            title: <div style={{textAlign: "center"}}>Ngày tạo</div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    {o.status}
                </div>;
            },


        },

        {
            title: "",
            key: "action",
            render(o) {
                return (
                    <span>
                      <Button
                          onClick={() => {
                          }}
                          type="primary" size="large">
                        <span className="nav-text">Chi tiết</span>
                      </Button>

          </span>
                );
            },
        },

    ]

    return (
        <div style={{padding: "10px"}}>
            <div style={{padding: "20px"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Danh sách Invoice</h2>
            </div>
            <div style={{padding: "10px"}}>

                <Table

                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}
                    expandable={{
                        expandedRowRender: o => {
                            return <div>

                            </div>;
                        }


                    }}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "70"],
                    }}
                />
            </div>
        </div>
    )
}

export default ListInvoiceAdminScreen;
