import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, Form, Input, message, Modal, Row, Switch, Table} from "antd";
import CONSTANT from "../config/constant.json";
import TextArea from "antd/es/input/TextArea";

// import ItemList from "../components/ItemList"


const AccountAdminList = (props) => {

    const [listAccount, setListAccount] = useState([]);
    const [formUser] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addNew, setAddnew] = useState(false);
    const [type, setType] = useState();
    const [displayPass, setDisplayPass] = useState(false);


    useEffect(() => {
        loadData(); // eslint-disable-next-line
    }, [listAccount]);


    function onFinish(value) {

        if (addNew) {

            API.createUser(value).then(rs => {
                console.log(rs)
                if (rs.message === CONSTANT.STATUS.SUCCESS) {
                    message.success(rs.result)
                    loadData()
                    setIsModalVisible(false)

                } else {
                    message.error(rs.reuslt)
                }
            })
        } else {
            console.log(value)
            API.updateUser(value).then(rs => {
                console.log(rs)
                if (rs.message === CONSTANT.STATUS.SUCCESS) {
                    message.success(rs.result)
                    loadData()
                    setIsModalVisible(false)
                }
            })
        }


    }


    const loadData = () => {
        API.getListUser().then((rs) => {
            const type = props.type
            setType(type)
            const list = rs.result;
            let result = list.filter(t => t.status !== 0);
            if (type === "delete") {
                console.log("delete")
                result = list.filter(t => t.status === 0);
            }


            setListAccount(result);

        });
    };

    const dataSource = listAccount.map(o => {
        return {
            key: o.id,
            id: o.id,
            name: o.name,
            email: o.email,
            address: o.address,
            numberPhone: o.numberPhone,
            nickName: o.nickName,
            facebook: o.facebook,
            note: o.note,
            status: o.status,
            consigneeName: o.consigneeName,


        }
    })


    const columns = [
        {
            title: <div style={{textAlign: "center", maxWidth: "80px"}}>Id</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: <div style={{textAlign: "center"}}>Tên </div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.name}</div>;
            },


        },
        {
            title: <div style={{textAlign: "center"}}>NickName </div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.nickName}</div>;
            },


        },
        {
            title: <div style={{textAlign: "center"}}>Số điện thoại </div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.numberPhone}</div>;
            },


        },

        {
            title: "",
            key: "action",
            render(o) {

                return (
                    <span>

              <Button
                  onClick={() => {

                      formUser.setFieldsValue(o)
                      setIsModalVisible(true)
                      setAddnew(false)
                  }}
                  type="primary" size="large">
                <span className="nav-text">Chỉnh sửa</span>
              </Button>

          </span>
                );
            },
        },

    ]

    function onChangeChangePass(checked) {
        console.log(`switch to ${checked}`);
        setDisplayPass(checked)
    }

    return (<div style={{}}>
        <Row style={{}}>
            <div>

                <Button
                    onClick={() => {
                        setAddnew(true)
                        formUser.resetFields()
                        setIsModalVisible(true)
                    }}
                    type="primary" style={{marginLeft: "35px", marginBottom: '20px'}}>


                    Thêm tài khoản
                </Button>
                <Modal
                    title="Thay đổi thông tin user"
                    centered
                    visible={isModalVisible}
                    // visible={true}

                    footer={[]}
                >

                    <Form
                        form={formUser}
                        name="basic"
                        onFinish={onFinish}

                    >

                        <Form.Item
                            label="Code User"
                            name="id"

                        >
                            <Input disabled/>
                        </Form.Item>

                        <Form.Item
                            style={{display: "none"}}
                            label="status"
                            name="status"

                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label="Tên khách hàng"
                            name="name"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Nickname"
                            name="nickName"
                        >
                            <Input/>
                        </Form.Item>


                        <Form.Item
                            label="Email"
                            name="email"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Tên người nhận hàng"
                            name="consigneeName"
                        >
                            <Input/>
                        </Form.Item>



                        <Form.Item
                            label="Địa chỉ nhận hàng"
                            name="address"
                        >
                            <Input/>
                        </Form.Item>


                        <Form.Item
                            label="Số điện thoại"
                            name="numberPhone"
                        >
                            <Input/>
                        </Form.Item>


                        <Form.Item
                            label="Facebook"
                            name="facebook"
                        >
                            <Input/>
                        </Form.Item>


                        <Form.Item
                            label="Ghi chú"
                            name="note"
                        >
                            <TextArea rows={4}/>
                        </Form.Item>



                        <p>Thay đổi mật khẩu</p>
                        <Switch
                            style={{marginBottom: "20px"}}
                            onChange={onChangeChangePass}/>


                        {displayPass ? <Form.Item

                            label="Mật khẩu"
                            name="password"
                        >
                            <Input/>
                        </Form.Item> : ""}

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {addNew ? "Thêm mới" : "Cập nhật"}
                            </Button>
                            {type === "all" ?
                                <Button
                                    onClick={() => {
                                        let value = formUser.getFieldsValue()
                                        value.status = 0

                                        onFinish(value)

                                    }}
                                    style={{marginLeft: "20px"}}
                                    danger> Xoá tài khoản </Button>

                                : ""}
                            {type === "delete" ? <Button
                                onClick={() => {
                                    let value = formUser.getFieldsValue()
                                    value.status = 1

                                    onFinish(value)

                                }}
                                style={{marginLeft: "20px"}}
                            > Khôi phục tài khoản </Button> : ""}
                            <Button
                                style={{marginLeft: "20px"}}
                                onClick={() => {
                                    formUser.resetFields();
                                    setIsModalVisible(false)
                                }}>
                                Huỷ
                            </Button>
                        </Form.Item>

                    </Form>

                </Modal>
            </div>
            <Col span={22} offset={1} style={{}}>
                <Table
                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}
                    expandable={{
                        expandedRowRender: o => {
                            return <div>
                                {o.address === null ? "" : <p style={{fontSize: "17px", fontWeight: 500}}><span
                                    style={{fontSize: "18px", fontWeight: 500}}>Địa chỉ: </span>{o.address}</p>}
                                {o.facebook === null ? "" : <p style={{fontSize: "17px", fontWeight: 500}}><span
                                    style={{fontSize: "18px", fontWeight: 500}}>Facebook: </span><a target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                    href={o.facebook}>{o.facebook}</a>
                                </p>}
                                {o.email === null ? "" : <p style={{fontSize: "17px", fontWeight: 500}}><span
                                    style={{fontSize: "18px", fontWeight: 500}}>Email: </span>{o.email}
                                </p>}
                            </div>;
                        }


                    }}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "70"],
                    }}
                />
            </Col>

        </Row>
    </div>)
}

export default AccountAdminList;

