import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_API || "https://api.aws.dp-cargo.com/";
//axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_API || "https://apiv1.dp-cargo.com/";
//axios.defaults.baseURL = "http://localhost:8080/";

const getToken = () => {
    let token = localStorage.getItem('token');

    return token;
}

const config = {
    headers: {
        "Content-Type": "application/json",
        Authorization:
            "Bearer " + getToken()
    },
};

const configSimple = {
    headers: {
        "Content-Type": "application/json",
    },
};


export const login = (login) => {
    return axios
        .post("/account/login", login)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const createTrackingUser = (data) => {
    return axios
        .post("/tracking/create", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getTracking = () => {
    return axios
        .get("/tracking", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getTrackingById = (id) => {
    return axios
        .get("/tracking/" + id, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const updateTrackingDetail = (data) => {
    return axios
        .post("/tracking/user/update" , data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const updateTrackingDetailAdminNew = (data) => {
    return axios
        .post("/tracking/admin/update", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const updateTrackingDetailAdmin = (data) => {
    return axios
        .post("/tracking/update/" + data.id, data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const cancelTrackingUserById = (id) => {
    return axios
        .get("/tracking/cancel-tracking/" + id, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const deleteTrackingAdmin = (id) => {
    return axios
        .get("/tracking/delete/" + id, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getListUser = () => {
    return axios
        .get("/user", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getConfig = () => {
    return axios
        .get("/admin-config/1", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const updateConfig = (data) => {
    return axios
        .post("/admin-config/update", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getListUserActive = () => {
    return axios
        .get("/user/active", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getAllPackage = () => {
    return axios
        .get("/package", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getAllPackageAirplane = () => {
    return axios
        .get("/package/not-add-airplanes", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};



export const getAllPackageUser = () => {
    return axios
        .get("/package/user", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getAllAirplane = () => {
    return axios
        .get("/airplanes", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getAirplaneById = (id) => {
    return axios
        .get("/airplanes/" + id, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const createAirPlane = (data) => {
    return axios
        .post("/airplanes/create", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const searchTrackingAdmin = (data) => {
    return axios
        .post("/tracking/admin/search", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const searchPackageAdmin = (data) => {
    return axios
        .post("/package/admin/search", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const searchPackageUser = (data) => {
    return axios
        .post("/package/user/search", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const searchTrackingUser = (data) => {
    return axios
        .post("/tracking/user/search", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};



export const getAllAirplaneUser = () => {
    return axios
        .get("/airplanes/user", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const searchTracking = (data) => {
    return axios
        .post("/tracking/scan", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const createPackagge = (data) => {
    return axios
        .post("/package/create", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getPackgeById = (id) => {
    return axios
        .get("/package/" + id, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const updatePackageDetail = (data) => {
    return axios
        .post("/package/update", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const updateStatusTracking = (id, status) => {
    return axios
        .get("/tracking/update-status/" + id + "?newStatus=" + status, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const updateOwnerTracking = (data) => {
    return axios
        .post("/tracking/assign-user", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const scanAll = (data) => {
    return axios
        .post("/scan", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const updateAirplaneDetail = (data) => {
    return axios
        .post("/airplanes/update", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const registerAdmin = (account) => {
    return axios
        .post("/users/register-admin", account, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const register = (account) => {
    return axios
        .post("/user/register", account, configSimple)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getAllOrder = () => {
    return axios
        .get("/order/all", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getAllCustomer = () => {
    return axios
        .get("/users/get-customer", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getAllStaff = () => {
    return axios
        .get("/users/get-staff", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const addOrder = (order) => {
    return axios
        .post("/order/new", order, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const updateOrder = (order) => {
    return axios
        .post("/order/update", order, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const updateUser = (user) => {
    return axios
        .post("/user/update", user, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const createUser = (user) => {
    return axios
        .post("/user/register", user, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const deleteOrder = (url) => {
    return axios
        .get(url, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getUserById = (url) => {
    return axios
        .get(url, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getAllUser = () => {
    return axios
        .get("/users/listAll", config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const checkToken = () => {
    return axios
        .get("/account/verify", config)
        .then((response) => response.data)
        .catch((error) => error);
};


export const getOrderByStatus = (order) => {
    return axios
        .post("/order/get-by-status", order, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const searchByStaffCus = (search) => {
    return axios
        .post("/order/searchByStaffCus", search, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


//invoice


export const searchInvoiceAdmin = (data) => {
    return axios
        .post("/invoice/search", data, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getHistory = () => {
    return axios
        .get("/system/history", config)
        .then((response) => response.data)
        .catch((error) => error);
};


export const reportByStatus = (search) => {
    return axios
        .post("/order/report", search, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const reportFull = () => {
    return axios
        .get("/order/report-full", config)
        .then((response) => response.data)
        .catch((error) => error);
};

export const reportFullId = (search) => {
    return axios
        .post("/order/report-full-id", search, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const reportFullIdList = (status) => {
    return axios
        .post("/order/report-full-list", status, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getLastUpdate = () => {
    return axios
        .get("/order/last-update", config)
        .then((response) => response.data)
        .catch((error) => error);
};


export const getAllRevenue = () => {
    return axios
        .get("/revenue/get-all", config)
        .then((response) => response.data)
        .catch((error) => error);
};


export const getCatRevenue = () => {
    return axios
        .get("/revenue/get-category", config)
        .then((response) => response.data)
        .catch((error) => error);
};


export const addCatRevenue = (revenue) => {
    return axios
        .post("/revenue/add-category", revenue, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const editCatRevenue = (revenue) => {
    return axios
        .post("/revenue/update-category", revenue, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const deleteCatRevenue = (url) => {
    return axios
        .get(url, config)
        .then((response) => response.data)
        .catch((error) => error);
};


export const addRevenue = (revenue) => {
    return axios
        .post("/revenue/add", revenue, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const editRevenue = (revenue) => {
    return axios
        .post("/revenue/edit", revenue, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const deleteRevenue = (url) => {
    return axios
        .get(url, config)
        .then((response) => response.data)
        .catch((error) => error);
};


export const getOrderByCus = () => {
    return axios
        .get("/order/get-by-user", config)
        .then((response) => response.data)
        .catch((error) => error);
};


export const getOrderByCustomerAndStatus = (order) => {
    return axios
        .post("/order/get-by-user-status", order, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getInfo = (list) => {
    return axios
        .post("/system/get-info-product", list, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};

export const getStatus = (list) => {
    return axios
        .post("/system/update-list-status", list, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};


export const getInfoProductUser = () => {
    return axios
        .get("/system/get-info-product-user", config)
        .then((response) => response.data)
        .catch((error) => error);
};

export const getListProductUser = () => {
    return axios
        .get("/system/get-list-product-user", config)
        .then((response) => response.data)
        .catch((error) => error);
};


export const editListProductInfoUser = (list) => {
    return axios
        .post("/system/edit-list-product", list, config)
        .then((response) => response.data)
        .catch((error) => error.message);
};
