import {
    Button,
    Col,
    Descriptions,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Steps,
    Table,
    Tabs
} from "antd";
import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import CONSTANT from "../config/constant.json";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

const {Step} = Steps;
const {TabPane} = Tabs;
const {Option} = Select;
const CreateInvoiceAdminScreen = () => {

    const [listUser, setListUser] = useState();
    const [current, setCurrent] = useState(0);
    const [state, setState] = useState()
    const [invoiceUser, setInvoiceUser] = useState()
    const [formItem] = Form.useForm();
    const [dataBanks, setDataBanks] = useState();
    const isEditing = (record) => record.key === editingKey;
    const [visibleModal, setVisibleModal] = useState(false);
    const [editingKey, setEditingKey] = useState('');

    useEffect(() => {
        API.getListUserActive().then(rs => {
            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                setListUser(rs.result)
            }
        })
        setState()
    }, []);


    function getUserfromId(id) {
        let a = null;
        // eslint-disable-next-line
        listUser.map(e => {
            console.log(e)
            if (id === e.id) {
                a = {id: e.id, sender: e.sender, phoneSender: e.numberPhone, receiver: e.address};
            }
        })

        return a;
    }


    //edit cell

    const addItem = value => {

        if (false) {
            message.error('Vui lòng điền dầy đủ thông tin');
        } else {
            try {
                const newData = [...dataBanks];

                if (newData.length === 0) {
                    value.key = 0
                } else {
                    value.key = newData[newData.length - 1].key + 1
                }
                newData.push(value)
                setDataBanks(newData);
                setVisibleModal(false)
                message.success('Thêm thành công');


            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
            //setVisibleModal(false)
        }


    }

    const EditableCell = ({
                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {
        const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };


    const cancel = () => {
        setEditingKey('');
    };

    const edit = (record) => {
        formItem.setFieldsValue({

            ...record,
        });
        setEditingKey(record.key);
    };


    const save = async (key) => {
        try {
            const row = await formItem.validateFields();
            const newData = [...dataBanks];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setDataBanks(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setDataBanks(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    const deleteItem = async (record) => {
        try {
            const newData = [...dataBanks];
            //  const index = newData.findIndex((item) => record.key === item.key);

            for (let i = 0; i < newData.length; i++) {
                if (newData[i].key === record.key) {


                    newData.splice(i, 1)

                    setDataBanks(newData);
                    return

                } else {

                }

            }


        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }

    const columns = [

        {
            title: <div style={{textAlign: ""}}>Tên</div>,
            editable: true,
            render(o) {
                console.log(o)
                return <div style={{textAlign: "center"}}></div>;
            },
        },
        {
            title: <div style={{textAlign: ""}}>Tên ngân hàng</div>,
            editable: true,
            dataIndex: 'surcharge',
        },
        {
            title: 'Tên viết tắt',
            dataIndex: 'bankShortName',
            editable: true,
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accountNumber',
            editable: true,
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Button

                type="primary"
                onClick={() => save(record.key)}
                style={{
                    marginRight: 8,
                }}
            >
              Lưu lại
            </Button>

            <Button
                onClick={cancel}
                style={{marginTop: "10px"}}>
                            Huỷ
            </Button>

          </span>
                ) : (
                    <div>
                        <div>
                            <Button

                                type="primary" icon={<EditOutlined/>}

                                disabled={editingKey !== ''} onClick={() => edit(record)}>

                            </Button>
                            <Button

                                icon={<DeleteOutlined/>}
                                style={{marginTop: "10px", marginLeft: "10px"}}
                                disabled={editingKey !== ''} onClick={() => deleteItem(record)}>

                            </Button>
                        </div>
                    </div>
                );
            },
        },
    ];



    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    //edit cell
    function selectUser() {
        return (

            <Row>
                <Col>
                    <h3 style={{fontSize: "25px", marginTop: "20px"}}>Chọn khách hàng</h3>

                    <Select
                        onSelect={data => {

                            //let dataOld = [...state]
                            // state.invoiceUser = getUserfromId(data)
                            // setState(prevState => ({
                            //     ...prevState,
                            //     "invoiceUser": getUserfromId(data)
                            // }));
                            setInvoiceUser(getUserfromId(data))
                        }}
                        showSearch
                        placeholder="Chọn khách hàng"
                        optionFilterProp="children"
                        style={{minWidth: "400px", maxWidth: "400px"}}
                    >
                        {listUser &&
                        listUser.map((cus) => (
                            <Option key={cus.id} value={cus.id}>
                                {cus.id} - {cus.name} - {cus.nickName}
                            </Option>
                        ))}
                    </Select>

                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={() => {
                            setCurrent(1)

                        }}
                        style={{marginTop: "70px", marginLeft: "50px"}}
                    >Tiếp tục</Button>
                </Col>
            </Row>

        )
    }

    return (
        <div style={{padding: "10px"}}>
            <div style={{padding: "20px"}}>
                <h2 style={{fontWeight: 600, fontSize: "30px"}}>Tạo Invoice</h2>
            </div>
            <div style={{padding: "20px"}}>

                <Steps
                    type="navigation"
                    style={{marginBottom: "20px"}}
                    current={current}
                    onChange={(data) => {
                        setCurrent(data)
                    }}
                >
                    <Step title="Chọn user"/>
                    <Step title="Thông tin"/>
                    <Step title="Hoàn tất"/>

                </Steps>

                {current === 0 ? (
                    <>
                        {selectUser()}
                    </>
                ) : current === 1 ? (
                    <div style={{background: "white", padding: "15px"}}>
                        {console.log(state, invoiceUser)}
                        <Descriptions title="Thông tin user" bordered>
                            <Descriptions.Item label="Họ tên người gửi" span={3}>
                                <Input value={"Người gửi"}/>
                            </Descriptions.Item>
                            <Descriptions.Item label="Người gửi" span={3}>
                                <Input value={"Người gửi"}/>
                            </Descriptions.Item>
                            <Descriptions.Item label="Số điên thoại" span={3}>
                                <Input value={invoiceUser.phoneSender}/>
                            </Descriptions.Item>
                            <Descriptions.Item label="Người nhận" span={3}>
                                <Input value={invoiceUser.receiver}/>
                            </Descriptions.Item>


                        </Descriptions>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Hàng hóa và phụ thu" key="1">

                                <Modal
                                    title="Thêm thông tin ngân hàng"
                                    centered
                                    visible={visibleModal}

                                    footer={[]}
                                >

                                    <Form

                                        name="basic"
                                        onFinish={addItem}
                                        initialValues={{
                                            beneficiaryName: "",
                                            bankName: "",
                                            bankShortName: "",
                                            accountNumber: "",
                                            note: "",


                                        }}
                                    >

                                        <Form.Item
                                            label="Người thụ hưởng"
                                            name="beneficiaryName"
                                        >
                                            <Input/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Tên ngân hàng"
                                            name="bankName"
                                        >
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            label="	Tên viết tắt"
                                            name="bankShortName"
                                        >
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            label="	Số tài khoản"
                                            name="accountNumber"
                                        >
                                            <Input/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Ghi chú"
                                            name="note"
                                        >
                                            <Input/>
                                        </Form.Item>



                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Lưu
                                            </Button>
                                            <Button
                                                style={{marginLeft: "10px"}}
                                                onClick={() => setVisibleModal(false)}
                                            >
                                                Huỷ
                                            </Button>
                                        </Form.Item>
                                    </Form>

                                </Modal>


                                <div>
                                    <Button

                                        onClick={() => setVisibleModal(true)}
                                        style={{margin: "10px 0"}}>Thêm thông tin chuyển khoản</Button>
                                </div>



                                <Form form={formItem} component={false}>
                                    <Table

                                        size="small"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        bordered
                                        dataSource={dataBanks}
                                        columns={mergedColumns}
                                        rowClassName="editable-row"
                                        pagination={{
                                            onChange: cancel,
                                        }}
                                    />
                                </Form>
                            </TabPane>
                            <TabPane tab="Tính tiền" key="2">
                                Content of Tab Pane 2
                            </TabPane>
                            <TabPane tab="Balance due" key="3">
                                Content of Tab Pane 3
                            </TabPane>
                        </Tabs>

                    </div>
                ) : current === 2 ? (
                    <>

                    </>
                ) : (
                    <>
                    </>
                )}

            </div>
        </div>
    )
}

export default CreateInvoiceAdminScreen;
