import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Table,
    Typography
} from "antd";
import CONSTANT from "../config/constant.json";
import moment from "moment";
import Customer from "../components/Customer";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import useSound from 'use-sound';
import boopSfx from '../sound/point.mp3';

const {TextArea} = Input;
const {Option} = Select;
const {Search} = Input;
const {Text} = Typography;

const PackageDetailAdmin = (props) => {
    // eslint-disable-next-line
    const [formTracking] = Form.useForm();
    const [selected, setSelected] = useState()
    const [fields, setFields] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [listItem, setListItem] = useState([]);
    const [listItemRetail, setListItemRetail] = useState([]);
    const [listTracking, setListTracking] = useState([])
    const [packageDetail, setPackageDetail] = useState([]);
    const [statusPackage, setStatusPackage] = useState(0);
    const [listUser, setListUser] = useState();
    const [listTrackingInSystem, setListTrackingInSystem] = useState();
    const [customer, setCustomer] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [listItemTemp, setListItemTemp] = useState([]);
    const [kg, setKg] = useState(null);
    const [play] = useSound(boopSfx);


    useEffect(() => {
        getDataDetail();
        API.getListUserActive().then(rs => {

            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                setListUser(rs.result)
            }
        })
        API.getTracking().then(rs => {
                console.log(rs.result)
                if (rs.message === CONSTANT.STATUS.SUCCESS) {
                    setListTrackingInSystem(rs.result)
                }
            }
        )
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        console.log(listItemTemp)

        let newItem = [...listItem];
        for (let i = 0; i < listItemTemp.length; i++) {
            newItem.push(listItemTemp[i])
        }
        console.log(newItem)
        setListItem(newItem)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setListItemTemp([])
    };


    const onChangeCus = (value) => {
        if (packageDetail.owner !== value) {
            setCustomer(value)
        }
    }


    const onSearch = value => {
        let data = {code: [value]}
        console.log(data)
        setSearchValue("")
        API.scanAll(data).then(rs => {
            console.log(rs)
            if (rs.result.itemVOHashSet.length > 0) {
                setListItemTemp(rs.result.itemVOHashSet)
                showModal();
            } else {
                message.error("Mã Item không tồn tại")
            }
        })
    }
    // eslint-disable-next-line
    const onSearchTracking = async value => {

        // eslint-disable-next-line
        value.listCode = await value.listCode.split("\n").map(x => {
            if (x !== "") {
                return x
            }
        });

        let listOrder_ = value.listCode
        listOrder_ = await listOrder_.filter(function (element) {
            return element !== undefined;
        });


        let count = 0;

        let listTrackingDisplay = [...listTracking]

        for (const item of listOrder_) {

            /*if (item > 0 && item < 99999999)
            {

                // eslint-disable-next-line
                await  API.getTrackingById(item).then(rs => {
                    if (rs.message === CONSTANT.STATUS.SUCCESS){

                        let flag = true
                        for (const t of listTrackingDisplay) {
                            console.log(t)
                            if (rs.result.id === t.id){
                                flag = false
                            }
                        }
                        if(flag) {
                            listTrackingDisplay.push({id: rs.result.id, codeTracking: rs.result.codeTracking})
                        }
                    }

                })
            } else*/
            if (item.length > 10) {

                await API.searchTracking({listCode: [item]}).then(rs => {

                    if (rs.message === CONSTANT.STATUS.SUCCESS) {

                        let tracking = rs.result[0];
                        console.log("list", tracking)
                        let flag = true
                        for (const t of listTrackingDisplay) {
                            console.log("t nef", t)
                            if (tracking.id === t.id) {
                                flag = false
                                message.error("Đơn bị trùng")
                            }
                            tracking.ownerVO = t.ownerVO
                        }
                        if (flag) {
                            console.log("tracking get", tracking)
                            listTrackingDisplay.push(tracking)
                        }
                    } else {
                        message.error("Không tìm thấy tracking")
                    }

                })

            }


            console.log("chạy lần n " + count)
            count++;
        }


        setListTracking(listTrackingDisplay)


    }


    const onChange = value => {
        setSearchValue(value.target.value)
    }

    /*
        const onChangeTracking = value => {
            setSearchValueTracking(value.target.value)
        }
    */

    const dataSource = listItem.map(o => {
        return {
            key: o.id,
            id: o.id,
            codeItem: o.codeItem,
            nameItem: o.nameItem,
            quantity: o.quantity,
        }
    })

    const dataSourceItemRetail = listItemRetail.map(o => {

        return {
            key: o.id,
            id: o.id,
            name: o.name,
            quantity: o.quantity,
            note: o.note,
            owner: o.owner,
        }
    })

    const dataSourceTracking = listTracking.map(o => {
        console.log("owwner tracking,", o)

        return {
            key: o.id,
            id: o.id,
            codeTracking: o.codeTracking,
            dateAddToPackage: o.dateAddToPackage,
            ownerVO: o.ownerVO,


        }
    })


    const removeItem = async id => {

        try {
            const newData = [...listItem];
            //  const index = newData.findIndex((item) => record.key === item.key);
            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id === id) {
                    newData.splice(i, 1)
                    setListItem(newData);
                    return
                } else {

                }

            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }

    const removeTracking = async o => {
        console.log(o)
        try {
            const newData = [...listTracking];
            //  const index = newData.findIndex((item) => record.key === item.key);
            for (let i = 0; i < newData.length; i++) {
                console.log(newData[i])
                if (newData[i].id === o.id) {
                    newData.splice(i, 1)
                    setListTracking(newData);
                    return
                } else {

                }

            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }

    const removeItemRetail = async o => {

        try {
            const newData = [...listItemRetail];

            //  const index = newData.findIndex((item) => record.key === item.key);
            for (let i = 0; i < newData.length; i++) {

                if (newData[i].id === o.id) {

                    newData.splice(i, 1)
                    setListItemRetail(newData);
                    return
                } else {

                }

            }

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }


    const columnsTracking = [
        {
            title: <div style={{textAlign: "center"}}>Mã Tracking</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Code Tracking</div>,
            key: "id",
            render(o) {
                return <CopyToClipboard text={o.codeTracking}>
                    <Button onClick={() => {
                        message.success("Đã copy code tracking")
                    }}>
                        {o.codeTracking}
                    </Button>
                </CopyToClipboard>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Ngày thêm</div>,
            key: "dateAddToPackage",
            render(o) {
                console.log(o)
                return <div style={{textAlign: "center"}}>{o.dateAddToPackage}</div>;
            },
        },

        {
            title: <div style={{textAlign: "center"}}>Owner</div>,
            key: "owner",
            render(o) {
                console.log("owwner", o)
                if (o.ownerVO !== null) {
                    return (
                        <div>
                            <Customer data={o.ownerVO}/>
                        </div>

                    )
                }
                return <>Không có khách</>
                    ;
            },
        },


        {
            title: <div style={{textAlign: "center"}}></div>,
            key: "tracking-remove",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    <Popconfirm
                        title="Bạn có muốn xoá"
                        okText="Xoá"
                        cancelText="Không xoá nữa"
                        onConfirm={() => {
                            removeTracking(o)
                        }}
                    >
                        <Button>

                            Xoá
                        </Button>
                    </Popconfirm>

                    <Button size="large">
                        <a rel="noopener noreferrer" href={"/tracking-admin-detail/" + o.id} target="_blank">Chi
                            tiết</a>
                    </Button>
                </div>;
            },
        },

    ];


    const columns = [
        {
            title: <div style={{textAlign: "center"}}>Mã Item</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Code</div>,
            key: "codeItem",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.codeItem}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Tên</div>,
            key: "nameItem",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.nameItem}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Số lượng</div>,
            key: "quantity",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.quantity}</div>;
            },
        },

        {
            title: <div style={{textAlign: "center"}}></div>,
            key: "id-remove",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    <Button onClick={() => {
                        removeItem(o.id)
                    }}>
                        Xoá
                    </Button>
                </div>;
            },
        },

    ];

    const columnsItemRetail = [

        {
            title: <div style={{textAlign: "center"}}>Tên</div>,
            key: "nameItem",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.name}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Số lượng</div>,
            key: "quantity",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.quantity}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Ghi chú</div>,
            key: "quantity",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.note}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Khách hàng</div>,
            key: "quantity",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    <Customer data={o.owner}/>
                </div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}></div>,
            key: "tracking-remove",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    <Popconfirm
                        title="Bạn có muốn xoá"
                        okText="Xoá"
                        cancelText="Không xoá nữa"
                        onConfirm={() => {

                            removeItemRetail(o)
                        }}
                    >
                        <Button>

                            Xoá
                        </Button>
                    </Popconfirm>


                </div>;
            },
        },


    ];

    const handleChangeStatus = (value) => {
        setStatusPackage(value)
    }


    const getStatusFromConstant = (status) => {
        let mapStatus = CONSTANT.STATUS_PACKAGE;
        return (
            <Select defaultValue={status} style={{width: 300}} onChange={handleChangeStatus}
                    disabled={packageDetail.airplanesId ? true : false}>
                <Option value={0}>{mapStatus[0]}</Option>

                <Option value={2}>{mapStatus[2]}</Option>
                <Option value={3}>{mapStatus[3]}</Option>


                <Option value={8}>{mapStatus[8]}</Option>
                <Option value={9}>{mapStatus[9]}</Option>
                <Option value={10}>{mapStatus[10]}</Option>
                <Option value={11}>{mapStatus[11]}</Option>
                <Option value={12}>{mapStatus[12]}</Option>
                <Option value={13}>{mapStatus[13]}</Option>
                <Option value={14}>{mapStatus[14]}</Option>
                <Option value={15}>{mapStatus[15]}</Option>
            </Select>
        )


    }

    const onFinish = (values) => {

        values.id = packageDetail.id


        values.status = statusPackage


        values.listItemId = listItem.map(x => {
            return x.id
        })
        values.listTrackingId = listTracking.map(t => {
            return t.id
        })


        if (customer === null) {
            if (packageDetail.owner !== null) {
                values.packageOwner = packageDetail.owner.id
            } else {
                values.packageOwner = null
                message.error("Vui lòng chọn Khách hàng")
                return;
            }
        } else {
            values.packageOwner = customer
        }
        values.list_retail_item = listItemRetail.map(t => {

            let item = {}
            item.name = t.name
            item.quantity = t.quantity
            item.note = t.note
            item.user_id = t.owner.id
            return item
        })


        console.log(values)

        API.updatePackageDetail(values)
            .then(rs => {
                    console.log(rs)
                    message.success(rs.result)

                }
            ).catch((er) => {
            console.log(er)
            message.error("Có lỗi xảy ra vui lòng thử lại")
        })


    }

    const getDataDetail = () => {

        const idGet = props.match.params.id
        API.getPackgeById(idGet).then(result => {
            let packageDetail = result.result
            console.log(packageDetail)

            setKg(packageDetail.kg * 0.45359237)
            setPackageDetail(packageDetail)
            setStatusPackage(packageDetail.status)
            setListItemRetail(packageDetail.listRetailItem)
            setListTracking(packageDetail.listTracking)
            if (packageDetail.dateOfFly !== null) {
                packageDetail.dateOfFly = moment(packageDetail.dateOfFly)
            }


            setFields([
                {
                    name: ["id"],
                    value: packageDetail.id,
                },
                {
                    name: ["codePackage"],
                    value: packageDetail.codePackage,
                },
                {
                    name: ["name"],
                    value: packageDetail.name,
                },
                {
                    name: ["note"],
                    value: packageDetail.note,
                },
                {
                    name: ["amount"],
                    value: packageDetail.amount,
                },
                {
                    name: ["note"],
                    value: packageDetail.note,
                },
                {
                    name: ["kg"],
                    value: packageDetail.kg,
                },
                {
                    name: ["dateCreate"],
                    value: packageDetail.dateCreate,
                },
                {
                    name: ["dateUpdate"],
                    value: packageDetail.dateUpdate,
                },
                {
                    name: ["dateOfFly"],
                    value: packageDetail.dateOfFly,
                },

            ])


        })
    }
    const getItem = () => {
        return listItemTemp.map(x => {
            return <p key={x.id}> Code: {x.id} | Tên: {x.nameItem} | Giá: {x.price} | Số lượng: {x.quantity}</p>
        })

    }

    /*
        const expandedRender = record => {
            console.log(record)
            API.getTrackingById(record.id).then(rs => {
                console.log(rs.result.ownerTracking)
                setCustomTrackingDetail(rs.result.ownerTracking)
               /!*
                console.log(rs.result)
                let tracking = rs.result
                if ((tracking.ownerTracking !== undefined) && (tracking.ownerTracking !== null)) {
                    return <div>
                        <Popover
                            content={<Link to={"user/detail/" + tracking.ownerTracking.id}>Xem chi
                                tiết | Email: {tracking.ownerTracking.email}</Link>}
                            title={"Tên: " + tracking.ownerTracking.name} trigger="click">
                            <Button>{tracking.ownerTracking.nickName}</Button>
                        </Popover>
                    </div>
                } else {
                    return <div>
                        <h3> Đơn này chưa thuộc về khác hàng nào cả</h3>
                    </div>
                }*!/
            })
            return <div>
                <p style={{fontSize: 18, margin: 0,}}>Khách hàng</p>
                {(customTrackingDetail.ownerTracking !== undefined) && (customTrackingDetail.ownerTracking !== null) ?
                    <div>
                        <Popover content={<Link to={"user/detail/" + customTrackingDetail.ownerTracking.id}>Xem chi tiết | Email: {customTrackingDetail.ownerTracking.email}</Link>} title={"Tên: " + customTrackingDetail.ownerTracking.name} trigger="click">
                            <Button>{customTrackingDetail.ownerTracking.nickName}</Button>
                        </Popover>
                    </div>

                    : <div>
                        <h3> Đơn này chưa thuộc về khác hàng nào cả</h3>
                    </div>
                }
            </div>
        }*/


    async function getUserFromUserId(id) {
        let data = [...listUser]

        for (let i = 0; i < data.length; i++) {
            console.log("data", data[0].id, data[i].id, id)
            if (data[i].id === id) {
                console.log("data", data[i])
                return await data[i]
            }
        }
    }

    async function addItem(item) {


        let data = [...listItemRetail]
        let itemAdd = {};
        console.log("item", item)
        if (data.length === 0) {
            itemAdd.id = 1
        } else {
            itemAdd.id = data[data.length - 1].id + 1
        }
        itemAdd.name = item.name
        itemAdd.note = item.note
        itemAdd.kg = item.kg
        itemAdd.quantity = item.quantity
        itemAdd.owner = await getUserFromUserId(item.user_id)

        if (itemAdd.owner != null) {
            data.push(itemAdd)

            setListItemRetail(data)
        }

    }

    return (
        <div style={{padding: "10px"}}>


            <Modal title="Đã tìm thấy đơn hàng" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Bạn có muốn thêm tất cả vào package</p>
                {getItem()}
            </Modal>

            <div style={{padding: "20px", background: "white", margin: "10px 0"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Chi tiết Package</h2>


            </div>


            <Row style={{}}>
                <Col span={24} offset={0} style={{background: "white", padding: "10px", marginLeft: "15px"}}>
                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off"
                          layout="vertical"
                          fields={fields}
                          disbled="true"
                    >
                        <Row style={{padding: "10px 0"}}>
                            <Col span={3} offset={0}>
                                <div style={{fontSize: 18,}}>Mã Package</div>
                                <div
                                    style={{fontSize: 18, fontWeight: 800, padding: "10px 0"}}>#{packageDetail.id}
                                </div>
                            </Col>
                            <Col span={4} offset={0}>

                                <div style={{fontSize: 18,}}>Trạng thái</div>
                                <div style={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    padding: "10px 0"
                                }}>
                                    {packageDetail.airplanesId ?
                                        <div>

                                            <Button size="large">
                                                <a rel="noopener noreferrer"
                                                   href={"/airplane/" + packageDetail.airplanesId}
                                                   target="_blank">{"Đã có chuyến bay " + packageDetail.airplanesId}</a>
                                            </Button>

                                        </div>
                                        : ""}

                                    {packageDetail.status ? getStatusFromConstant(packageDetail.status) : ""}
                                </div>
                            </Col>
                            <Col span={4} offset={1}>

                                <Form.Item
                                    label={<p style={{fontSize: 18, margin: 0,}}>Code Package</p>}
                                    name="name"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={3} offset={1}>

                                <div>
                                    <p style={{fontSize: 18, margin: 0,}}>Khách hàng</p>
                                    {(packageDetail.owner !== undefined) && (packageDetail.owner !== null) ?
                                        <div>
                                            <Customer data={packageDetail.owner}/>
                                        </div>

                                        : <div>
                                            <h3> Đơn này chưa thuộc về khác hàng nào cả</h3>
                                        </div>
                                    }
                                </div>
                            </Col>

                            <Col span={5} offset={1}>
                                <div>
                                    <h3 style={{margin: "10px 0"}}>Chỉ chọn khi thay đổi khách hàng</h3>
                                    <Select
                                        showSearch
                                        style={{width: 350}}
                                        placeholder="Chọn khách hàng"
                                        optionFilterProp="children"
                                        onChange={onChangeCus}

                                        // onFocus={onFocus}
                                        // onBlur={onBlur}
                                        // onSearch={onSearch}

                                    >

                                        <Option key={null} value={null}>Không chọn</Option>
                                        {listUser &&
                                        listUser.map((cus) => (
                                            <Option key={cus.id} value={cus.id}>
                                                {cus.id} - {cus.name} - {cus.nickName}
                                            </Option>
                                        ))}
                                    </Select>

                                </div>
                            </Col>

                        </Row>

                        <Row>
                            <Col span={5} offset={0}>
                                <Form.Item
                                    label={<p style={{fontSize: 18, margin: 0,}}>Ghi chú Package</p>}
                                    name="note"
                                >
                                    <TextArea/>
                                </Form.Item>

                            </Col>
                            <Col span={2} offset={1}>
                                <Form.Item
                                    label={<p style={{fontSize: 18, margin: 0,}}>Số pounds</p>}
                                    name="kg"
                                >
                                    <InputNumber onChange={e=>{setKg(e *  0.45359237)}}/>
                                </Form.Item>
                              <h3>  Số kg: {Math.round(kg * 1000)/1000 }</h3>

                            </Col>

                            <Col span={3} offset={1}>
                                <h3 style={{fontSize: "19px"}}>Ngày bay dự kiến</h3>
                                {packageDetail.dateOfFly === null ? "" :
                                    <div>
                                        <h3 style={{fontSize: "16px", fontWeight: 400}}> {packageDetail.dateUpdate}</h3>
                                    </div>}
                            </Col>
                            <Col span={3} offset={1}>
                                <div>
                                    <h3 style={{fontSize: "19px"}}>Ngày tạo đơn</h3>
                                    <h3 style={{fontSize: "16px", fontWeight: 400}}> {packageDetail.dateCreate}</h3>
                                </div>
                            </Col>
                            <Col span={3} offset={1}>
                                {packageDetail.dateUpdate === null ? "" :
                                    <div>
                                        <h3 style={{fontSize: "19px"}}>Ngày cập nhật</h3>
                                        <h3 style={{fontSize: "16px", fontWeight: 400}}> {packageDetail.dateUpdate}</h3>
                                    </div>}
                            </Col>
                        </Row>

                        <Form.Item style={{marginTop: "0"}}>
                            <Button
                                name={"update"}
                                type="primary" htmlType="submit" size={'large'}>
                                Cập nhật package
                            </Button>

                        </Form.Item>

                    </Form>

                </Col>
                <Col span={24} offset={0}
                     style={{background: "white", padding: "10px", marginLeft: "10px", marginTop: "10px"}}>

                    <h3 style={{fontSize: "24px"}}>Thêm Tracking</h3>

                    <Select
                        showSearch
                        style={{width: 600}}
                        value={selected}
                        placeholder="Chọn tracking"
                        optionFilterProp="children"
                        onSelect={record => {

                            let listTrackingDisplay = [...listTracking]
                            for (let i = 0; i < listTrackingInSystem.length; i++) {

                                if (record === listTrackingInSystem[i].id) {
                                    let flag = true;

                                    for (let j = 0; j < listTrackingDisplay.length; j++) {
                                        if (record === listTrackingDisplay[j].id) {
                                            flag = false
                                        }
                                    }
                                    if (flag) {
                                        let tracking = listTrackingInSystem[i]
                                        tracking.ownerVO = tracking.ownerTracking
                                        listTrackingDisplay.push(tracking)
                                        setListTracking(listTrackingDisplay)
                                        setSelected(null)
                                    }

                                }
                            }
                            // eslint-disable-next-line
                            {play()}
                        }}

                    >
                        {listTrackingInSystem &&
                        listTrackingInSystem.map((cus) => (
                            <Option key={cus.id} value={cus.id}>
                                TK-{cus.id} - {cus.codeTracking}
                            </Option>
                        ))}
                    </Select>
                    <Button type={"primary"}>
                        Thêm Tracking
                    </Button>

                    {/*<Search*/}
                    {/*    placeholder="Nhập mã Tracking"*/}
                    {/*    allowClear*/}
                    {/*    enterButton="Search"*/}
                    {/*    size="large"*/}
                    {/*    onSearch={onSearchTracking}*/}
                    {/*    style={{marginBottom: "20px"}}*/}
                    {/*    value={searchValueTracking}*/}
                    {/*    onChange={onChangeTracking}*/}

                    {/*/>*/}


                    <h3 style={{fontSize: "24px"}}>Danh sách tracking</h3>
                    <Table
                        /*    expandedRowRender= {expandedRender}*/


                        pagination={false}
                        size="small" bordered dataSource={dataSourceTracking} columns={columnsTracking}
                        summary={pageData => {
                            let totalTracking = 0;
                            pageData.forEach(() => {
                                totalTracking++;
                            });

                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell>Tổng số</Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={3}>
                                            <Text style={{fontSize: "25px", fontWeight: "600"}}>{totalTracking}</Text>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>

                                </>
                            );
                        }}


                    />
                    <Divider plain></Divider>
                    <div>
                        <h3 style={{fontSize: "24px", paddingTop: "20px"}}>Thêm từng sản phẩm</h3>
                        <Search
                            placeholder="Nhập mã item"
                            allowClear
                            enterButton="Search"
                            size="large"
                            onSearch={onSearch}
                            style={{marginBottom: "20px"}}
                            value={searchValue}
                            onChange={onChange}
                        />
                        <h3 style={{fontSize: "24px"}}>Danh sách sản phẩm</h3>

                        <Table
                            size="small" bordered dataSource={dataSource} columns={columns}
                        />
                    </div>
                </Col>
                <Col span={12} offset={0}
                     style={{background: "white", padding: "10px", marginLeft: "10px", marginTop: "10px"}}>
                    <h3 style={{fontSize: "24px", paddingTop: "20px"}}>Thêm từng sản phẩm khách lẽ</h3>

                    <div style={{padding: "20px 0 "}}>
                        <Form name="horizontal_login" layout="inline"
                              onFinish={addItem}>
                            <Form.Item
                                name="name"
                            >
                                <Input placeholder="Tên"/>
                            </Form.Item>
                            <Form.Item
                                name="quantity"
                            >
                                <InputNumber placeholder="Số lượng"/>
                            </Form.Item>

                            <Form.Item
                                name="note"
                            >
                                <TextArea placeholder="Ghi chú"/>
                            </Form.Item>

                            <Form.Item
                                name="user_id"
                            >
                                <Select
                                    showSearch
                                    style={{width: 250}}
                                    placeholder="Chọn khách hàng"
                                    optionFilterProp="children"


                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    // onSearch={onSearch}

                                >


                                    {listUser &&
                                    listUser.map((cus) => (
                                        <Option key={cus.id} value={cus.id}>
                                            {cus.id} - {cus.name} - {cus.nickName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"

                                    >
                                        Thêm sản phẩm
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>
                    </div>

                    <Table

                        size="small" bordered dataSource={dataSourceItemRetail} columns={columnsItemRetail}
                    />
                </Col>
            </Row>


        </div>

    )


}

export default PackageDetailAdmin;