import React, {useState} from 'react';
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, notification, Row, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import CONSTANT from "../config/constant.json"
import * as API from "../code/Api";
import TextArea from "antd/es/input/TextArea";

import moment from "moment";


export const CreateTrackingUser = (props) => {

    const [displayDetails, setDisplayDetails] = useState(false);
    const dateFormat = 'YYYY/MM/DD';

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg,
        });
    };

    const onFinish = (values) => {

        if (values.codeTracking === undefined) {
            message.error("Vui lòng điền mã tracking")

            return
        }


        if (values.codeTracking.length < 10) {
            message.error("Mã tracking phải lớn hơn 10 ký tự")
            return
        }

        if (values.goodsName === undefined) {
            values.goodsName = null;
        }

        if (values.note === undefined) {
            values.note = "";
        }
        if (values.listItemDTO === undefined) {
            values.listItemDTO = [];
        }
        if (values.dateReceive === undefined) {
            values.dateReceive = null;
        } else {
            values.dateReceive = moment(values.dateReceive).format(dateFormat)
        }


        API.createTrackingUser([values]).then(result => {

            if (result.message === CONSTANT.STATUS.ERROR) {
                message.error("Code bị trùng, vui lòng thử lại")
                return
            }


            if (result.message === CONSTANT.STATUS.SUCCESS) {
                openNotificationWithIcon(result.message, "Tạo đơn hàng thành công")
                let role = localStorage.getItem(CONSTANT.LOCAL_KEY.ROLE);
                if (role === CONSTANT.ROLE.ADMIN || role === CONSTANT.ROLE.ADMIN_VN || role === CONSTANT.ROLE.ADMIN_US) {
                    props.history.push("/tracking-admin");
                } else if (role === CONSTANT.ROLE.USER) {
                    props.history.push("/tracking-user-all")
                }
            }


        })
    };

    const changeCheckbox = (e) => {

        setDisplayDetails(e.target.checked)
    }

    return (
        <div>
            <div style={{padding: "20px"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Tạo Tracking mới</h2>
                <h2>Vui lòng điền các thông tin bên dưới</h2>
            </div>
            <Row style={{padding: "20px", height: "100%",}}>
                <Col span={20} offset={2} style={{}}>
                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off"
                          layout="vertical">
                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Mã Tracking</p>}
                            name="codeTracking"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Mô tả hàng hoá</p>}
                            name="goodsName"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Ghi chú cho tracking</p>}
                            name="note"
                        >
                            <TextArea/>
                        </Form.Item>


                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Ngày nhận dự kiến</p>}
                            name="dateReceive"
                        >
                            <DatePicker size="large" format={dateFormat}/>
                        </Form.Item>


                        <Checkbox style={{fontSize: "18px"}}
                                  onChange={changeCheckbox}
                        >
                            <p style={{fontSize: "18px"}}>
                                Nhập thông tin chi tiết sản phẩm
                            </p>
                        </Checkbox>


                        <Form.List name="listItemDTO">
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map(({key, name, fieldKey, ...restField}) => (
                                        <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                                            <Form.Item

                                                {...restField}
                                                name={[name, 'nameItem']}
                                                fieldKey={[fieldKey, 'nameItem']}
                                                rules={[{required: true, message: 'Tên không được để rỗng'}]}
                                                label={<p style={{fontSize: 15, margin: 0}}>Tên</p>}

                                            >
                                                <Input style={{minWidth: "35vw"}}/>
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'quantity']}
                                                fieldKey={[fieldKey, 'quantity']}
                                                label={<p style={{fontSize: 15, margin: 0}}>Số lượng</p>}
                                            >
                                                <InputNumber style={{minWidth: "10vw"}}/>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'price']}
                                                fieldKey={[fieldKey, 'price']}
                                                label={<p style={{fontSize: 15, margin: 0}}>Giá</p>}
                                            >
                                                <InputNumber style={{minWidth: "10vw"}}/>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)}/>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            style={displayDetails ? {} : {display: 'none'}}
                                            type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                            Thêm sản phẩm mới
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                        <Form.Item style={{marginTop: "20px"}}>
                            <Button type="primary" htmlType="submit">
                                Tạo tracking mới
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>

            </Row>
        </div>
    );
};

export default CreateTrackingUser;