import React from "react";
import {Tabs} from 'antd';
import PackageAdminList from "../components/PackageAdminList";

const {TabPane} = Tabs;

const PackageAdminScreen = () => {


    const onChange = (event) => {

    }

    return (
        <div style={{padding: "10px"}}>
            <div style={{padding: "20px"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Quản lý package</h2>

            </div>
            <Tabs defaultActiveKey="1" onChange={onChange()}>
                <TabPane tab="Tất cả đơn hàng" key="1">

                    <PackageAdminList type={"all"}/>
                </TabPane>
                <TabPane tab="Đơn đã xoá" key="2">
                    <PackageAdminList type={"delete"}/>
                </TabPane>

            </Tabs>
        </div>
    )
}

export default PackageAdminScreen;
