import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, Collapse, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Table,} from "antd";
// import ItemList from "../components/ItemList";
import CommentList from "../components/CommentList";
import StepTracking from "../components/StepTracking";
import Customer from "../components/Customer";
import moment from "moment";
import CONSTANT from "../config/constant.json";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

//import { useBarcode } from '@createnextapp/react-barcode';
// import html2canvas from 'html2canvas';

//const Barcode = require('react-barcode');

const {Panel} = Collapse;
const {TextArea} = Input;
const {Option} = Select;

const TrackingDetailAdmin = (props) => {
    // const history = useHistory();
    //  const dateFormat = 'YYYY/MM/DD';
    const dateFormat = 'DD/MM/YYYY';
    const dateFormatUpdate = 'YYYY/MM/DD';
    const [form] = Form.useForm();
    const [fields, setFields] = useState([]);
    const [tracking, setTracking] = useState([]);
    // const [listItem, setListItem] = useState();
    const [listComment, setListComment] = useState();
    const [formItem] = Form.useForm();
    const [dataItem, setDataItem] = useState();
    const [editingKey, setEditingKey] = useState('');
    const [visibleModal, setVisibleModal] = useState(false);
    const [statusTracking, setStatusTracking] = useState(false);

    const [customer, setCustomer] = useState(null);
    const [customerFromOld, setCustomerFromOld] = useState(null);
    const [listUser, setListUser] = useState();
    const [statusDisable, setStatusDisable] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);


    useEffect(() => {


        if (props.location.state !== undefined) {
            setCustomerFromOld(props.location.state)
        }
        getDataDetail();
        API.getListUserActive().then(rs => {
            if (rs.message === CONSTANT.STATUS.SUCCESS) {
                setListUser(rs.result)
            }
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // eslint-disable-next-line
    const showModal = () => {
        // eslint-disable-next-line
        setIsModalVisible(true);
        // eslint-disable-next-line
    };  // eslint-disable-next-line

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onChangeCus = (value) => {


        setCustomer(value)

    }

    const handleChangeStatus = (value) => {
        setStatusTracking(value)
    }


    const getStatusFromConstant = (status) => {
        let mapStatus = CONSTANT.STATUS_TRACKING;


        return (
            <Select defaultValue={status} style={{width: 300}} onChange={handleChangeStatus}
                    disabled={tracking.airplanesId ? tracking.airplanesId.length > 0 : false}>
                <Option value={0}>{mapStatus[0]} </Option>
                <Option value={1}>{mapStatus[1]} </Option>
                <Option value={2}>{mapStatus[2]}</Option>
                <Option value={3}>{mapStatus[3]}</Option>
                <Option value={4}>{mapStatus[4]}</Option>
                <Option value={5}>{mapStatus[5]}</Option>
                <Option value={6}>{mapStatus[6]}</Option>
                <Option value={7}>{mapStatus[7]}</Option>
                <Option value={8}>{mapStatus[8]}</Option>
                <Option value={9}>{mapStatus[9]}</Option>
                <Option value={10}>{mapStatus[10]}</Option>
                <Option value={11}>{mapStatus[11]}</Option>
                <Option value={12}>{mapStatus[12]}</Option>
                <Option value={13}>{mapStatus[13]}</Option>
                <Option value={14}>{mapStatus[14]}</Option>
                <Option value={15}>{mapStatus[15]}</Option>
            </Select>
        )

        // return mapStatus.map(s => {
        //     console.log(s)
        // })
        // return mapStatus[status];

    }

    const onFinish = (values) => {


        if (values.codeTracking.length < 10) {
            message.error("Code tracking phải hơn 10 ký tự")
            return;
        }

        if (values.dateReceive !== null) {
            if (values.dateReceive.isValid()) {
                values.dateReceive = values.dateReceive.format(dateFormatUpdate)
            }
        }


        // if (values.dateWarehouseUsReceived !== null) {
        //     if (values.dateWarehouseUsReceived.isValid()) {
        //         values.dateWarehouseUsReceived = values.dateWarehouseUsReceived.format(dateFormatUpdate)
        //     }
        // }

        values.listItemDTO = dataItem
        values.id = tracking.id
        values.status = statusTracking
        if (values.codeTracking === null) {
            values.codeTracking = ""
        }
        if (values.goodsName === null) {
            values.goodsName = ""
        }
        if (values.note === undefined) {
            values.note = ""
        }

        if (customer === null) {
            values.userId = null
        } else {
            values.userId = customer
        }
        values.customNoteUser = tracking.customNoteUser
        values.amount = 0
        values.price = 0
        console.log(values)

        API.updateTrackingDetailAdminNew(values)
            .then(rs => {
                    console.log(rs)
                    console.log(rs.message === "success")
                    if (rs.message === "success") {
                        console.log(customerFromOld)
                        message.success(rs.result)
                        getDataDetail()
                        // history.push("/tracking-admin")
                    } else {
                        //  window.location.reload();
                        message.error(rs.result)
                    }
                }
            ).catch((er) => {
            console.log(er)
            message.error("Có lỗi xảy ra vui lòng thử lại")
        })


    }

    /*
        const onDelete = () => {
            API.deleteTrackingAdmin(tracking.id)
                .then(rs => {
                        console.log(rs)
                        console.log(rs.message === "success")
                        if (rs.message === "success") {
                            message.success(rs.result)
                            getDataDetail()
                        } else {
                            //  window.location.reload();
                        }
                    }
                ).catch((er) => {
                console.log(er)
                message.error("Có lỗi xảy ra vui lòng thử lại")
            })
        }
    */

    const getDataDetail = () => {

        const idGet = props.match.params.id
        API.getTrackingById(idGet).then(result => {
            let tracking = result.result


            setTracking(tracking)
            //setListItem(tracking.listItemVO)
            setStatusTracking(tracking.status)
            if (tracking.status < 6) {
                setStatusDisable(false)
            }
            if (tracking.ownerTracking !== null) {
                setCustomer(tracking.ownerTracking.id)
            }
            let list = tracking.listItemVO.map(o => {
                return {
                    key: o.id,
                    nameItem: o.nameItem,
                    price: o.price,
                    quantity: o.quantity,
                    codeItem: o.codeItem,
                }
            });
            setDataItem(list)
            tracking.listTrackingCommentVO = tracking.listTrackingCommentVO.sort(
                (a, b) => {

                    return b.id - a.id
                }
            )
            setListComment(tracking.listTrackingCommentVO)

            if (tracking.dateReceive !== null) {
                tracking.dateReceive = moment(tracking.dateReceive, dateFormat)
            }
            // if (tracking.dateWarehouseUsReceived !== null) {
            //     tracking.dateWarehouseUsReceived = moment(tracking.dateWarehouseUsReceived, dateFormat)
            // }

            console.log("code", tracking)
            setFields([
                {
                    name: ["id"],
                    value: tracking.id,
                },
                {
                    name: ["status"],
                    value: tracking.status,
                },
                {
                    name: ["codeTracking"],
                    value: tracking.codeTracking,
                },
                {
                    name: ["goodsName"],
                    value: tracking.goodsName,
                },
                {
                    name: ["amount"],
                    value: tracking.amount,
                },
                {
                    name: ["note"],
                    value: tracking.note,
                },
                {
                    name: ["dateReceive"],
                    value: tracking.dateReceive,
                },
                {
                    name: ["dateCreate"],
                    value: tracking.dateCreate,
                },
                {
                    name: ["dateUpdate"],
                    value: tracking.dateUpdate,
                },
                {
                    name: ["adminName"],
                    value: tracking.adminName,
                },
                {
                    name: ["dateWarehouseUsReceived"],
                    value: tracking.dateWarehouseUsReceived,
                },
                {
                    name: ["customNoteUser"],
                    value: tracking.customNoteUser,
                },
            ])
        })
    }


// Start Item list
    const EditableCell = ({
                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {
        const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };


    // useEffect(() => {
    //     if (props.listItem !== undefined) {
    //         let list = props.listItem.map(o => {
    //             return {
    //                 key: o.id,
    //                 nameItem: o.nameItem,
    //                 price: o.price,
    //                 quantity: o.quantity,
    //             }
    //         });
    //         setDataItem(list)
    //     }
    // }, [props.listItem]);

    const isEditing = (record) => record.key === editingKey;


    const edit = (record) => {
        formItem.setFieldsValue({

            ...record,
        });
        setEditingKey(record.key);
    };


    const cancel = () => {
        setEditingKey('');
    };

    const deleteItem = async (record) => {
        try {
            const newData = [...dataItem];
            //  const index = newData.findIndex((item) => record.key === item.key);

            for (let i = 0; i < newData.length; i++) {
                if (newData[i].key === record.key) {


                    newData.splice(i, 1)

                    setDataItem(newData);
                    return

                } else {

                }

            }


        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }


    const save = async (key) => {
        try {
            const row = await formItem.validateFields();
            const newData = [...dataItem];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setDataItem(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setDataItem(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    const columns = [
        {
            title: <div style={{textAlign: ""}}>Id</div>,
            editable: true,
            dataIndex: 'key',
        },
        {
            title: <div style={{textAlign: ""}}>Code</div>,
            editable: true,
            dataIndex: 'codeItem',
        },
        /*  {
              title: <div style={{textAlign: "center"}}>Mã Item</div>,
              key: "codeItem-print",
              render(o) {
                  return <div style={{textAlign: "center"}} id={"barcode-ne"}>

                      <div ref={wrapper_ref}>
                          <Barcode
                              value={o.codeItem}
                          />
                      </div>
                      <Barcode

                      width={1}
                      value={o.codeItem}/>

                  </div>;
              },tracking
          },
  */
        {
            title: <div style={{textAlign: ""}}>Tên</div>,
            editable: true,
            dataIndex: 'nameItem',
        },
        {
            title: 'Giá',
            dataIndex: 'price',
            inputType: 'number',
            editable: true,
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Button

                type="primary"
                onClick={() => save(record.key)}
                style={{
                    marginRight: 8,
                }}
            >
              Lưu lại
            </Button>

            <Button
                onClick={cancel}
                style={{marginTop: "10px"}}>
                            Huỷ
            </Button>

          </span>
                ) : (
                    <div>
                        {tracking.status > 6 ? "" : <div>
                            <Button

                                type="primary" icon={<EditOutlined/>}

                                disabled={editingKey !== ''} onClick={() => edit(record)}>

                            </Button>
                            <Button

                                icon={<DeleteOutlined/>}
                                style={{marginTop: "10px", marginLeft: "10px"}}
                                disabled={editingKey !== ''} onClick={() => deleteItem(record)}>

                            </Button>
                        </div>}
                    </div>
                );
            },
        },
    ];


    const addItem = value => {

        if (value.nameItem === undefined || value.price === undefined || value.quantity === undefined) {
            message.error('Vui lòng điền dầy đủ thông tin');
        } else {
            try {
                const newData = [...dataItem];

                if (newData.length === 0) {
                    value.key = 0
                } else {
                    value.key = newData[newData.length - 1].key + 1
                }
                newData.push(value)
                setDataItem(newData);
                setVisibleModal(false)
                message.success('Thêm thành công');
                form.resetFields()

            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
            //setVisibleModal(false)
        }


    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

// End Item list


    return (
        <div style={{padding: "10px"}}>
            <div style={{padding: "20px", background: "white", margin: "10px 0"}}>

                <Modal title="Chia nhỏ tracking " visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                </Modal>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Chi tiết tracking</h2>

                <StepTracking curentStatus={tracking.status}/>
            </div>
            <Row style={{}}>
                <>

                </>

                <Col span={15} offset={0} style={{background: "white", padding: "10px", marginLeft: "15px"}}>
                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off"
                          layout="vertical"
                          fields={fields}
                    >
                        <Row style={{padding: "10px 0"}}>
                            <Col span={10} offset={0}>
                                <div style={{fontSize: 18,}}>Mã Tracking</div>
                                <div style={{fontSize: 18, fontWeight: 800, padding: "10px 0"}}>#{tracking.id}</div>
                            </Col>
                            <Col span={10} offset={2}>

                                <div style={{fontSize: 18,}}>Trạng thái</div>
                                <div style={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    padding: "10px 0"
                                }}>
                                    <div>
                                        {tracking.airplanesId ? <>
                                            {tracking.airplanesId.length > 0 ?
                                                <Button size="large">
                                                    <a rel="noopener noreferrer"
                                                       href={"/airplane/" + tracking.airplanesId}
                                                       target="_blank">{"Đã có chuyến bay " + tracking.airplanesId}
                                                    </a>
                                                </Button>
                                                : ""}
                                        </> : false}

                                    </div>

                                    {tracking.status ? getStatusFromConstant(tracking.status) : ""}


                                </div>
                            </Col>
                        </Row>
                        <Form.Item
                            style={{display: 'none'}}
                            label={<span></span>}
                            name="id"
                        >
                            <Input style={{display: "none"}}/>
                        </Form.Item>

                      <Form.Item

                            label={<p style={{fontSize: 18, margin: 0,}}>Code Tracking</p>}
                            name="codeTracking"
                        >
                            <Input disabled={statusDisable}/>
                        </Form.Item>
                        <Button style={{marginTop:"0"}} onClick={showModal}>Chia nhỏ tracking </Button>

                        <p style={{fontSize: 18, margin: 0,}}>Khách hàng</p>
                        {(tracking.ownerTracking !== undefined) && (tracking.ownerTracking !== null) ?
                            <div>
                                <Customer data={tracking.ownerTracking}/>
                            </div>

                            : <div>
                                <h3> Đơn này chưa thuộc về khác hàng nào cả</h3>
                            </div>
                        }
                        <div>
                            <h3 style={{margin: "10px 0"}}>Chỉ chọn khi thay đổi khách hàng</h3>
                            <Select
                                showSearch
                                style={{width: 500}}
                                placeholder="Chọn khách hàng"
                                optionFilterProp="children"
                                onChange={onChangeCus}
                                disabled={statusDisable}
                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                // onSearch={onSearch}

                            >


                                {listUser &&
                                listUser.map((cus) => (
                                    <Option key={cus.id} value={cus.id}>
                                        {cus.id} - {cus.name} - {cus.nickName}
                                    </Option>
                                ))}
                            </Select>

                        </div>

                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0, marginTop: "10px"}}>Mô tả hàng hoá</p>}
                            name="goodsName"
                        >
                            <Input disabled={statusDisable}/>
                        </Form.Item>

                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Ghi chú tracking Admin</p>}
                            name="note"
                        >
                            <TextArea disabled={statusDisable}/>

                        </Form.Item>

                        <p style={{fontSize: 22, margin: 0,}}>Ghi chú khách hàng</p>
                        <p style={{paddingBottom: "20px", fontSize: 20}}>
                            {tracking.customNoteUser}
                        </p>


                        <Row>

                            <Col>
                                <Form.Item
                                    label={<p style={{fontSize: 18, margin: 0,}}>Ngày nhận dự kiến</p>}
                                    name="dateReceive"
                                >
                                    <DatePicker format={dateFormat}/>
                                </Form.Item>
                            </Col>

                        </Row>


                        <Row>
                            <Col>
                                <div>
                                    <h3 style={{fontSize: "19px"}}>Ngày kho US nhận</h3>
                                    <h3 style={{
                                        fontSize: "16px",
                                        fontWeight: 400
                                    }}> {tracking.dateWarehouseUsReceived}</h3>
                                </div>
                            </Col>
                            <Col offset={2}>
                                <div>
                                    <h3 style={{fontSize: "19px"}}>Ngày tạo đơn</h3>
                                    <h3 style={{fontSize: "16px", fontWeight: 400}}> {tracking.dateCreate}</h3>
                                </div>
                            </Col>
                            <Col offset={3}>

                                {tracking.dateUpdate === null ? "" :
                                    <div>
                                        <h3 style={{fontSize: "19px"}}>Ngày cập nhật</h3>
                                        <h3 style={{fontSize: "16px", fontWeight: 400}}> {tracking.dateUpdate}</h3>
                                    </div>}
                            </Col>
                        </Row>


                        <Form.Item style={{marginTop: "20px"}}>


                            <Button
                                name={"update"}
                                type="primary" htmlType="submit" size={'large'}>
                                Cập nhật tracking
                            </Button>
                            {tracking.status < 6 ? "" : <div>
                                Chỉ cập nhật trạng thái khi có lỗi xảy ra
                            </div>}
                            {/* <Popconfirm
                                title="Bạn muốn xoá đơn hàng"
                                onConfirm={onDelete}
                                // onCancel={cancel}
                                okText="Xoá đơn hàng"
                                cancelText="Không huỷ"
                            >
                                <Button
                                    style={{marginLeft: "20px"}}
                                    name={"delete"}
                                    type="primary" danger htmlType="submit" size={'large'}>
                                    Xoá đơn hàng
                                </Button>
                            </Popconfirm>
*/}

                        </Form.Item>

                    </Form>
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="Danh sách sản phẩm" key="1">
                            {/*<ItemList listItem={listItem}/>*/}
                            <div>
                                <Modal
                                    title="Thêm sản phẩm mới"
                                    centered
                                    visible={visibleModal}

                                    footer={[]}
                                >

                                    <Form
                                        form={form}
                                        name="basic"
                                        onFinish={addItem}
                                        initialValues={{
                                            name: "",
                                            quantity: "",
                                            price: "",

                                        }}
                                    >

                                        <Form.Item
                                            label="Tên sản phẩm"
                                            name="nameItem"
                                        >
                                            <Input/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Số lượng"
                                            name="quantity"
                                        >
                                            <InputNumber/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Giá"
                                            name="price"
                                        >
                                            <InputNumber/>
                                        </Form.Item>


                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Thêm sản phẩm
                                            </Button>
                                            <Button
                                                style={{marginLeft: "10px"}}
                                                onClick={() => setVisibleModal(false)}
                                            >
                                                Huỷ
                                            </Button>
                                        </Form.Item>
                                    </Form>

                                </Modal>


                                {tracking.status > 6 ? "" : <div>
                                    <Button

                                        onClick={() => setVisibleModal(true)}
                                        type="primary" style={{margin: "10px 0"}}>Thêm sản phẩm</Button>
                                </div>}


                                <Form form={formItem} component={false}>
                                    <Table

                                        size="small"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        bordered
                                        dataSource={dataItem}
                                        columns={mergedColumns}
                                        rowClassName="editable-row"
                                        pagination={{
                                            onChange: cancel,
                                        }}
                                    />
                                </Form>
                            </div>
                        </Panel>

                    </Collapse>
                </Col>
                <Col span={8} offset={0} style={{background: "white", padding: "10px", marginLeft: "20px"}}>
                    <h3 style={{fontSize: "24px"}}>Lịch sử cập nhật</h3>
                    <CommentList listComment={listComment}/>
                </Col>
            </Row>


        </div>

    )


}

export default TrackingDetailAdmin;