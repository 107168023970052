import {Collapse, Steps} from "antd";
import React from "react";
import CONSTANT from "../config/constant.json";

const {Step} = Steps;
const {Panel} = Collapse;


const StepTracking = (props) => {


    const getStep = () => {
        let listStep = [];
        let list = CONSTANT.STATUS_TRACKING;
        for (let i = 0; i <= 15; i++) {
            if (i < 0 || i > 0) {
                listStep.push(<Step key={i} title={list[i]}/>)
            }
        }
        return listStep;
    }


    return (

        <Collapse defaultActiveKey={['']}>
            <Panel header="Trạng thái tracking" key="1">
                <Steps current={props.curentStatus - 1} direction="vertical">
                    {/*    <Step status="finish" title="Đặt hàng" icon={<UserOutlined />} />
            <Step status="finish" title="Đã nhận tại kho Mỹ" icon={<SolutionOutlined />} />
            <Step status="process" title="Đang ship về VN" icon={<LoadingOutlined />} />
            <Step status="wait" title="Đang chờ thông quan" icon={<LoadingOutlined />} />
            <Step status="wait" title="Đã thông quan" icon={<LoadingOutlined />} />
            <Step status="wait" title="Đã hoàn thành" icon={<SmileOutlined />} />*/}


                    {getStep()}
                </Steps>
            </Panel>
        </Collapse>

    )
}

export default StepTracking;