import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {ProtectedRoute} from "./protected.route";
import Nav from "./components/Nav";
import Logo from "./images/DP_Logo.png";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import "./styles.css";
import "antd/dist/antd.css";
import Login from "./screens/login";
import HomePage from "./screens/HomePage";
import CreateTrackingUser from "./screens/CreateTrackingUser";
import PackageDetailAdmin from "./screens/PackageDetailAdmin";
import OrderTrackingCustomer from "./screens/OrderTrackingCustomer";
import TrackingDetail from "./screens/TrackingDetail";
import AirplaneDetailAdmin from "./screens/AirplaneDetailAdmin";
import TrackingDetailAdmin from "./screens/TrackingDetailAdmin";
import TrackingAdminScreen from "./screens/TrackingAdminScreen";
import PackageAdminScreen from "./screens/PackageAdminScreen";
import AirplaneAdminScreen from "./screens/AirplaneAdminScreen";
import ConfigAdminScreen from "./screens/ConfigAdminScreen";
import CreatePackage from "./screens/CreatePackage";
import ReceiveAdmin from "./screens/ReceiveAdmin";
import AccountAdminScreen from "./screens/AccountAdminScreen";
import AirplaneUserScreen from "./screens/AirplaneUserScreen";
import AirplaneDetailUser from "./screens/AirplaneDetailUser";
import PackageDetailUser from "./screens/PackageDetailUser";
import ListInvoiceAdminScreen from "./screens/ListInvoiceAdminScreen";
import CreateInvoiceAdminScreen from "./screens/CreateInvoiceAdminScreen";
import PackageUserList from "./components/PackageUserList";
import * as API from "./code/Api";
import {HeartTwoTone} from "@ant-design/icons";
import {Layout} from "antd";
import CONSTANT from "./config/constant.json"
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

const {Footer} = Layout;

const {Content, Sider} = Layout;

const App = (props) => {

    console.log(process.env)
    if (process.env.NODE_ENV === "production")
        console.log = function no_console() {};

    Sentry.init({
        dsn: "https://6028de3fab9041f1a8e78f4cefdd8101@o300620.ingest.sentry.io/5776910",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    useEffect(() => {
        let token = localStorage.getItem(CONSTANT.LOCAL_KEY.TOKEN);
        if (token !== null) {
            API.checkToken(token)
                .then((res) => {
                    res = res + ''
                    if (res.includes("Error: Request failed with status code 401")) {
                        localStorage.clear();
                        window.location.href = "/";
                    }

                })
        }


    }, []);

    const [collapsed, setCollapsed] = useState(false);
    const onCollapse = (collapsed) => {
        setCollapsed(collapsed);
    };

    function getNavbar() {
        let token = localStorage.getItem("token");
        let name = "Xin chào " + localStorage.getItem("name");
        if (token != null) {
            return (
                <Sider

                    collapsible collapsed={collapsed} onCollapse={onCollapse}>
                    <div className="logo">
                        <img
                            style={{
                                width: "100%",
                                objectFit: "cover",
                                padding: 20,
                            }}
                            src={Logo}
                            alt="Logo"
                        />
                    </div>
                    <h3 style={{color: "white", padding: "10px"}}>{name}</h3>

                    <Nav {...props} />
                </Sider>
            );
        }
        return null;
    }

    return (
        <Layout style={{minHeight: "100vh"}}>
            {getNavbar()}

            <Layout className="site-layout" style={{padding: 0}}>
                <Content
                    style={{
                        minHeight: "100vh",
                    }}
                >
                    <Switch>
                        <Route exact path="/" component={Login}/>
                        <ProtectedRoute path="/home" component={HomePage}/>
                        <ProtectedRoute path="/home-user" component={HomePage}/>
                        <ProtectedRoute path="/user-create-tracking" component={CreateTrackingUser}/>
                        <ProtectedRoute path="/add-tracking-admin" component={CreateTrackingUser}/>
                        <ProtectedRoute path="/admin-create-tracking" component={CreateTrackingUser}/>
                        <ProtectedRoute path="/tracking-user-all" component={OrderTrackingCustomer}/>
                        <ProtectedRoute path="/tracking-admin" component={TrackingAdminScreen}/>
                        <ProtectedRoute path="/receive-admin" component={ReceiveAdmin}/>
                        <ProtectedRoute path="/tracking-admin-detail/:id" component={TrackingDetailAdmin}/>
                        <ProtectedRoute path="/tracking/:id" component={TrackingDetail}/>
                        <ProtectedRoute path="/package/:id" component={PackageDetailAdmin}/>
                        <ProtectedRoute path="/package-user/:id" component={PackageDetailUser}/>
                        <ProtectedRoute path="/create-package" component={CreatePackage}/>
                        <ProtectedRoute path="/list-package" component={PackageAdminScreen}/>
                        <ProtectedRoute path="/list-package-user" component={PackageUserList}/>
                        <ProtectedRoute path="/list-airplane" component={AirplaneAdminScreen}/>
                        <ProtectedRoute path="/airplane/:id" component={AirplaneDetailAdmin}/>
                        <ProtectedRoute path="/airplane-user/:id" component={AirplaneDetailUser}/>
                        <ProtectedRoute path="/account-admin" component={AccountAdminScreen}/>
                        <ProtectedRoute path="/list-airplane-user" component={AirplaneUserScreen}/>
                        <ProtectedRoute path="/list-invoice" component={ListInvoiceAdminScreen}/>
                        <ProtectedRoute path="/create-invoice" component={CreateInvoiceAdminScreen}/>
                        <ProtectedRoute path="/config-admin" component={ConfigAdminScreen}/>

                    </Switch>
                </Content>
                <Footer style={{textAlign: "center"}}>
                    From Yirga Studio with <HeartTwoTone twoToneColor="#eb2f96"/> | Version: {process.env.REACT_APP_DEPLOY_ID ? process.env.REACT_APP_DEPLOY_ID : "Develop"}
                </Footer>
            </Layout>
        </Layout>
    );
};

const rootElement = document.getElementById("root");
ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    rootElement
);
