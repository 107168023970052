import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, Form, message, Row, Table, Tag} from "antd";
import moment from "moment";
import CONSTANT from "../config/constant.json"
import {Link} from "react-router-dom";
import SearchTracking from "./SearchTracking";
// import ItemList from "../components/ItemList"


const PackageUserList = (props) => {

    const [listPackage, setListPackage] = useState([]);
    const dateFormat = 'DD/MM/YYYY';
    const [listPackageStock, setListPackageStock] = useState([]);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(true);
    const dateFormatSearch = 'YYYY/MM/DD';
    useEffect(() => {
        loadData(); // eslint-disable-next-line
    }, []);


    const loadData = () => {
        API.getAllPackageUser().then((res) => {
            console.log(res.result)
            console.log(props.type)
            const type = props.type
            const list = res.result;

            let result = list.filter(package_ => package_.status !== 0);

            if (type === "delete") {
                result = res.result.filter(package_ => package_.status === 0);
            }


            setListPackage(result);
            setListPackageStock(result);
            setIsLoading(!isLoading)
            if (result.length === 0) {
                message.error("Không có đơn hàng nào")
                return
            }
            message.success("Tải xong đơn hàng")

        });
    };

    const dataSource = listPackage.map(o => {
        return {
            key: o.id,
            id: o.id,
            codePackage: o.codePackage,
            name: o.name,
            note: o.note,
            status: o.status,
            dateCreate: o.dateCreate,
            dateUpdate: o.dateUpdate,
            dateOfFly: o.dateOfFly,
            listItem: o.listItem,
            listHistory: o.listHistory,
            listUserInPackage: o.listUserInPackage,
            owner: o.owner,
            airplanes: o.airplanes,


        }
    })

    const getStatusFromConstan = (status) => {
        let mapStatus = CONSTANT.STATUS_PACKAGE;
        let mapColor = CONSTANT.COLOR_TAG;
        return <Tag color={mapColor[status]}>
            {mapStatus[status]}
        </Tag>;


    }

    const columns = [
        {
            title: <div style={{textAlign: "center"}}>Mã Package</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: <div style={{textAlign: "center"}}>Trạng thái</div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>{getStatusFromConstan(o.status)}</div>;
            },
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],


        },


        {
            title: <div style={{textAlign: "center"}}>Airplane</div>,
            key: "air",
            width: '10%',
            render(o) {

                if (o.airplanes !== null) {
                    return (<div key={"package"} style={{textAlign: "center"}}>
                        <Button size="large">
                            <a rel="noopener noreferrer"
                               href={"/airplane-user/" + o.airplanes.id}
                               target="_blank">{o.airplanes.name}
                            </a>
                        </Button>
                    </div>)

                }

            }

        },
        {
            title: <div style={{textAlign: "center"}}>Tên </div>,
            key: "status",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.name}</div>;
            },
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],


        },

        {
            title: <div style={{textAlign: "center"}}>Ngày bay</div>,
            key: "dateOfFly",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.dateOfFly}</div>;
            }


        },

        {
            title: "",
            key: "action",
            render(o) {
                const url = "/package-user/" + o.id;
                return (
                    <span>
            <Link target="_blank" rel="noopener noreferrer"
                  to={{
                      pathname: url,
                  }}
            >
              <Button type="primary" size="large">
                   <div style={{textAlign: "center"}}><span className="nav-text">Chi tiết</span></div>

              </Button>
            </Link>
          </span>
                );
            },
        },

    ]


    const onFinish = (values) => {

        let body = {dateFrom: null, dateTo: null, airplanesId: null}

        if (values.date !== undefined) {
            if (values.date !== null) {
                body.dateFrom = values.date[0].add(-1, 'd').format(dateFormatSearch)
                body.dateTo = values.date[1].add(1, 'd').format(dateFormatSearch)

            }
        }

        if (values.airplanesId !== undefined) {
            body.airplanesId = values.airplanesId
        }

        API.searchPackageUser(body).then(rs => {
            console.log(rs)
            if (rs.result.length === 0) {
                message.error("Không có tracking nào")
                setListPackage([])
                return
            }

            setListPackage(rs.result)
        })

    };


    return (<div style={{padding: "20px"}}>
        Quản lý package
        <Row style={{}}>


            <Form
                onFinish={onFinish}
                form={form}
            >
                <SearchTracking user={"no"}/>
                <Button
                    style={{marginLeft: "24px", marginBottom: "20px"}}
                    onClick={() => {
                        setListPackage(listPackageStock)
                        form.resetFields()
                    }}>
                    Xoá tìm kiếm
                </Button>
            </Form>
            <Col span={22} offset={1} style={{}}>
                <Table
                    loading={isLoading}
                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}
                    expandable={{
                        expandedRowRender: o => {
                            if (o.listItemVO !== null) {
                                return <div>
                                    {o.note === '' ? "" : <p style={{fontSize: "17px", fontWeight: 500}}><span
                                        style={{fontSize: "18px", fontWeight: 500}}>Ghi chú: </span>{o.note}</p>}
                                    {o.dateReceive === null ? "" : <p style={{fontSize: "17px", fontWeight: 500}}><span
                                        style={{
                                            fontSize: "18px",
                                            fontWeight: 500
                                        }}>Ngày nhận hàng dự kiến: </span>{moment(o.dateReceive).format(dateFormat)}
                                    </p>}

                                    {/*<ItemList listItem={o.listItemVO}/>*/}
                                </div>;
                            }
                            return;
                        },

                    }}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "70"],
                    }}
                />
            </Col>

        </Row>
    </div>)
}

export default PackageUserList;