import React from "react";
import {Tabs} from 'antd';
import AirplaneUserList from "../components/AirplaneUserList";

const {TabPane} = Tabs;

const AirplaneUserScreen = () => {


    const onChange = (event) => {

    }

    return (
        <div style={{padding: "10px"}}>
            <div style={{padding: "20px"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Quản lý Chuyến bay</h2>
            </div>
            <Tabs defaultActiveKey="1" onChange={onChange()}>
                <TabPane tab="Tất cả chuyến bay" key="1">
                    <AirplaneUserList type={"all"}/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default AirplaneUserScreen;
