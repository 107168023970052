import React from 'react';


const HomePage = () => {


    return (
        <div>
            <h1>Home Page</h1>

        </div>
    )
}

export default HomePage;
