import {Tag, Timeline} from "antd";
import React from "react";
import CONSTANT from "../config/constant.json";

const CommentList = (props) => {

    const getComment = () => {
        return props.listComment.map((i) => {
            let colorRole = "#108ee9";
            if (i.role === CONSTANT.ROLE.ADMIN
                || i.role === CONSTANT.ROLE.ADMIN_US
                || i.role === CONSTANT.ROLE.ADMIN_VN
            ) {
                colorRole = "#24af58";
            }
            return (
                <Timeline.Item key={i.id} label={i.dateCreate}
                >
                    <h3>{i.note}</h3>
                    <Tag color={colorRole}>{i.nameAdminComment}</Tag>
                </Timeline.Item>
            )
        })
    }

    if (props.listComment !== undefined) {
        /* return props.listComment.map((i) => {
             let colorRole = "#108ee9";
             console.log(CONSTANT.ROLE.ADMIN)
             console.log(i.role)
             if (i.role === CONSTANT.ROLE.ADMIN
                 || i.role === CONSTANT.ROLE.ADMIN_US
                 || i.role === CONSTANT.ROLE.ADMIN_VN
             ) {
                 colorRole = "#24af58";
             }

             return (
                 <Card title={"Thời gian " + i.dateCreate} key={i.id} bordered={false}
                       style={{width: "100%", marginBottom: "10px", background: ""}}>
                     <h3>{i.note}</h3>
                     <Tag color={colorRole}>{i.nameAdminComment}</Tag>
                 </Card>
             )
         })*/
        return (
            <Timeline mode={"left"} style={{background: "white", paddingTop: "30px", paddingRight: "10px"}}>
                {getComment()}
            </Timeline>
        )


    }

    return <div></div>

}

export default CommentList;