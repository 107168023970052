import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import auth from "../auth";
import CONSTANT from "../config/constant.json"
import {ExpandOutlined, HomeOutlined, PicRightOutlined, ShopOutlined, UserOutlined} from "@ant-design/icons";

const {SubMenu} = Menu;

const Nav = () => {
    const handleClick = (e) => {
        if (e.key === "logout") {
            auth.logout(() => {
                auth.authenticated = false;
                window.location.href = "/";
                localStorage.clear();
            });
        }
    };


    const getNav = () => {
        let role = localStorage.getItem(CONSTANT.LOCAL_KEY.ROLE);
        if (role === CONSTANT.ROLE.ADMIN) {
            return (
                getNavAdmin()

            );
        }
        if (role === CONSTANT.ROLE.USER) {
            return getNavUser();
        }
    }

    const getNavAdmin = () => {

        return ([
            <Menu.Item key="home">
                <HomeOutlined/>
                <span className="nav-text">Trang chủ</span>
                <Link to="/home"/>
            </Menu.Item>,
            <Menu.Item key="order-admin">
                <HomeOutlined/>
                <span className="nav-text">Quản lý tracking</span>
                <Link to="/tracking-admin"/>
            </Menu.Item>,
            <Menu.Item key="add-admin">
                <HomeOutlined/>
                <span className="nav-text">Thêm tracking</span>
                <Link to="/add-tracking-admin"/>
            </Menu.Item>,

            <SubMenu key="sub-manage-order" title="Quản lý kho">
                <Menu.Item key="receive-admin">
                    <HomeOutlined/>
                    <span className="nav-text">Nhập hàng vào kho </span>
                    <Link to="/receive-admin"/>
                </Menu.Item>
                <Menu.Item key="storage-admin">
                    <HomeOutlined/>
                    <span className="nav-text">Tạo Package</span>
                    {/*<Link to="/storage-admin"/>*/}
                    <Link to="/create-package"/>
                </Menu.Item>
                <Menu.Item key="list-package">
                    <HomeOutlined/>
                    <span className="nav-text">Danh sách Package</span>
                    <Link to="/list-package"/>
                </Menu.Item>
            </SubMenu>,
            <Menu.Item key="list-airplane">
                <HomeOutlined/>
                <span className="nav-text">Chuyến bay</span>
                <Link to="/list-airplane"/>
            </Menu.Item>,


            <SubMenu key="sub-manage-user" title="Quản lý Account">
                <Menu.Item key="list-user">
                    <HomeOutlined/>
                    <span className="nav-text">Danh sách user</span>
                    <Link to="/account-admin"/>
                </Menu.Item>
                <Menu.Item key="create-user">
                    <HomeOutlined/>
                    <span className="nav-text">Tạo User</span>
                    {/*<Link to="/storage-admin"/>*/}
                    <Link to="/create-user"/>
                </Menu.Item>
            </SubMenu>,
            <SubMenu key="sub-manage-invoice" title="Quản lý Invoice">
                <Menu.Item key="/create-invoice">
                    <HomeOutlined/>
                    <span className="nav-text">Tạo Invoice</span>
                    {/*<Link to="/storage-admin"/>*/}
                    <Link to="/create-invoice"/>
                </Menu.Item>
                <Menu.Item key="list-invoice">
                    <HomeOutlined/>
                    <span className="nav-text">Danh sách Invoice</span>
                    <Link to="/list-invoice"/>
                </Menu.Item>
                <Menu.Item key="config-admin">
                    <HomeOutlined/>
                    <span className="nav-text">Cài đặt</span>
                    <Link to="/config-admin"/>
                </Menu.Item>


            </SubMenu>,

        ])
    }

    const getNavUser = () => {
        return (
            [
                <Menu.Item key="home">
                    <HomeOutlined/>
                    <span className="nav-text">Trang chủ</span>
                    <Link to="/home-user"/>
                </Menu.Item>,
                <SubMenu
                    key="sub-manager-tracking-user"
                    title={
                        <span>
                          <ExpandOutlined/>
                          <span>Quản lý Tracking</span>
                        </span>
                    }
                >
                    <Menu.Item key="user-create-tracking">
                        <ShopOutlined/>
                        <span className="nav-text">Tạo đơn mới</span>
                        <Link to="/user-create-tracking"/>
                    </Menu.Item>
                    <Menu.Item key="tracking-user-all">
                        <PicRightOutlined/>
                        <span className="nav-text">Xem tất cả</span>
                        <Link to="/tracking-user-all"/>
                    </Menu.Item>
                </SubMenu>,
                <Menu.Item key="package-tracking">
                    <HomeOutlined/>
                    <span className="nav-text">Package Thùng</span>
                    <Link to="/list-package-user"/>
                </Menu.Item>,
                <Menu.Item key="air-plane">
                    <HomeOutlined/>
                    <span className="nav-text">Chuyến bay</span>
                    <Link to="/list-airplane-user"/>
                </Menu.Item>,
                <SubMenu
                    key="sub-manager-tracking-user"
                    title={
                        <span>
                          <ExpandOutlined/>
                          <span>Quản lý Tracking</span>
                        </span>
                    }
                >
                    <Menu.Item key="user-create-tracking">
                        <ShopOutlined/>
                        <span className="nav-text">Tạo đơn mới</span>
                        <Link to="/user-create-tracking"/>
                    </Menu.Item>
                    <Menu.Item key="tracking-user-all">
                        <PicRightOutlined/>
                        <span className="nav-text">Xem tất cả</span>
                        <Link to="/tracking-user-all"/>
                    </Menu.Item>
                </SubMenu>,


            ]
        );
    };

    /*
        const getUserManagement = () => {
            let role = localStorage.getItem("role");
            if (role === "admin") {
                return (
                    <SubMenu
                        key="sub2"
                        title={
                            <span>
                  <ExpandOutlined/>
                  <span>Quản lý người dùng</span>
                </span>
                        }
                    >
                        <Menu.Item key="4">
                            <UserOutlined/>
                            <span className="nav-text">Danh sách người dùng</span>
                            <Link to="/manager-user"/>
                        </Menu.Item>

                        <Menu.Item key="5">
                            <UserOutlined/>
                            <span className="nav-text">Thêm người dùng</span>
                            <Link to="/add-user"/>
                        </Menu.Item>
                    </SubMenu>
                );
            }
        };*/

    return (
        <div>
            <Menu
                style={{background: "#46547B"}}
                onClick={handleClick}
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["home"]}
                defaultOpenKeys={["sub1",
                    "sub-manager-tracking-user",
                    "sub-revenue", "sub-manage-order",
                    "sub-manage-user",
                    "sub-manage-invoice"]}
            >
                {getNav()}

                {/* {addOrder()} */}

                {/* chi tiêu */}
                {/* {getRevenueNav()} */}
                {/* quản lý user  */}
                {/*{getUserManagement()}*/}

                {/* quản lý lịch sử */}
                {/* {getHistoryNav()} */}


                <Menu.Item key="logout">
                    <UserOutlined/>
                    <span className="nav-text">Đăng xuất</span>
                </Menu.Item>

                {/* <Menu.Item key="6">
          <FormOutlined />
          <span className="nav-text">Quản lý yêu cầu</span>
        </Menu.Item> */}
            </Menu>
        </div>
    );
}

export default Nav;
