/*import React, {useState} from 'react';
import 'antd/dist/antd.css';
import '../styles.css';
import {Form, Input, InputNumber, Popconfirm, Table, Typography} from 'antd';

const originData = [];*/
/*
const ItemList = (props) => {


    if (props.listItem !== undefined) {


        let list = props.listItem;


        const columns = [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'name',
            },
            {
                title: 'Tên sản phẩm',
                dataIndex: 'nameItem',
                key: 'nameItem',
            },
            {
                title: 'Giá',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'Số lượng',
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: 'Code item',
                dataIndex: 'codeItem',
                key: 'codeItem',
            },
            {
                title: "",
                key: "action",
                render(o) {

                    return (
                        <span>

              <Button
                  // onClick={deleteItem(o)}
                  type="primary" size="large">
                <span className="nav-text">Chi tiết</span>
              </Button>

          </span>
                    );
                },
            },

        ];

        const dataSource = list.map(o => {
            return {
                key: o.id,
                id: o.id,
                nameItem: o.nameItem,
                price: o.price,
                quantity: o.quantity,
                codeItem: o.codeItem,
            }
        })


        return (
            <div>
                <Button type="primary" style={{margin: "10px 0"}}>Thêm sản phẩm</Button>
                <Table
                    bordered dataSource={dataSource} columns={columns}/>
            </div>
        )
    }

    return (
        <div>
        </div>
    )

}


export default ItemList;*/

import React, {useEffect, useState} from 'react';
import {Form, Input, InputNumber, Table, Tag} from 'antd';
import CONSTANT from "../config/constant.json";

const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {
    const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const ItemList = (props) => {
    const [formItem] = Form.useForm();
    const [dataItem, setDataItem] = useState();
    const [editingKey, setEditingKey] = useState('');
    // const [visibleModal, setVisibleModal] = useState(false);


    useEffect(() => {
        if (props.listItem !== undefined) {
            let list = props.listItem.map(o => {
                return {
                    key: o.id,
                    nameItem: o.nameItem,
                    price: o.price,
                    quantity: o.quantity,
                    status: o.status,
                }
            });
            setDataItem(list)
        }
    }, [props.listItem]);

    const isEditing = (record) => record.key === editingKey;


    // const edit = (record) => {
    //     formItem.setFieldsValue({
    //
    //         ...record,
    //     });
    //     setEditingKey(record.key);
    // };


    const cancel = () => {
        setEditingKey('');
    };

    /*    const deleteItem = async (record) => {
            try {
                const newData = [...dataItem];
                //  const index = newData.findIndex((item) => record.key === item.key);

                for (let i = 0; i < newData.length; i++) {
                    if (newData[i].key === record.key) {

                        console.log("trúng")

                        newData.splice(i, 1)
                        console.log(newData[i])
                        setDataItem(newData);
                        return

                    } else {
                        console.log("éo")
                    }

                }


            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
        }*/


    /*   const save = async (key) => {
           try {
               const row = await formItem.validateFields();
               const newData = [...dataItem];
               const index = newData.findIndex((item) => key === item.key);

               if (index > -1) {
                   const item = newData[index];
                   newData.splice(index, 1, {...item, ...row});
                   setDataItem(newData);
                   setEditingKey('');
               } else {
                   newData.push(row);
                   setDataItem(newData);
                   setEditingKey('');
               }
           } catch (errInfo) {
               console.log('Validate Failed:', errInfo);
           }
       };*/

    const getStatusItem = status => {
        let mapStatus = CONSTANT.STATUS_ITEM;
        let mapColor = CONSTANT.COLOR_TAG;
        return <Tag color={mapColor[status]}>
            {mapStatus[status]}
        </Tag>;
    }

    const columns = [
        {
            title: <div style={{textAlign: ""}}>Tên</div>,
            editable: true,
            dataIndex: 'nameItem',
        },
        {
            title: 'Giá',
            dataIndex: 'price',
            inputType: 'number',
            editable: true,
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            editable: true,
        },
        {
            title: '',
            key: 'status',
            render(o) {
                console.log(o)
                return <div style={{textAlign: "center"}}>{getStatusItem(o.status)}</div>;
            },
            /* render (t) => {
                 console.log(t)
                 return (
                     <Switch checkedChildren="Đã nhận hàng" unCheckedChildren="Chưa nhận hàng" defaultChecked/>
                 )
             }*/
        },

        /*  {
              title: '',
              dataIndex: 'operation',
              render: (_, record) => {
                  const editable = isEditing(record);
                  return editable ? (
                      <span>
              <Button
                  type="primary"
                  onClick={() => save(record.key)}
                  style={{
                      marginRight: 8,
                  }}
              >
                Lưu lại
              </Button>

              <Button
                  onClick={cancel}
                  style={{marginTop: "10px"}}>
                              Huỷ
              </Button>

            </span>
                  ) : (
                      <div>
                          <Button type="primary" icon={<EditOutlined/>}

                                  disabled={editingKey !== ''} onClick={() => edit(record)}>

                          </Button>
                          <Button
                              icon={<DeleteOutlined/>}
                              style={{marginTop: "10px", marginLeft: "10px"}}
                              disabled={editingKey !== ''} onClick={() => deleteItem(record)}>

                          </Button>
                      </div>
                  );
              },
          },*/
    ];


    /*  const addItem = value => {

          if (value.nameItem === undefined || value.price === undefined || value.quantity === undefined) {
              message.error('Vui lòng điền dầy đủ thông tin');
          } else {
              try {
                  const newData = [...dataItem];

                  if (newData.length === 0) {
                      value.key = 0
                  } else {
                      value.key = newData[newData.length - 1].key + 1
                  }
                  newData.push(value)
                  setDataItem(newData);
                  //setVisibleModal(false)
                  message.success('Thêm thành công');

              } catch (errInfo) {
                  console.log('Validate Failed:', errInfo);
              }
              //setVisibleModal(false)
          }


      }*/

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <div>
            {/*    <Modal
                title="Thêm sản phẩm mới"
                centered
                visible={visibleModal}

                footer={[

                ]}
            >

                <Form
                    name="basic"
                    onFinish={addItem}

                >
                    <Form.Item
                        label="Tên sản phẩm"
                        name="nameItem"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Số lượng"
                        name="quantity"
                    >
                        <InputNumber/>
                    </Form.Item>

                    <Form.Item
                        label="Giá"
                        name="price"
                    >
                        <InputNumber/>
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Thêm sản phẩm
                        </Button>
                        <Button
                            style={{marginLeft:"10px"}}
                            onClick={() => setVisibleModal(false)}
                         >
                            Huỷ
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>*/}

            {/*  <Button
                onClick={() => setVisibleModal(true)}
                type="primary" style={{margin: "10px 0"}}>Thêm sản phẩm</Button>*/}
            <Form form={formItem} component={false}>
                <Table
                    size="small"
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={dataItem}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                />
            </Form>
        </div>
    );
};


export default ItemList;
