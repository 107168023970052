import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {Button, Col, Form, message, Row, Table, Tag} from "antd";
import CONSTANT from "../config/constant.json"
import {Link} from "react-router-dom";
import Customer from "./Customer";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import SearchTracking from "./SearchTracking";
import Text from "antd/es/typography/Text";
// import ItemList from "../components/ItemList"

const PackageAdminList = (props) => {

    const [listPackage, setListPackage] = useState([]);
    const [listPackageStock, setListPackageStock] = useState([]);
    const [form] = Form.useForm();
    const dateFormatSearch = 'YYYY/MM/DD';
    const [isLoading, setIsLoading] = useState(true);
    // const dateFormat = 'DD/MM/YYYY';
    useEffect(() => {
        loadData(); // eslint-disable-next-line
    }, []);


    const loadData = () => {
        API.getAllPackage().then((res) => {

            const type = props.type
            const list = res.result;

            let result = list.filter(package_ => package_.status !== 0);

            if (type === "delete") {
                result = res.result.filter(package_ => package_.status === 0);
            }


            setListPackage(result);
            setListPackageStock(result);
            setIsLoading(!isLoading)
            if (result.length === 0) {
                message.error("Không có đơn hàng nào")
                return
            }
            message.success("Tải xong đơn hàng")
        });
    };

    const dataSource = listPackage.map(o => {
        return {
            key: o.id,
            id: o.id,
            codePackage: o.codePackage,
            name: o.name,
            note: o.note,
            status: o.status,
            dateCreate: o.dateCreate,
            dateUpdate: o.dateUpdate,
            dateOfFly: o.dateOfFly,
            listItem: o.listItem,
            listHistory: o.listHistory,
            listUserInPackage: o.listUserInPackage,
            owner: o.owner,
            airplanesId: o.airplanesId,
            airplanes: o.airplanes,
            listTracking: o.listTracking,
            kg: o.kg,


        }
    })

    const getStatusFromConstan = (status) => {
        let mapStatus = CONSTANT.STATUS_PACKAGE;
        let mapColor = CONSTANT.COLOR_TAG;
        return <Tag color={mapColor[status]}>
            {mapStatus[status]}
        </Tag>;


    }

    const columns = [
        {
            title: 'STT',
            key: 'index',
            dataIndex: 'index',
            render: (text, record, index) => {
                return <div style={{textAlign: "center"}}>{index + 1}</div>;
            },
            width: '1%'
        },

        {
            title: "Mã Package",
            key: 'id',

            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
            width: '10%'
        },
        {
            title: "Tên thùng",
            key: "name_package",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.name}</div>;
            },

        },
        {
            title: "Số pounds ",
            key: "kg_pond",
            render(o) {
                console.log(o)
                return <div style={{textAlign: "center"}}>{o.kg}p - {Math.round((o.kg * 0.45359237) * 1000)/1000}kg</div>;
            },

        },
        {
            title: "Số tracking",
            key: "tracking_count",
            width: '10%',
            render(o) {

                if (o.listTracking !== null) {

                    return <div style={{textAlign: "center", fontSize: "25px"}}>{o.listTracking.length}</div>;

                }

            }

        },
        {
            title: "Khách hàng",
            key: "customer",
            render(o) {

                if (o.owner !== null) {
                    return (
                        <div style={{textAlign: "center"}}>
                            <Customer data={o.owner}/>
                        </div>

                    )
                }
            }


        },

        {
            title: "Trạng thái",
            key: "status",
            render(o) {


                if (o.airplanesId) {
                    return <div style={{textAlign: "center"}}>
                        {getStatusFromConstan(o.status)}
                        <Button size="large">
                            <a rel="noopener noreferrer"
                               href={"/airplane/" + o.airplanesId}
                               target="_blank">{o.airplanesId}
                            </a>
                        </Button>
                    </div>
                }


                return <div style={{textAlign: "center"}}>{getStatusFromConstan(o.status)}</div>;
            },

        },

        {
            title: "Airplane",
            key: "pack",
            width: '10%',
            render(o) {

                if (o.airplanes !== null) {
                    return (<div key={"package"} style={{textAlign: "center"}}>
                        <Button size="large">
                            <a rel="noopener noreferrer"
                               href={"/package/" + o.airplanes.id}
                               target="_blank">{"C " + o.airplanes.name}
                            </a>
                        </Button>
                    </div>)

                }

            }

        },
        {
            title: "Ghi chú",
            key: "note",
            render(o) {


                return <div style={{textAlign: "center"}}>{o.note}</div>

            }
        },


        {
            title: "",
            key: "action",
            render(o) {
                const url = "/package/" + o.id;
                return (
                    <span>
            <Link target="_blank" rel="noopener noreferrer"
                  to={{
                      pathname: url,
                  }}
            >
              <Button type="primary" size="large">
                <span className="nav-text">Chi tiết</span>
              </Button>
            </Link>
          </span>
                );
            },
        },

    ]

    const columnsTracking = [
        {
            title: <div style={{textAlign: "center"}}>Id Tracking</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },

        },
        {
            title: <div style={{textAlign: "center"}}>Khách hàng</div>,
            key: "userName",
            render(o) {

                if (o.ownerVO !== null) {
                    return (
                        <Customer data={o.ownerVO}/>
                    )
                }
            }


        },
        {
            title: <div style={{textAlign: "center"}}>Code Tracking</div>,
            key: "codeTracking",
            render(o) {
                return <div style={{textAlign: "center"}}>
                    <CopyToClipboard text={o.codeTracking}>
                        <Button onClick={() => {
                            message.success("Đã copy code tracking")
                        }}>
                            {o.codeTracking}
                        </Button>
                    </CopyToClipboard>
                </div>;
            },

        },
    ]

    function getDataSourceTracking(list) {

        return list.map(t => {

            return {
                key: t.id,
                id: t.id,
                codeTracking: t.codeTracking,
                ownerVO: t.ownerVO,

            }
        })
    }


    const columnsTrackingItem = [
        {
            title: <div style={{textAlign: "center"}}>Id Item</div>,
            key: "id",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.id}</div>;
            },

        },
        {
            title: <div style={{textAlign: "center"}}>Tên</div>,
            key: "nameItem",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.nameItem}</div>;
            },
        },
        {
            title: <div style={{textAlign: "center"}}>Số lượng</div>,
            key: "quantity",
            render(o) {
                return <div style={{textAlign: "center"}}>{o.quantity}</div>;
            },
        },

    ]

    function getListItemInTracking(id) {
        API.getTrackingById(id).then(rs => {
            let tracking = rs.result

            if (tracking.listItemVO.length > 0) {
                return tracking.listItemVO.map(t => {
                    return {
                        key: t.id,
                        id: t.id,
                        nameItem: t.nameItem,
                        quantity: t.quantity,
                    }
                })
            }
        })
        return []
    }


    const onFinish = (values) => {

        let body = {userId: null, dateFrom: null, dateTo: null, airplanesId: null}
        if (values.userId !== undefined) {
            body.userId = values.userId
        }


        if (values.date !== undefined) {
            if (values.date !== null) {
                body.dateFrom = values.date[0].add(-1, 'd').format(dateFormatSearch)
                body.dateTo = values.date[1].add(1, 'd').format(dateFormatSearch)

            }
        }

        if (values.airplanesId !== undefined) {
            body.airplanesId = values.airplanesId
        }

        if (values.status !== undefined) {
            body.status = values.status
        }


        API.searchPackageAdmin(body).then(rs => {

            if (rs.result) {
                if (rs.result.length === 0) {
                    message.error("Không có tracking nào")
                    setListPackage([])
                    return
                }
            }


            setListPackage(rs.result)
        })

    };


    return (<div style={{}}>

        <Form
            onFinish={onFinish}
            form={form}
        >
            <SearchTracking/>
            <Button
                style={{marginLeft: "80px", marginBottom: "20px"}}
                onClick={() => {
                    setListPackage(listPackageStock)
                    form.resetFields()
                }}>
                Xoá tìm kiếm
            </Button>
        </Form>

        <Row style={{}}>


            <Col span={22} offset={1} style={{}}>

                <Table
                    loading={isLoading}
                    size="small"
                    bordered
                    columns={columns} dataSource={dataSource}
                    expandable={{
                        expandedRowRender: o => {
                            if (o.listTracking.length > 0) {
                                return <Table

                                    summary={pageData => {
                                        let totalTracking = 0;
                                        pageData.forEach(() => {
                                            totalTracking++;
                                        });

                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell colSpan={2}>Tổng số</Table.Summary.Cell>
                                                    <Table.Summary.Cell colSpan={2}>
                                                        <Text style={{
                                                            fontSize: "25px",
                                                            fontWeight: "600"
                                                        }}>{totalTracking}</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>

                                            </>
                                        );
                                    }}
                                    pagination={false}
                                    style={{padding: "20px"}}
                                    dataSource={getDataSourceTracking(o.listTracking)}
                                    columns={columnsTracking}
                                    expandable={{
                                        expandedRowRender: tk => {
                                            return <Table
                                                style={{padding: "20px"}}
                                                dataSource={getListItemInTracking(tk.id)}
                                                columns={columnsTrackingItem}


                                            />
                                        }
                                    }
                                    }
                                />
                            }


                        },

                    }}
                    pagination={{
                        defaultPageSize: 20,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "70"],
                    }}
                />
            </Col>

        </Row>
    </div>)
}

export default PackageAdminList;