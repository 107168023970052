import React, {useEffect, useState} from "react";
import * as API from "../code/Api";
import {
    Button,
    Col,
    Collapse,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Table,
} from "antd";
// import ItemList from "../components/ItemList";
import CommentList from "../components/CommentList";
import StepTracking from "../components/StepTracking";
import moment from "moment";
import CONSTANT from "../config/constant.json";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";


const {Panel} = Collapse;
const {TextArea} = Input;


const TrackingDetail = (props) => {

    const dateFormat = 'DD/MM/YYYY';
    const dateFormatUpdate = 'YYYY/MM/DD';
    const [form] = Form.useForm();
    const [fields, setFields] = useState([]);
    const [tracking, setTracking] = useState([]);
    // const [listItem, setListItem] = useState();
    const [listComment, setListComment] = useState();
    const [formItem] = Form.useForm();
    const [dataItem, setDataItem] = useState();
    const [editingKey, setEditingKey] = useState('');
    const [visibleModal, setVisibleModal] = useState(false);
    const [statusDisable, setStatusDisable] = useState(true);

    useEffect(() => {
        getDataDetail();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onDelete = () => {
        API.cancelTrackingUserById(tracking.id).then(() => {

            getDataDetail()
            message.success("Huỷ đơn hàng thành công")
        })

    }

    const getStatusFromConstant = (status) => {
        let mapStatus = CONSTANT.STATUS_TRACKING;
        return mapStatus[status];

    }

    const onFinish = (values) => {

        if (values.dateReceive !== null) {
            if (values.dateReceive.isValid()) {
                values.dateReceive = values.dateReceive.format(dateFormatUpdate)
            }
        }
        values.listItemDTO = dataItem
        values.id = tracking.id
        values.status = tracking.status
        values.userId = tracking.ownerTracking.id
        values.note = tracking.note
        console.log(values)

        API.updateTrackingDetail(values)
            .then(rs => {


                    if (rs.message === "success") {
                        message.success(rs.result)
                        getDataDetail()

                    } else {
                        //  window.location.reload();
                        message.error(rs.result)
                    }
                }
            ).catch(() => {
            message.error("Có lỗi xảy ra vui lòng thử lại")
        })


    }

    const getDataDetail = () => {

        const idGet = props.match.params.id
        API.getTrackingById(idGet).then(result => {
            let tracking = result.result
            console.log(tracking)
            setTracking(tracking)
            //setListItem(tracking.listItemVO)
            if (tracking.status < 5){
                setStatusDisable(false)
            }
            let list = tracking.listItemVO.map(o => {
                return {
                    key: o.id,
                    nameItem: o.nameItem,
                    price: o.price,
                    quantity: o.quantity,
                }
            });
            setDataItem(list)

            setListComment(tracking.listTrackingCommentVO)


            if (tracking.dateReceive !== null) {
                tracking.dateReceive = moment(tracking.dateReceive, dateFormat)
            }

            setFields([
                {
                    name: ["id"],
                    value: tracking.id,
                },
                {
                    name: ["status"],
                    value: tracking.status,
                },
                {
                    name: ["codeTracking"],
                    value: tracking.codeTracking,
                },
                {
                    name: ["goodsName"],
                    value: tracking.goodsName,
                },
                {
                    name: ["amount"],
                    value: tracking.amount,
                },
                {
                    name: ["note"],
                    value: tracking.note,
                },
                {
                    name: ["dateReceive"],
                    value: tracking.dateReceive,
                },
                {
                    name: ["dateCreate"],
                    value: tracking.dateCreate,
                },
                {
                    name: ["dateUpdate"],
                    value: tracking.dateUpdate,
                },
                {
                    name: ["adminName"],
                    value: tracking.adminName,
                },
                {
                    name: ["customNoteUser"],
                    value: tracking.customNoteUser,
                },
            ])
        })
    }


// Start Item list
    const EditableCell = ({
                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {
        const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };


    // useEffect(() => {
    //     if (props.listItem !== undefined) {
    //         let list = props.listItem.map(o => {
    //             return {
    //                 key: o.id,
    //                 nameItem: o.nameItem,
    //                 price: o.price,
    //                 quantity: o.quantity,
    //             }
    //         });
    //         setDataItem(list)
    //     }
    // }, [props.listItem]);

    const isEditing = (record) => record.key === editingKey;


    const edit = (record) => {
        formItem.setFieldsValue({

            ...record,
        });
        setEditingKey(record.key);
    };


    const cancel = () => {
        setEditingKey('');
    };

    const deleteItem = async (record) => {
        try {
            const newData = [...dataItem];
            //  const index = newData.findIndex((item) => record.key === item.key);

            for (let i = 0; i < newData.length; i++) {
                if (newData[i].key === record.key) {

                    console.log("trúng")

                    newData.splice(i, 1)
                    console.log(newData[i])
                    setDataItem(newData);
                    return

                } else {
                    console.log("éo")
                }

            }


        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }


    const save = async (key) => {
        try {
            const row = await formItem.validateFields();
            const newData = [...dataItem];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setDataItem(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setDataItem(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: <div style={{textAlign: ""}}>Tên</div>,
            editable: true,
            dataIndex: 'nameItem',
        },
        {
            title: 'Giá',
            dataIndex: 'price',
            inputType: 'number',
            editable: true,
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                if (tracking.status < 3 || tracking.status > 6)
                    return <div></div>

                return editable ? (
                    <span>
            <Button
                type="primary"
                onClick={() => save(record.key)}
                style={{
                    marginRight: 8,
                }}
            >
              Lưu lại
            </Button>

            <Button
                onClick={cancel}
                style={{marginTop: "10px"}}>
                            Huỷ
            </Button>

          </span>
                ) : (
                    <div>
                        <Button type="primary" icon={<EditOutlined/>}

                                disabled={editingKey !== ''} onClick={() => edit(record)}>

                        </Button>
                        <Button
                            icon={<DeleteOutlined/>}
                            style={{marginTop: "10px", marginLeft: "10px"}}
                            disabled={editingKey !== ''} onClick={() => deleteItem(record)}>

                        </Button>
                    </div>
                );
            },
        },
    ];


    const addItem = value => {

        if (value.nameItem === undefined || value.price === undefined || value.quantity === undefined) {
            message.error('Vui lòng điền dầy đủ thông tin');
        } else {
            try {
                const newData = [...dataItem];

                if (newData.length === 0) {
                    value.key = 0
                } else {
                    value.key = newData[newData.length - 1].key + 1
                }
                newData.push(value)
                setDataItem(newData);
                setVisibleModal(false)
                message.success('Thêm thành công');
                form.resetFields()

            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
            //setVisibleModal(false)
        }


    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
// End Item list

    return (
        <div style={{padding: "10px"}}>
            <div style={{padding: "20px", background: "white", margin: "10px 0"}}>
                <h2 style={{fontWeight: 600, fontSize: "25px"}}>Chi tiết tracking</h2>
                <StepTracking curentStatus={tracking.status}/>
            </div>
            <Row style={{}}>
                <Col span={15} offset={0} style={{background: "white", padding: "10px", marginLeft: "15px"}}>
                    <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off"
                          layout="vertical"
                          fields={fields}
                    >
                        <Row style={{padding: "10px 0"}}>
                            <Col span={10} offset={0}>
                                <div style={{fontSize: 18,}}>Mã Tracking</div>
                                <div style={{fontSize: 18, fontWeight: 800, padding: "10px 0"}}>#{tracking.id}</div>
                            </Col>
                            <Col span={10} offset={2}>
                                <div style={{fontSize: 18,}}>Trạng thái</div>
                                <div style={{
                                    fontSize: 18,
                                    fontWeight: 600,
                                    padding: "10px 0"
                                }}>{getStatusFromConstant(tracking.status)}</div>
                            </Col>
                        </Row>


                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Code Tracking</p>}
                            name="codeTracking"
                        >
                            <Input     disabled={statusDisable}/>
                        </Form.Item>
                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Mô tả hàng hoá</p>}
                            name="goodsName"
                        >
                            <Input     disabled={statusDisable}/>
                        </Form.Item>


                        <p style={{fontSize: 21, margin: 0,}}>Ghi chú admin</p>
                        <p style={{paddingBottom: "20px", fontSize: 20}}>
                            {tracking.note}
                        </p>

                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Ghi chú tracking</p>}
                            name="customNoteUser"
                        >
                            <TextArea disabled={statusDisable}/>
                        </Form.Item>


                        <Form.Item
                            label={<p style={{fontSize: 18, margin: 0,}}>Ngày nhận dự kiến</p>}
                            name="dateReceive"
                        >
                            <DatePicker format={dateFormat} disabled={statusDisable}/>
                        </Form.Item>


                        <div>
                            <h3 style={{fontSize: "19px"}}>Ngày tạo đơn</h3>
                            <h3 style={{fontSize: "16px", fontWeight: 400}}> {tracking.dateCreate}</h3>
                        </div>

                        {tracking.dateUpdate === null ? "" :
                            <div>
                                <h3 style={{fontSize: "19px"}}>Ngày cập nhật</h3>
                                <h3 style={{fontSize: "16px", fontWeight: 400}}> {tracking.dateUpdate}</h3>
                            </div>}
                        <Form.Item style={{marginTop: "20px"}}>
                            {tracking.status < 6 ?
                            <Button type="primary" htmlType="submit" size={'large'}>
                                Cập nhật tracking
                            </Button> : ""}
                            {tracking.status > 0 ? <div>


                                {(tracking.status >= 3 && tracking.status <= 4) ?
                                    <Popconfirm
                                        title="Bạn có huỷ đơn hàng"
                                        onConfirm={onDelete}
                                        onCancel={cancel}
                                        okText="Huỷ đơn hàng"
                                        cancelText="Không huỷ"
                                    >
                                        <Button style={{marginLeft: "20px"}} danger type="primary" htmlType="submit"
                                                size={'large'}>
                                            Huỷ tracking
                                        </Button>
                                    </Popconfirm>
                                    : ""}


                            </div> : <div>
                                <h3
                                    style={{fontWeight: "bold"}}
                                >tracking đã bị huỷ, bạn vui lòng tạo đơn mới </h3>
                            </div>}


                        </Form.Item>
                    </Form>
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="Danh sách sản phẩm" key="1">
                            {/*<ItemList listItem={listItem}/>*/}
                            <div>
                                <Modal
                                    title="Thêm sản phẩm mới"
                                    centered
                                    visible={visibleModal}

                                    footer={[]}
                                >

                                    <Form
                                        form={form}
                                        name="basic"
                                        onFinish={addItem}
                                        initialValues={{
                                            name: "",
                                            quantity: "",
                                            price: "",
                                        }}
                                    >
                                        <Form.Item
                                            label="Tên sản phẩm"
                                            name="nameItem"
                                        >
                                            <Input/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Số lượng"
                                            name="quantity"
                                        >
                                            <InputNumber/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Giá"
                                            name="price"
                                        >
                                            <InputNumber/>
                                        </Form.Item>


                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Thêm sản phẩm
                                            </Button>
                                            <Button
                                                style={{marginLeft: "10px"}}
                                                onClick={() => setVisibleModal(false)}
                                            >
                                                Huỷ
                                            </Button>
                                        </Form.Item>
                                    </Form>

                                </Modal>

                                {(tracking.status >= 3 && tracking.status <= 6) ?
                                    <Button
                                        onClick={() => setVisibleModal(true)}
                                        type="primary" style={{margin: "10px 0"}}>Thêm sản phẩm
                                    </Button>
                                    : ""}

                                <Form form={formItem} component={false}>
                                    <Table
                                        size="small"
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        bordered
                                        dataSource={dataItem}
                                        columns={mergedColumns}
                                        rowClassName="editable-row"
                                        pagination={{
                                            onChange: cancel,
                                        }}
                                    />
                                </Form>
                            </div>
                        </Panel>

                    </Collapse>
                </Col>
                <Col span={8} offset={0} style={{background: "white", padding: "10px", marginLeft: "20px"}}>
                    <h3 style={{fontSize: "24px"}}>Lịch sử cập nhật</h3>
                    <CommentList listComment={listComment}/>
                </Col>
            </Row>


        </div>

    )


}

export default TrackingDetail;